import React, { useState, useEffect, useContext, useRef } from 'react';
import SideNavBar from '../../components/layout/sideNavBar';
import RightContainerFormHeader from '../auth/components/rightContainerFormHeader';
import { Alert, Dropdown } from 'bootstrap';
import { DropdownButton } from 'react-bootstrap';
import CustomDropdown from './components/dropdown';
import InputField from './components/inputfield';
import DateInputField from './components/dateinputfield';
import LegalCasesForm from './components/toggle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import BasicButton from './components/basicbutton';
import FileUploader from '../loans/components/fileupload';
import DatePicker from 'react-datepicker';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { MenuItem, TextField } from '@mui/material';
import CollateralUpload from './components/collateralupload';
import ApplicationReceivedModal from './components/ApplicationReceivedModal';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ApplicationContext } from './components/ApplicationContext';
import html2pdf from 'html2pdf.js';
import { margin } from '@mui/system';

const today = new Date().toISOString().split('T')[0];

const data = [
  { label: 'Sole', value: 'sole' },
  {
    label: 'Limited Liability',
    value: 'limited',
  },
  { label: 'Share Holding', value: 'share' },
  { label: 'Partnership', value: 'partnership' },
];

const data1 = [
  { label: 'AgriTech', value: 'AgriTech' },
  {
    label: ' EdTech',
    value: 'EdTech',
  },
  { label: 'HealthTech ', value: 'HealthTech' },
  { label: 'Logistics Technology', value: 'Logistics Technology' },
];
const initialValues = {
  companyType: '',
  companySector: '',
  dateOfEstablishment: '',
  companyWebsite: '',
  companyLinkedin: '',
  contactName: '',
  address: '',
  phoneNumber: '',
  annualrevenue: '',
  netprofit: '',
  totalassets: '',
  totalliabilities: '',
  cashflow: '',
  email: '',
  loanAmount: '',
  purposeOfLoan: '',
  repaymentTerm: '',
  signature: '',
  fileUpload1: '',
  fileUpload: '',
  management: '',
  industry: '',
  additionalInformation: '',
  Revenueprojections: '',
  date: '',
  collateralFiles: '',
  fileUpload2: '',
};

function LoanScreen() {
  const isFridayOrSaturday = (date) => {
    const day = date.getDay();
    return day === 5 || day === 6;
  };
  const { addApplication, applications } = useContext(ApplicationContext);

  const handleCreateApplication = (newApplication) => {
    addApplication(newApplication);
  };

  const validationSchema = Yup.object().shape({
    companyType: Yup.string().ensure().required('This field is required'),
    companySector: Yup.string().ensure().required('This field is required'),
    dateOfEstablishment: Yup.date()
      .max(new Date(), 'Future dates are not allowed')
      .required('This field is required'),
    companyWebsite: Yup.string()
      .url('Invalid URL')
      .required('This field is required'),
    companyLinkedin: Yup.string()
      .url('Invalid URL')
      .required('This field is required'),
    contactName: Yup.string()
      .min(3, 'Contact name should be at least 3 characters')
      .max(50, 'Contact name should not exceed 50 characters')
      .matches(/^[A-Za-z\s]+$/, 'Contact name should only contain letters')
      .required('This field is required'),
    address: Yup.string()
      .min(10, 'Address should be at least 10 characters')
      .max(100, 'Address should not exceed 100 characters')
      .matches(
        /^[A-Za-z0-9\s,.-]+$/,
        'Address can only contain letters, numbers, spaces, hyphens, and commas'
      )
      .required('This field is required'),
    phoneNumber: Yup.string()
      .required('Phone number is required')
      .matches(/^(010|011|012|015)[0-9]{8}$/, 'Invalid phone number'),
    annualrevenue: Yup.string()
      .nullable()
      .test('is-positive', 'Must be a positive number.', (value) => {
        if (!value) return true;
        const numericValue = Number(value.replace(/,/g, ''));
        return !isNaN(numericValue) && numericValue >= 0;
      })
      .matches(
        /^(\d{1,3}(,\d{3})*|\d+)?$/,
        'Please enter a valid number'
      ),
      netprofit: Yup.string()
      .nullable()
      .test(
        'is-in-range',
        'Please enter a valid net profit margin or leave the field empty if not applicable.',
        (value) => {
          if (!value) return true; // Allow null or undefined values
          const numericValue = parseFloat(value.replace('%', '')); // Remove % if present
          return !isNaN(numericValue) && numericValue >= -100 && numericValue <= 100;
        }
      )
      .matches(
        /^-?\d{1,3}(\.\d{1,2})?%?$/, // Allows optional % at the end, and up to two decimal places
        'Please enter a valid number '
      ),
    totalassets: Yup.string()
      .required('This field is required.')
      .test('is-valid-number', 'Must be a positive number.', (value) => {
        if (!value) return true;

        const numericValue = parseFloat(value.replace(/,/g, ''));
        return !isNaN(numericValue) && numericValue >= 0;
      })
      .matches(
        /^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/, // Regular expression to allow commas as thousand separators
        'Please enter a valid value for total assets.'
      ),
    totalliabilities: Yup.string()
      .required('This field is required.')
      .test('is-valid-number', 'Must be a positive number.', (value) => {
        if (!value) return true; // Allow empty field

        // Remove commas and check if it's a valid positive number
        const numericValue = parseFloat(value.replace(/,/g, ''));
        return !isNaN(numericValue) && numericValue >= 0;
      })
      .matches(
        /^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/, // Regular expression to allow commas as thousand separators
        'Please enter a valid value for total liabilities.'
      ),
    Revenueprojections: Yup.string()
      .required('This field is required.')
      .test(
        'is-valid-number',
        'please enter a positive number ',
        (value) => {
          if (!value) return true;

          const numericValue = parseFloat(value.replace(/,/g, ''));
          return !isNaN(numericValue) && numericValue >= 0;
        }
      )
      .matches(
        /^(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/, // Regular expression to allow commas as thousand separators
        'Please enter a valid value for revenue projections.'
      ),
    cashflow: Yup.string()
      .required('This field is required.')
      .test(
        'is-valid-cashflow',
        'Please enter a valid value for cash flow forecasts.',
        (value) => {
          if (!value) return true; // Allow empty field

          // Remove commas and check if it's a valid number (positive or negative)
          const numericValue = parseFloat(value.replace(/,/g, ''));
          return !isNaN(numericValue);
        }
      )
      .matches(
        /^-?(\d+|\d{1,3}(,\d{3})*)(\.\d+)?$/, // Regular expression to allow commas as thousand separators
        'Please enter a valid value for cash flow forecasts.'
      ),
    email: Yup.string()
      .matches(
        /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
        'please enter a valid email address'
      )
      .required('This field is required'),
    loanAmount: Yup.string()
      .required('This field is required')
      .test('is-valid-number', 'Must be a positive integer.', (value) => {
        if (!value) return true; // Allow empty field

        // Remove commas and check if it's a valid positive integer
        const numericValue = parseInt(value.replace(/,/g, ''), 10);
        return !isNaN(numericValue) && numericValue >= 1;
      })
      .matches(
        /^(\d+|\d{1,3}(,\d{3})*)$/, // Regular expression to allow numbers with or without commas
        'Please enter a valid loan amount'
      ),
    purposeOfLoan: Yup.string()
      .required('This field is required')
      .min(10, 'Must be at least 10 characters.')
      .max(200, 'Must be 200 characters or less.')
      .matches(
        /[a-zA-Z0-9]/, // Ensures there is at least one alphanumeric character
        'meaningful characters are provided.'
      )
      .matches(
        /^[a-zA-Z0-9\s.,!?'":;()&-]+$/, // Regular expression to allow letters, numbers, spaces, and standard punctuation
        'Please use only letters, numbers, spaces, and standard punctuation.'
      ),
    repaymentTerm: Yup.string()
      .required('This field is required.')
      .test(
        'is-valid-number',
        'Must be a whole number between 1 and 360.',
        (value) => {
          if (!value) return true; // Allow empty field if not required

          const numericValue = parseInt(value, 10);
          return (
            !isNaN(numericValue) && numericValue >= 1 && numericValue <= 360
          );
        }
      )
      .matches(
        /^\d+$/, // Regular expression to allow only whole numbers
        'Please enter a valid repayment term in whole numbers.'
      ),
    signature: Yup.string()
      .min(5, 'Must be at least 5 characters.')
      .max(100, 'Must be 100 characters or less.')
      .matches(
        /^[A-Za-z\s-]+$/,
        'The signature can only contain alphabetic characters, spaces, and hyphens.'
      )
      .required('This field is required'),
    fileUpload1: Yup.mixed()
      .required('A business plan document is required')
      .test(
        'fileFormat',
        '',
        (value) =>
          !value ||
          (value &&
            [
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/vnd.ms-powerpoint',
              'application/vnd.openxmlformats-officedocument.presentationml.presentation',
              'text/csv',
              'text/plain',
            ].includes(value[0]?.type))
      ),
    fileUpload: Yup.mixed()
      .required('Project milestones document is required')
      .test(
        'fileFormat',
        '',

        (value) =>
          !value ||
          (value &&
            [
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
              'application/vnd.ms-excel',
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              'application/vnd.ms-powerpoint',
              'application/vnd.openxmlformats-officedocument.presentationml.presentation',
              'text/csv',
              'text/plain',
            ].includes(value[0]?.type))
      ),
    fileUpload2: Yup.mixed()
      .required('Collateral document is required')
      .test(
        'fileFormat',
        '',
        (value) =>
          !value ||
          (value &&
            ['application/pdf', 'image/jpeg', 'image/png'].includes(
              value[0]?.type
            ))
      ),
    date: Yup.date()
      .max(new Date(), 'Future dates are not allowed')
      .required('This field is required'),

    management: Yup.string()
      .min(
        100,
        'Enter a valid description of the management team, at least 100 characters long.'
      )
      .required('This field is required'),
    industry: Yup.string()
      .min(100, 'Industry analysis must be at least 100 characters long.')
      .required('This field is required'),
    additionalInformation: Yup.string()
      .required('This field is required')
      .oneOf(['true', 'false'], 'Please select either "Yes" or "No".'),

    collateralFiles: Yup.array().min(
      1,
      'Please upload at least one collateral document.'
    ),
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSubmit = (values, { setSubmitting, setFieldError }) => {
    const today = new Date().toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
    const applicationData = {
      applicationNumber: applications.length,
      applicationDate: today,
      email: values.email,
    };
    handleCreateApplication(applicationData);
    setIsModalOpen(true);
    setSubmitting(false);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const filterFridaysAndSaturdays = (date) => {
    return !isFridayOrSaturday(date); // Return true for dates that are not Friday or Saturday
  };

  const [selectedDate, setSelectedDate] = useState(null);
  const modalContentRef = useRef(null);

  const handleDownload = () => {
    const element = modalContentRef.current;
    const options = {
      margin: [20, 40, 20, 40],
      filename: 'loan-application.pdf',
      //image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 4 },
      useCORS: true,
      windowWidth: element.scrollWidth,
      jsPDF: { unit: 'pt', format: 'a3', orientation: 'portrait' },
      pagebreak: { mode: ['avoid-all'] },
    };
    html2pdf().from(element).set(options).save();
  };

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '100%',
        margin: '0 auto',
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleSubmit,
          errors,
          touched,
          setValues,
          handleChange,
          handleBlur,
          setFieldValue,
        }) => (
          <Form>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                padding: '0 5vw',
                // width: '83vw',
              }}
            >
              <div ref={modalContentRef}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                   
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100vh',
                      flex: 1,

                      justifyContent: 'center',
                      gap: '30px',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        // paddingLeft: '139px',
                        // paddingRight: '139px',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: '10px',
                        }}
                      >
                        <h1
                          style={{
                            fontFamily: 'Lato',
                            fontSize: '22px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            paddingTop: '30px',
                          }}
                        >
                          Loan Applications
                        </h1>
                        <span
                          style={{
                            color: '#888E9E',
                            fontFamily: 'Lato',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '20px',
                            wordWrap: 'break-word',
                            alignSelf: 'center',
                            justifySelf: 'center',
                            marginBottom: '30px',
                          }}
                        >
                          Empowering your business growth with tailored
                          commercial Loans solutions.
                        </span>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          flex: 1,
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex',
                            gap: '16px',
                            alignSelf: 'stretch',
                            width: '100%',
                          }}
                        >
                          <span
                            style={{
                              color: '#121212',
                              fontFamily: 'Lato',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: '600',
                              width: '100%',
                              height: 'auto',
                            }}
                          >
                            Company Information
                          </span>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: '100%',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '12px',
                                justifyContent: 'center',
                                alignItems: 'flex',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  gap: '12px',
                                }}
                              >
                                <div style={{ flex: 1 }}>
                                  <InputField
                                    label={'Company Type'}
                                    type="text"
                                    name={'companyType'}
                                    value={values.companyType}
                                    errors={errors.companyType}
                                    touched={touched.companyType}
                                    select
                                  >
                                    {data.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.value}
                                        sx={{
                                          fontFamily: 'Lato',
                                          fontSize: '14px',
                                          fontStyle: 'normal',
                                          fontWeight: '600',
                                          lineHeight: '140%',
                                          color: '#121212',
                                        }}
                                      >
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </InputField>
                                </div>
                                <div style={{ flex: 1 }}>
                                  <DateInputField
                                    label={'Date of establishment'}
                                    name="dateOfEstablishment"
                                    value={values.dateOfEstablishment}
                                    onChange={handleChange}
                                    max={today}
                                  ></DateInputField>
                                  <ErrorMessage
                                    name="dateOfEstablishment"
                                    component="div"
                                    style={{
                                      color: '#BB2124',
                                      fontFamily: 'Lato',
                                      fontSize: '16px',
                                      fontStyle: 'normal',
                                      fontWeight: '400',
                                    }}
                                  />
                                </div>
                              </div>

                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '10px',
                                }}
                              >
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '12px',
                                  }}
                                >
                                  <div
                                    style={{ flex: 1, marginBottom: '16px' }}
                                  >
                                    <InputField
                                      label={'Company Website address'}
                                      type="text"
                                      value={values.companyWebsite}
                                      touched={touched.companyWebsite}
                                      errors={errors.companyWebsite}
                                      name={'companyWebsite'}
                                    />
                                  </div>
                                  <div style={{ flex: 1, color: '#BB2124' }}>
                                    <InputField
                                      label={'Company linkedin link'}
                                      type="text"
                                      name={'companyLinkedin'}
                                      value={values.companyLinkedin}
                                      touched={touched.companyLinkedin}
                                      errors={errors.companyLinkedin}
                                    />
                                  </div>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: '12px',
                                    flex: 1,
                                  }}
                                >
                                  <InputField
                                    label={'Company Sector'}
                                    type="text"
                                    name={'companySector'}
                                    value={values.companySector}
                                    errors={errors.companySector}
                                    touched={touched.companySector}
                                    select
                                  >
                                    {data1.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.value}
                                        sx={{
                                          fontFamily: 'Lato',
                                          fontSize: '14px',
                                          fontStyle: 'normal',
                                          fontWeight: '600',
                                          lineHeight: '140%',
                                          color: '#121212',
                                        }}
                                      >
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </InputField>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                  }}
                                >
                                  <span
                                    style={{
                                      color: '#212121',
                                      fontFamily: 'Lato',
                                      fontSize: '14px',
                                      fontStyle: 'normal',
                                      fontWeight: '400',
                                      lineHeight: '140%',
                                      wordWrap: 'break-word',
                                      marginBottom: '30px',
                                    }}
                                  >
                                    Currently, we focusing only on those four
                                    industries, we will notify you when we
                                    decide to expand into other areas
                                  </span>
                                </div>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex',
                                    gap: '12px',
                                    alignSelf: 'stretch',
                                  }}
                                >
                                  <span
                                    style={{
                                      color: '#121212',
                                      fontFamily: 'Lato',
                                      fontSize: '16px',
                                      fontStyle: 'normal',
                                      fontWeight: '600',
                                      width: '100%',
                                      height: 'auto',
                                    }}
                                  >
                                    Contact Information
                                  </span>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'flex',
                                      gap: '12px',
                                      alignSelf: 'stretch',
                                      marginBottom: '16px', 

                                    }}
                                  >
                                    <div style={{ flex: 1 }}>
                                      <InputField
                                        label={'Address'}
                                        type="text"
                                        value={values.address}
                                        touched={touched.address}
                                        errors={errors.address}
                                        name={'address'}
                                      />
                                    </div>
                                    <div style={{ flex: 1 }}>
                                      <InputField
                                        label={'Contact name'}
                                        type="text"
                                        value={values.contactName}
                                        touched={touched.contactName}
                                        errors={errors.contactName}
                                        name={'contactName'}
                                      />
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      alignItems: 'flex',
                                      gap: '12px',
                                      alignSelf: 'stretch',
                                      marginBottom: '16px', 

                                    }}
                                  >
                                    <div style={{ flex: 1 }}>
                                      <InputField
                                        label={'Phone Number'}
                                        type="text"
                                        value={values.phoneNumber}
                                        touched={touched.phoneNumber}
                                        errors={errors.phoneNumber}
                                        name={'phoneNumber'}
                                      />
                                    </div>
                                    <div style={{ flex: 1 }}>
                                      <InputField
                                        label={' Email'}
                                        type="text"
                                        value={values.email}
                                        touched={touched.email}
                                        errors={errors.email}
                                        name={'email'}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'flex',
                          gap: '12px',
                          alignSelf: 'stretch',
                        }}
                      >
                        <span
                          style={{
                            color: '#121212',
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            width: '100%',
                            height: 'auto',
                          }}
                        >
                          Financials
                        </span>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex',
                            gap: '12px',
                            alignSelf: 'stretch',
                          }}
                        >
                          <div style={{ flex: 1    ,     marginBottom: '16px', 
}}>
                            <InputField
                              label={
                                'Annual revenue (previous 3 years, if applicable)'
                              }
                              type="text"
                              value={values.annualrevenue}
                              touched={touched.annualrevenue}
                              errors={errors.annualrevenue}
                              name={'annualrevenue'}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <InputField
                              label={'Net profit margin (If applicable)'}
                              type="text"
                              value={values.netprofit}
                              touched={touched.netprofit}
                              errors={errors.netprofit}
                              name={'netprofit'}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex',
                            gap: '12px',
                            alignSelf: 'stretch',
                            marginBottom: '16px', 

                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <InputField
                              label={
                                'Total assets by the closing of the last financial year'
                              }
                              type="text"
                              value={values.totalassets}
                              touched={touched.totalassets}
                              errors={errors.totalassets}
                              name={'totalassets'}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <InputField
                              label={
                                'Total liabilities by the closing of the last financial year'
                              }
                              type="text"
                              value={values.totalliabilities}
                              touched={touched.totalliabilities}
                              errors={errors.totalliabilities}
                              name={'totalliabilities'}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex',
                            gap: '12px',
                            
                            alignSelf: 'stretch',
                            marginBottom: '16px', 

                          }}
                        >
                          <div style={{ flex: 1}}>
                            <InputField
                              label={
                                'Revenue projections for the project subject to the Loans  Service'
                              }
                              type="text"
                            
                              value={values.Revenueprojections}
                              touched={touched.Revenueprojections}
                              errors={errors.Revenueprojections}
                              name={'Revenueprojections'}
                              InputProps={{
                                sx: { height: '80px' }, 
                              }}
                              sx={{ 
                                '.MuiInputBase-input': { height: '100%' },  // Ensure the input content fills the height
                              }}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <InputField
                              label={'Cash flow forecasts for the project'}
                              type="text"
                              value={values.cashflow}
                              touched={touched.cashflow}
                              errors={errors.cashflow}
                              name={'cashflow'}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex',
                            gap: '12px',
                            alignSelf: 'stretch',
                          }}
                        >
                          <span
                            style={{
                              color: '#121212',
                              fontFamily: 'Lato',
                              fontSize: '16px',
                              fontStyle: 'normal',
                              fontWeight: '600',
                              width: '100%',
                              height: 'auto',
                            }}
                          >
                            Loan details
                          </span>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'flex',
                              gap: '12px',
                              alignSelf: 'stretch',
                              marginBottom: '16px', 

                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <InputField
                                label={'Loan amount'}
                                type="text"
                                value={values.loanAmount}
                                touched={touched.loanAmount}
                                errors={errors.loanAmount}
                                name={'loanAmount'}
                              />
                            </div>
                            <div style={{ flex: 1 }}>
                              <InputField
                                label={'Purposed repayment term'}
                                type="text"
                                value={values.repaymentTerm}
                                touched={touched.repaymentTerm}
                                errors={errors.repaymentTerm}
                                name={'repaymentTerm'}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'flex',
                              gap: '12px',
                              alignSelf: 'stretch',
                              marginBottom: '16px', 

                            }}
                          >
                            <div style={{ flex: 1 }}>
                              <InputField
                                label={'Purpose of loan'}
                                type="text"
                                value={values.purposeOfLoan}
                                touched={touched.purposeOfLoan}
                                errors={errors.purposeOfLoan}
                                name={'purposeOfLoan'}
                              />
                            </div>
                          </div>
                        </div>
                        <span
                          style={{
                            color: '#121212',
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            width: '100%',
                            height: 'auto',
                          }}
                        >
                          Business
                        </span>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex',
                            gap: '12px',
                            alignSelf: 'stretch',
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <FileUploader
                              uploadText="A brief business plan outlining growth strategies and market opportunities of the project."
                              name="fileUpload1"
                              value={values.fileUpload1}
                              acceptedFormats={[
                                'application/pdf',
                                'application/msword',
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                'application/vnd.ms-excel',
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                'application/vnd.ms-powerpoint',
                                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                                'text/csv',
                                'text/plain',
                              ]}
                              touched={touched.fileUpload1}
                              errors={errors.fileUpload1}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex',
                            gap: '12px',
                            alignSelf: 'stretch',
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <FileUploader
                              uploadText="Timelines of the major milestones of the project."
                              acceptedFormats={[
                                'application/pdf',
                                'application/msword',
                                'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                                'application/vnd.ms-excel',
                                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                                'application/vnd.ms-powerpoint',
                                'application/vnd.openxmlformats-officedocument.presentationml.presentation',
                                'text/csv',
                                'text/plain',
                              ]}
                              name="fileUpload"
                              touched={touched.fileUpload}
                              value={values.fileUpload}
                              errors={errors.fileUpload}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        </div>
                        <span
                          style={{
                            color: '#121212',
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            width: '100%',
                            height: 'auto',
                          }}
                        >
                          Collateral
                        </span>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex',
                            gap: '12px',
                            alignSelf: 'stretch',
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <FileUploader
                              uploadText="Potential collateral or assets that can secure the loan"
                              acceptedFormats={[
                                'application/pdf',
                                'image/jpeg',
                                'image/png',
                              ]}
                              name="fileUpload2"
                              value={values.fileUpload2}
                              errors={errors.fileUpload2}
                              touched={touched.fileUpload2}
                              setFieldValue={setFieldValue}
                            />

                            <CollateralUpload
                              name={'collateralFiles'}
                              value={values.collateralFiles}
                              errors={errors.collateralFiles}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        </div>
                        <span
                          style={{
                            color: '#121212',
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            width: '100%',
                            height: 'auto',
                          }}
                        >
                          Management Team
                        </span>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex',
                            gap: '12px',
                            alignSelf: 'stretch',
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <TextField
                              style={{ backgroundColor: '#FAFAFA' }}
                              label="Brief about the project team's experience, expertise, and track record."
                              multiline
                              rows={5}
                              fullWidth
                              name="management"
                              error={errors.management}
                              value={values.management}
                              touched={touched.management}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              InputProps={{
                                style: {
                                  paddingTop: '26px',
                                },
                              }}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: '#FAFAFA',
                                  },
                                  '&:hover fieldset': {
                                    borderColor: '#FAFAFA',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#FAFAFA',
                                  },
                                  '&.Mui-error fieldset': {
                                    borderColor: 'transparent',
                                  },
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  color: '#757575',
                                  '&.MuiInputLabel-shrink': {
                                    transform:
                                      'translate(16px, 6px) scale(0.8)',
                                  },
                                  '&.Mui-focused': {
                                    color: '#757575',
                                    transform:
                                      'translate(16px, 6px) scale(0.8)',
                                  },
                                  '&.Mui-error': {
                                    color: '#757575',
                                  },
                                },
                              }}
                            />
                            <ErrorMessage
                              name="management"
                              component="div"
                              style={{
                                color: '#BB2124',
                                fontFamily: 'Lato',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                              }}
                            />
                          </div>
                        </div>
                        <span
                          style={{
                            color: '#121212',
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            width: '100%',
                            height: 'auto',
                          }}
                        >
                          Industry Analysis
                        </span>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex',
                            gap: '12px',
                            alignSelf: 'stretch',
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <TextField
                              style={{ backgroundColor: '#FAFAFA' }}
                              label="Please provide information on the industry landscape, competition, and market trends."
                              multiline
                              rows={5}
                              fullWidth
                              name="industry"
                              error={errors.industry}
                              touched={touched.industry}
                              value={values.industry}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              InputProps={{
                                style: {
                                  paddingTop: '26px',
                                },
                              }}
                              sx={{
                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: '#FAFAFA',
                                  },
                                  '&:hover fieldset': {
                                    borderColor: '#FAFAFA',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#FAFAFA',
                                  },
                                  '&.Mui-error fieldset': {
                                    borderColor: 'transparent',
                                  },
                                },
                              }}
                              InputLabelProps={{
                                sx: {
                                  color: '#757575',
                                  '&.MuiInputLabel-shrink': {
                                    transform:
                                      'translate(16px, 6px) scale(0.8)',
                                  },
                                  '&.Mui-focused': {
                                    color: '#757575',
                                    transform:
                                      'translate(16px, 6px) scale(0.8)',
                                  },
                                  '&.Mui-error': {
                                    color: '#757575',
                                  },
                                },
                              }}
                            />
                            <ErrorMessage
                              name="industry"
                              component="div"
                              style={{
                                color: '#BB2124',
                                fontFamily: 'Lato',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                              }}
                            />
                          </div>
                        </div>
                        <span
                          style={{
                            color: '#121212',
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            width: '100%',
                            height: 'auto',
                          }}
                        >
                          Additional Information
                        </span>
                        <LegalCasesForm
                          input="Is your company currently involved in any legal cases?"
                          name="additionalInformation"
                          value={values.additionalInformation}
                          error={errors.additionalInformation}
                          onChange={handleChange}
                        />
                        <ErrorMessage
                          name="additionalInformation"
                          component="div"
                          style={{
                            color: '#BB2124',
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                          }}
                        />
                        <span
                          style={{
                            color: '#121212',
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            width: '100%',
                            height: 'auto',
                          }}
                        >
                          Authorization
                        </span>
                        <span
                          style={{
                            color: '#212121',
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '140%',
                            wordWrap: 'break-word',
                          }}
                        >
                          By signing below, I certify that the information
                          provided is accurate and complete to the best of my
                          knowledge.
                        </span>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex',
                            gap: '12px',
                            alignSelf: 'stretch',
                            marginBottom: '30px',
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            <InputField
                              label={'Signature (write your full name)'}
                              type="text"
                              value={values.signature}
                              touched={touched.signature}
                              errors={errors.signature}
                              name={'signature'}
                            />
                          </div>
                          <div
                            style={{
                              flex: 1,
                              
                            }}
                          >
                            <DateInputField
                              label={'Date'}
                              name="date"
                              value={values.date}
                              max={today}
                              onChange={handleChange}
                            ></DateInputField>
                            <ErrorMessage
                              name="date"
                              component="div"
                              style={{
                                color: '#BB2124',
                                fontFamily: 'Lato',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                              }}
                            />
                          </div>
                        </div>
                        <ApplicationReceivedModal
                          isOpen={isModalOpen}
                          onClose={handleCloseModal}
                          applicationDate={today}
                          email={values.email}
                          handleDownload={handleDownload}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
                              
              <BasicButton
                label="Submit"
                onClick={handleSubmit}
                disabled={false}
                style={{
                  marginBottom: '30px', 
                  // position: 'sticky', 
                  // bottom: '10px',
                  zIndex: 1000,
                  // flexDirection: 'row',
                  // flex: 1,
                  // display: 'flex',
                  // justifyContent: 'center',
                  // alignItems: 'center',
                  // gap: '8px',
                  // alignSelf: 'stretch',
                  // margin: "0 20px",
                  backgroundColor: '#1E1E20',
                  color: '#FFFFFF',
                  padding: '8px',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  // width: '100%',
                  height: '38px',
                }}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}
export default LoanScreen;
