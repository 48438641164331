import React , {useState} from 'react';

function ActionItem({ title, slug, buttonText,buttonAction, background1,marginBottom }) {

  // const [isFirstModalOpen, setIsFirstModalOpen] = useState(false);
  // const openFirstModal = () => setIsFirstModalOpen(true);

  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        height: '158px',
        padding: '16px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
        borderRadius: '12px',
        border: '1px #DCD8FB',
        background: background1,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
        }}
      >
        <h1
          style={{
            color: 'var(--Greyscale-900, #09090B)',
            fontFamily: 'Inter',
            fontSize: '100%',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '150%',
            margin: '0',
          }}
          
        >
            {title}
         </h1>
       
        <h1
          style={{
            color: '#353535',
            fontFamily: 'Lato',
            fontSize: '80%',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '140%',
            margin: '0',
            marginBottom:marginBottom,
          }}
        >
          {slug}
        </h1>
        <button
        style={{
          display: 'flex',
          width: '173px',
          height: '32px',
          
          justifyContent: 'center',
          alignItems: 'center',
         
          gap: 'var(--Layout-x2, 8px)',
          borderRadius: 'var(--Radius-Large, 12px)',
          background: 'var(--Buttons-Primary, #1E1E20)',
          boxShadow: 'none',
          border: 'none',
          outline: 'none',
         
        }}
        onClick={buttonAction}
      >
         <h1
          style={{
            color: '#FFF',
            fontFamily: 'Lato',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '140%',
            margin: '0',
          }}
        >
          {buttonText}
        </h1>
        </button>
       
      </div>
     
        
      
    </div>
  );
}

export default ActionItem;
