import React from 'react';
import { TextField } from '@mui/material';

function DateInputField({ label, value, onChange, name, max }) {
  return (
    <div className="date-input-field-container" style={{ marginBottom: '16px' }}>
      <TextField
        label={label} // This serves as the floating label
        type="date"
        value={value}
        onChange={onChange}
        name={name}
        variant="filled" // Use "filled" or "outlined" for a floating label effect
        InputLabelProps={{
          shrink: true, // Ensures that the label stays above the date value
          style: {
            color: '#757575', // Change label color to match InputField
          },
        }}
        fullWidth
        error={Boolean(value && max && new Date(value) > new Date(max))} // Handle error state if needed
        helperText={Boolean(value && max && new Date(value) > new Date(max)) && "Date cannot be in the future"}
        sx={{
          borderRadius: '8px',
          '& .MuiInputBase-root': {
            backgroundColor: '#FAFAFA', // Change background color
            borderRadius: '8px', // Change input field shape
          },
          '& .MuiFilledInput-root': {
            '&:before, &:after': {
              borderBottom: 'none', // Remove the underline
            },
            '&.Mui-error': {
              '&:before, &:after': {
                borderBottom: 'none', // Remove the red border underline
              },
            },
          },
        }}
        inputProps={{
          max: max, // Disable future dates
          style: {
            color: 'black', // Text color
            borderRadius: '8px', // Change input border radius
          },
        }}
        FormHelperTextProps={{
          style: {
            color: '#BB2124', // Change error message color
            fontFamily: 'Lato',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
          },
        }}
      />
    </div>
  );
}

export default DateInputField;
