import React from 'react'

function RightContainerFormHeader({title, subtitle}) {
  return (
    <>
    
    <h1 style={{
            fontSize: '32px',
            fontFamily: 'Lato',
            fontWeight: 'bold',
            marginBottom: '12px',
          }}>{title}</h1>
          <p style={{
            fontSize: '18px',
            fontFamily: 'Lato',
            marginBottom: '32px',
          }}>{subtitle}</p>
    </>
  )
}

export default RightContainerFormHeader