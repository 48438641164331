import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import '../styles/customButton.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import BokraLogo from '../assests/img/BokraLogo.png';

const validationSchema = Yup.object({
  Government: Yup.string().required('This field is required'),
  City: Yup.string().required('This field is required'),
  Region: Yup.string().required('This field is required'),
  StreetCompound: Yup.string()
    .required('This field is required')
    .max(30, 'Street/Compount cannot exceed 30 characters')
    .matches(/^[a-zA-Z0-9\s,-]+$/, 'Street/Compound should not contain special characters'),
  Building: Yup.string()
    .required('This field is required')
    .max(20, 'Building cannot exceed 20 characters')
    .matches(/^[a-zA-Z0-9\s,-]+$/, 'Building should not contain characters'),
});

const DashboardCompanyInformation = () => {
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  

    const commonStyles = {
        display: 'flex',
        color: '#FFF',
      };

 


      const handleSubmit = async (values, { setSubmitting, setStatus }) => {
        try {
          await axios.post(`${process.env.REACT_APP_SERVER_URL}/companyInformation`, values);
          setStatus({ success: 'Company Information added successfully' });
          navigate('/dashboard');
        } catch (error) {
          console.error(error);
          setStatus({ error: 'An error occurred while submitting company information.' });
        } finally {
          setSubmitting(false);
        }
      };

  return (
    <div style={{ display: 'flex', flexDirection: 'column',width:'100%' }}>
      <div
        style={{
          display: 'flex',
         // width: '1440px',
          height: '13vh',
          padding: 'var(--Line-height-12-18, 18px) var(--6, 32px)',
          alignItems: 'center',
          gap: '12px',
          flexShrink: '0',
          borderBottom: '1px solid var(--Border-1, #E8E8E8)',
          background: 'var(--Background-Primary, #FFF)',
        }}
      >
        <img
          style={{
            display: 'flex',
            width: '7.8vw',
            height: '3.9vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          src={BokraLogo}
          alt="bokralogo"
          className="img-fluid"
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row',width:'100%', height: '87vh',}}>
      <div
          style={{
            ...commonStyles,
            //width: '720px',
            width: '100%',
            height: '100%',
            flex:1,
            padding: '40px',
            background: '#FAFAFA',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.06)',
            borderTop: '1px #E4E4E7 solid',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '24px',
          }}
        >
          <div
            style={{
              ...commonStyles,
              alignSelf: 'stretch',
              height: '282px',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: '24px',
            }}
          >
            <div
              style={{
                ...commonStyles,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '4px',
              }}
            >
              <div
                style={{
                  color: '#09090B',
                  fontSize: '24px',
                  fontFamily: 'Lato',
                  fontWeight: 500,
                  lineHeight: '36px',
                  wordWrap: 'break-word',
                }}
              >
                Set up your company account
              </div>
            </div>
            <div
              style={{
                ...commonStyles,
                alignSelf: 'stretch',
                height: '222px',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: '24px',
              }}
            >
              {/** Step 1 */}
              <div
                style={{
                  ...commonStyles,
                  borderRadius: '99px',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '24px',
                }}
              >
                <div
                  style={{
                    ...commonStyles,
                    width: '40px',
                    height: '40px',
                    padding: '5.71px',
                    background: 'white',
                    borderRadius: '1428.57px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '11.43px',
                  }}
                >
                  <div
                    style={{
                      width: '29px',
                      height: '29px',
                      textAlign: 'center',
                      color: '#572875',
                      fontSize: '20px',
                      fontFamily: 'Lato',
                      fontWeight: '500',
                      lineHeight: '20px',
                      wordWrap: 'break-word',
                    }}
                  >
                    1
                  </div>
                </div>
                <div
                  style={{
                    ...commonStyles,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '4px',
                    
                  }}
                >
                  <div
                    style={{
                      color: '#09090B',
                      fontSize: '20px',
                      fontFamily: 'Lato',
                      fontWeight: '500',
                      lineHeight: '28px',
                      wordWrap: 'break-word',
                    }}
                  >
                    Company Information
                  </div>
                  <div
                    style={{
                      color: '#52525B',
                      fontSize: '16px',
                      fontFamily: 'Lato',
                      fontWeight: '400',
                      lineHeight: '25.60px',
                      wordWrap: 'break-word',
                    }}
                  >
                    Let’s get started. Tell about you and your company.
                  </div>
                </div>
              </div>

              {/** Step 2 */}
              <div
                style={{
                  ...commonStyles,
                  alignSelf: 'stretch',
                  borderRadius: '99px',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '24px',
                }}
              >
                <div
                  style={{
                    ...commonStyles,
                    width: '40px',
                    height: '40px',
                    padding: '5.71px',
                    background: 'white',
                    borderRadius: '1428.57px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '11.43px',
                  }}
                >
                  <div
                    style={{
                      width: '29px',
                      height: '29px',
                      textAlign: 'center',
                      color: '#572875',
                      fontSize: '18px',
                      fontFamily: 'Lato',
                      fontWeight: '400',
                      lineHeight: '25.20px',
                      wordWrap: 'break-word',
                    }}
                  >
                    2
                  </div>
                </div>
                <div
                  style={{
                    ...commonStyles,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '4px',
                  }}
                >
                  <div
                    style={{
                      color: '#52525B',
                      fontSize: '20px',
                      fontFamily: 'Lato',
                      fontWeight: 500,
                      lineHeight: '28px',
                      wordWrap: 'break-word',
                    }}
                  >
                    Company Verifying
                  </div>
                  <div
                    style={{
                      color: '#52525B',
                      fontSize: '16px',
                      fontFamily: 'Lato',
                      fontWeight: 400,
                      lineHeight: '25.60px',
                      wordWrap: 'break-word',
                    }}
                  >
                    You can just draft a name now and change it later.
                  </div>
                </div>
              </div>

              {/** Step 3 */}
              <div
                style={{
                  ...commonStyles,
                  alignSelf: 'stretch',
                  borderRadius: '99px',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '24px',
                }}
              >
                <div
                  style={{
                    ...commonStyles,
                    width: '40px',
                    height: '40px',
                    padding: '5.71px',
                    background: 'white',
                    borderRadius: '1428.57px',
                    border: '2px #572875 solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '11.43px',
                  }}
                >
                  <div
                    style={{
                      width: '29px',
                      height: '29px',
                      textAlign: 'center',
                      color: '#572875',
                      fontSize: '20px',
                      fontFamily: 'Lato',
                      fontWeight: 500,
                      lineHeight: '20px',
                      wordWrap: 'break-word',
                    }}
                  >
                    3
                  </div>
                </div>
                <div
                  style={{
                    ...commonStyles,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '4px',
                  }}
                >
                  <div
                    style={{
                      color: '#52525B',
                      fontSize: '20px',
                      fontFamily: 'Lato',
                      fontWeight: 500,
                      lineHeight: '28px',
                      wordWrap: 'break-word',
                    }}
                  >
                    Office Address
                  </div>
                  <div
                    style={{
                      color: '#52525B',
                      fontSize: '16px',
                      fontFamily: 'Lato',
                      fontWeight: 400,
                      lineHeight: '25.60px',
                      wordWrap: 'break-word',
                    }}
                  >
                    You can just draft a name now and change it later.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flex:1,
            width: '80vw',
            padding: '40px',
            height: '100%',
            
            flexDirection: 'column',
            alignItems: 'flex-start',
            borderTop: '1px solid var(--Greyscale-100, #E4E4E7)',
            borderLeft: '1px solid var(--Greyscale-100, #E4E4E7)',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
          }}
        >
          <h1 style={{ fontSize: '32px', fontFamily: 'Lato', fontWeight: 'bold', marginBottom: '12px' }}>
            Company Information
          </h1>
          <p style={{ fontSize: '18px', fontFamily: 'Lato', marginBottom: '32px' }}>Provide the info below to start your account</p>
          <Formik
              initialValues={{
                Government: '',
                City: '',
                Region: '',
                StreetCompound: '',
                Building: '',
                Instructions: '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, status }) => (
                <Form>
                  <div className="d-flex align-items-center" style={{ flexDirection: 'column', gap: '24px' }}>
                    <div className="form-group mb input-container">
                      <div className="d-flex align-items-center" style={{ flexDirection: 'row', gap: '24px' }}>
                        <div style={{ width: '100%' }}>
                          <Field
                            as="select"
                            name="Government"
                            className="form-select"
                            style={{ backgroundColor: '#FAFAFA', fontFamily: 'Lato', height: '56px', width: '17vw' }}
                          >
                            <option value='' disabled>Government</option>
                            <option value='Cairo'>Cairo</option>
                            <option value='Giza'>Giza</option>
                            <option value='Alexandria'>Alexandria</option>
                          </Field>
                          <ErrorMessage name="Government" component="div" className="text-danger mt-2" />
                        </div>

                        <div style={{ width: '100%' }}>
                          <Field
                            as="select"
                            name="City"
                            className="form-select"
                            style={{ backgroundColor: '#FAFAFA', fontFamily: 'Lato', height: '56px', width: '26vw' }}
                          >
                            <option value='' disabled>City</option>
                            <option value='Cairo'>Cairo</option>
                            <option value='Giza'>Giza</option>
                            <option value='Alexandria'>Alexandria</option>
                          </Field>
                          <ErrorMessage name="City" component="div" className="text-danger mt-2" />
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb input-container">
                      <div className="d-flex align-items-center" style={{ flexDirection: 'row', gap: '24px' }}>
                        <div style={{ width: '100%' }}>
                          <Field
                            as="select"
                            name="Region"
                            className="form-select"
                            style={{ backgroundColor: '#FAFAFA', fontFamily: 'Lato', height: '56px', width: '17vw' }}
                          >
                            <option value='' disabled>Region</option>
                            <option value='District 1'>District 1</option>
                            <option value='District 2'>District 2</option>
                            <option value='District 3'>District 3</option>
                          </Field>
                          <ErrorMessage name="Region" component="div" className="text-danger mt-2" />
                        </div>

                        <div style={{ width: '100%' }}>
                          <Field
                            name="StreetCompound"
                            className="form-control"
                            style={{ backgroundColor: '#FAFAFA', fontFamily: 'Lato', height: '56px', width: '26vw' }}
                            placeholder="Street/Compound"
                          />
                          <ErrorMessage name="StreetCompound" component="div" className="text-danger mt-2" />
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb input-container">
                      <div style={{ width: '100%' }}>
                        <Field
                          name="Building"
                          className="form-control"
                          style={{ backgroundColor: '#FAFAFA', fontFamily: 'Lato', height: '56px', width: '45vw' }}
                          placeholder="Building"
                        />
                        <ErrorMessage name="Building" component="div" className="text-danger mt-2" />
                      </div>
                    </div>

                    <div className="form-group mb input-container">
                      <Field
                        name="Instructions"
                        className="form-control"
                        style={{ backgroundColor: '#FAFAFA', fontFamily: 'Lato', height: '56px', width: '45vw' }}
                        placeholder="Special delivery instructions (optional)"
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn-dark btn-custom-size-continue w-100"
                      style={{ fontFamily: 'Lato', fontSize: '16px', fontWeight: '600', lineHeight: '140%', }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Submitting...' : 'Save and Start'}
                    </button>

                    {status && <p className="text-center mb-3 text-danger">{status.success || status.error}</p>}
                  </div>
                </Form>
              )}
            </Formik>
          <p className="text-center mb-3">{message}</p>
        </div>
      </div>
    </div>
  );
};

export default DashboardCompanyInformation;
