import React, { useState } from 'react';
import { format, addDays } from 'date-fns';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CalendarIcon from '../../../../../assests/img/calendar.png';
import PlusIcon from '../../../../../assests/img/PlusIcon2.png';
import CustomModal from '../../../../../components/modal';
import LuggageIcon from '../../../../../assests/img/LuggageIcon.png';
import EditIcon from '../../../../../assests/img/EditIcon.png';
import DeleteIcon from '../../../../../assests/img/DeleteIcon.png';
import Title from '../components/title';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../../../../../styles/customToast.css';
import InputField from '../../../../loans/components/inputfield';
import { toast } from 'react-toastify';
import tick from '../../../../../assests/img/tick.png';



function FirstModal({ closeModal, isModalOpen }) {
  const [periods, setPeriods] = useState([]);
  const [currentPeriod, setCurrentPeriod] = useState({
    inputValue: '',
    startDate: null,
    endDate: null,
  });
  const [periodError, setPeriodError] = useState(''); 


  const validationSchema = Yup.object().shape({
    inputValue: Yup.number()
      .required('Liquidity amount is required')
      .min(1, 'Amount should be greater than 0'),
    dateRange: Yup.array()
      .of(Yup.date().required('Both start and end dates are required'))
      .test(
        'is-complete-range',
        'Both start and end dates are required',
        function (value) {
          return value[0] !== null && value[1] !== null;
        }
      ),
  });
  const [editIndex, setEditIndex] = useState(null);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setCurrentPeriod({ ...currentPeriod, startDate: start, endDate: end });
  };

  const handleInputChange = (value) => {
    setCurrentPeriod({ ...currentPeriod, inputValue: value });
  };

  const addNewPeriod = () => {
    if (
      currentPeriod.inputValue &&
      currentPeriod.startDate &&
      currentPeriod.endDate
    ) {
      if (editIndex !== null) {
        const updatedPeriods = [...periods];
        updatedPeriods[editIndex] = currentPeriod;
        setPeriods(updatedPeriods);
        setEditIndex(null);
      } else {
        setPeriods([...periods, currentPeriod]);
      }
      
      setCurrentPeriod({ inputValue: '', startDate: null, endDate: null });
      setPeriodError(''); 
    }
  };

  const handleEditPeriod = (index) => {
    setCurrentPeriod(periods[index]);
    setEditIndex(index);
  };

  const handleDeletePeriod = (index) => {
    setPeriods(periods.filter((_, i) => i !== index));
  };

  const submitFirstModal = () => {
    if (periods.length === 0) {
      setPeriodError('Please add at least one period before submitting.');
    } else {
      setPeriodError('');
      closeModal();
      closeModal();
      toast.success('Liquidity plan has been updated successfully ', {
        autoClose: 3000,
        position: 'bottom-center',
        className: 'custom-toast',
        bodyClassName: 'custom-body-toast',
        icon: <div className="custom-toast-icon"><img src={tick} alt="Success" style={{ width: '30px', height: '30px' }} /></div>,
        progressClassName: 'custom-toast-progress',
        style: {
          width: '413px', 
          
        },
      });
   
     
    }
  };

  return (
    <CustomModal isModalOpen={isModalOpen} closeModal={closeModal}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          background: '#FAFAFA',
          width: '100%',
          height: '100vh',
          maxHeight: '90vh',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            width: '80%',
            margin: 'auto',
            borderRadius: '12px',
            border: '1px solid var(--Border-1, #EAEDF2)',
            background: 'var(--Background-Primary, #FFF)',
            maxHeight: '82vh',
            overflowY: 'scroll',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            padding: '20px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              maxWidth: '720px',
            }}
          >
            <Title
              title={'Manage liquidity plan '}
              subtitle={`Set your cash flow needs, and we'll optimize your investments to be ready when required.`}
            />

            {periods.map((period, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  padding: '16px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '12px',
                  justifyContent: 'space-between',
                  borderRadius: '12px',
                  border: '1px #D0CAEE',
                  background: '#FDFAFF',
                  marginBottom: '16px',
                  width: '100%',
                }}
              >
                <span
                  style={{
                    color: '#121212',
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    fontWeight: '600',
                  }}
                >
                  Period #{index + 1}
                </span>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      gap: '12px',
                      alignItems: 'center',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px 16px',
                        borderRadius: '90px',
                        border: '1px solid #E4E4E7',
                        background: '#FFF',
                      }}
                    >
                      <img
                        src={LuggageIcon}
                        alt="Period"
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '4px',
                        }}
                      />
                      <span>{`From ${format(period.startDate, 'dd MMM yyyy')} to ${format(
                        period.endDate,
                        'dd MMM yyyy'
                      )}`}</span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '5px 16px',
                        borderRadius: '90px',
                        border: '1px solid #E4E4E7',
                        background: '#FFF',
                      }}
                    >
                      <img
                        src={LuggageIcon}
                        alt="Amount"
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '4px',
                        }}
                      />
                      <span>{`EGP${period.inputValue}`}</span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '8px',
                    }}
                  >
                    <img
                      src={EditIcon}
                      alt="Edit Period"
                      style={{
                        width: '24px',
                        height: '24px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleEditPeriod(index)}
                    />
                    <img
                      src={DeleteIcon}
                      alt="Delete Period"
                      style={{
                        width: '24px',
                        height: '24px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleDeletePeriod(index)}
                    />
                  </div>
                </div>
              </div>
            ))}
            <Formik
              initialValues={{ inputValue: '', dateRange: [null, null] }}
              validationSchema={validationSchema}
              onSubmit={(values) => {
                const newPeriod = {
                  inputValue: values.inputValue,
                  startDate: values.dateRange[0],
                  endDate: values.dateRange[1],
                };

                if (editIndex !== null) {
                  const updatedPeriods = [...periods];
                  updatedPeriods[editIndex] = newPeriod;
                  setPeriods(updatedPeriods);
                  setEditIndex(null);
                } else {
                  setPeriods([...periods, newPeriod]);
                }
              }}
            >
              {({ setFieldValue, values, errors, touched }) => (
                <Form style={{ width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '16px',
                      width: '100%',
                    }}
                  >
                    <span
                      style={{
                        alignSelf: 'flex-start',
                        color: '#121212',
                        fontFamily: 'Lato',
                        fontSize: '14px',
                        fontWeight: '600',
                      }}
                    >
                      {editIndex !== null
                        ? `Edit Period #${editIndex + 1}`
                        : `Period #${periods.length + 1}`}
                    </span>

                    <InputField
                      label={'Liquidity Amount'}
                      type="text"
                      name={'inputValue'}
                      errors={errors.inputValue}
                      touched={touched.inputValue}
                    />
                    <div
                      style={{
                        width: '100%',
                        padding: '12px 0',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '8px',
                        marginBottom: '16px',
                        flexDirection: 'row',
                      }}
                    >
                      <div
                        style={{
                          height: '1px',
                          width: '100%',
                          backgroundColor: '#EAEDF2',
                        }}
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          padding: '3.264px 14px 3.264px 3.264px',
                          gap: '6.5px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '80px',
                          border:
                            'var(--Letter-spacing-Letter-spacing, 0.816px) solid #E4E4E7',
                          background: '#FFF',
                        }}
                      >
                        <div
                          style={{
                            borderRadius: '815.894px',
                            background: '#F5F3FF',
                          }}
                        >
                          <img
                            src={CalendarIcon}
                            alt="Select Period"
                            style={{ width: '24px', height: '24px' }}
                          />
                        </div>
                        <span
                          style={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#121212',
                            whiteSpace: 'nowrap',
                            lineHeight: '24px',
                          }}
                        >
                          Select liquidity period
                        </span>
                      </div>
                      <div
                        style={{
                          height: '1px',
                          width: '100%',
                          backgroundColor: '#EAEDF2',
                        }}
                      />
                    </div>

                    <DatePicker
                      selected={values.dateRange[0]}
                      onChange={(dates) => setFieldValue('dateRange', dates)}
                      startDate={values.dateRange[0]}
                      endDate={values.dateRange[1]}
                      selectsRange
                      inline
                      minDate={addDays(new Date(), 1)}
                    />

                    {errors.dateRange &&
                      errors.dateRange[1] &&
                      touched.dateRange && (
                        <div className='text-danger'>
                          {errors.dateRange[1]} 
                        </div>
                      )}
                  </div>

                  <div
                    style={{
                      gap: 8,
                      display: 'flex',
                      height: '2.4vh',
                      flexDirection: 'row',
                      cursor: 'pointer',
                      marginTop: '24px',
                      marginBottom: '24px',
                    }}
                    onClick={addNewPeriod}
                  >
                    <div
                      style={{
                        width: 20,
                        height: 20,
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: 10,
                        display: 'inline-flex',
                      }}
                    >
                      <button
                        style={{
                          display: 'flex',
                          width: '20px',
                          height: '20px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          border: 'none',
                          backgroundColor: 'transparent',
                          padding: 0,
                        }}
                      >
                        <img
                          style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            objectFit: 'contain',
                          }}
                          src={PlusIcon}
                          alt="Add New Period"
                        />
                      </button>
                    </div>
                    <div
                      style={{
                        color: '121212',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '140%',
                      }}
                    >
                      {editIndex !== null
                        ? 'Update Period'
                        : 'Add another period'}
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <button
            style={{
              display: 'flex',
              width: '65%',
              height: '38px',
              padding: '8px var(--Layout-x4, 16px)',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '8px',
              gap: 'var(--Layout-x2, 8px)',
              borderRadius: '12px',
              background: '#1E1E20',
               border:'none'
            }}
            onClick={submitFirstModal}
          >
            <span
              style={{
                color: '#FFF',
                textAlign: 'center',
                height: '22px',
                fontFamily: 'Lato',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '140%',
               
              }}
            >
              Adjust
            </span>
          </button>
          {periodError && (
                      <div className='text-danger' style={{marginBottom: '24px'}}>
                        {periodError}
                      </div>
                    )}
        </div>
      </div>
    </CustomModal>
  );
}

export default FirstModal;
