import React from 'react';
import DebitIcon from '../../../assests/img/NegativeIcon.png';
import CreditIcon from '../../../assests/img/PlussIcon.png';
import { transactions } from '../../../data/transactions';
import PlusIcon from '../../../assests/img/PlusIcon.png';

const TransactionPage = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        padding: '28px',
        boxSizing: 'border-box',
      }}
    >
      {/* Header Section */}
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: '24px',
        }}
      >
        <span
          style={{
            color: '#121212',
            fontFamily: 'Lato',
            fontSize: '24px',
            fontWeight: '700',
            lineHeight: '140%',
          }}
        >
          Transactions
        </span>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
          }}
        >
          <button
            style={{
              display: 'flex',
              height: '36px',
              width: '125px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '4px',
              borderRadius: '8px',
              background: '#1E1E20',
              border: 'none',
              flexDirection: 'row',
            }}
          >
            <img
              style={{
                width: '16px',
                height: '16px',
              }}
              src={PlusIcon}
              alt="Plus"
            />
            <h1
              style={{
                color: '#FFFFFF',
                fontFamily: 'Inter',
                fontSize: '13px',
                fontWeight: '400',
                margin: '0',
              }}
            >
              Export Report
            </h1>
          </button>
          <select
            style={{
              borderRadius: '8px',
              border: '0.5px solid #F1E8F8',
              backgroundColor: '#FAFAFA',
              fontFamily: 'Lato',
              fontSize: '16px',
              height: '36px',
              //width: '104px',
              border:'none'
            }}
          >
            <option value="">Type</option>
            <option value="Credit">Credit</option>
            <option value="Debit">Debit</option>
          </select>
          <select
            style={{
              borderRadius: '8px',
              border: '0.5px solid #F1E8F8',
              backgroundColor: '#FAFAFA',
              fontFamily: 'Lato',
              fontSize: '16px',
             height: '36px',
             // width: '104px',
              border:'none'
            }}
          >
            <option value="">Today</option>
            <option value="Week to date">Week to date</option>
            <option value="Month to date">Month to date</option>
            <option value="Quarter to date">Quarter to date</option>
            <option value="Year to date">Year to date</option>
            <option value="Since inception">Since inception</option>
          </select>
        </div>
      </div>

      {/* Table Section */}
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          backgroundColor: '#FFF',
          borderRadius: '8px',
          overflow: 'hidden',
        }}
      >
        <thead>
          <tr
            style={{
              backgroundColor: '#FAFAFA',
              padding: '8px',
            }}
          >
            <th
              style={{
                textAlign: 'left',
                padding: '12px',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '400',
              }}
            >
              Transactions
            </th>
            <th
              style={{
                textAlign: 'left',
                padding: '12px',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '400',
              }}
            >
              Date
            </th>
            <th
              style={{
                textAlign: 'left',
                padding: '12px',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '400',
              }}
            >
              Type
            </th>
            <th
              style={{
                textAlign: 'right',
                padding: '12px',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '400',
              }}
            >
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => (
            <tr key={index} style={{ borderBottom: '1px solid #FAFAFA' }}>
              <td style={{ padding: '12px', display: 'flex', alignItems: 'center' }}>
                <img
                  style={{ marginRight: '8px' }}
                  src={transaction.type === 'Debit' ? DebitIcon : CreditIcon}
                  alt="icon"
                />
                <div>
                  <div>{transaction.fund}</div>
                  <div
                    style={{
                      fontSize: '10px',
                      fontWeight: '400',
                      color: '#797979',
                    }}
                  >
                    {transaction.reference}
                  </div>
                </div>
              </td>
              <td
                style={{
                  padding: '12px',
                  fontSize: '14px',
                  color: '#121212',
                }}
              >
                {transaction.date}
              </td>
              <td
                style={{
                  padding: '12px',
                  fontSize: '14px',
                  color: transaction.type === 'Debit' ? 'black' : 'black',
                }}
              >
                {transaction.type}
              </td>
              <td
                style={{
                  padding: '12px',
                  textAlign: 'right',
                  fontSize: '14px',
                  fontWeight: '400',
                }}
              >
                {transaction.amount}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionPage;
