import React, { useState, useEffect } from 'react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.rtl.min.css';
import RightContainerFormHeader from '../../components/rightContainerFormHeader';
import 'react-toastify/dist/ReactToastify.css';
import InputField from '../../../loans/components/inputfield';
import { ToastContainer, toast } from 'react-toastify';
import tick from '../../../../assests/img/tick.png';

const LoginUpRightContainerOne = ({ setIsAuthenticated }) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (token) {
      const params = new URLSearchParams(location.search);
      const redirectUrl = params.get('redirect') || '/dashboard';
      navigate(redirectUrl); // Redirect if already authenticated
    }
  }, [navigate, location]);

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);

  useEffect(() => {
    let timer;
    if (isButtonDisabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsButtonDisabled(false);
      setCountdown(60);
    }
    return () => clearInterval(timer); // Cleanup
  }, [isButtonDisabled, countdown]);

  const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email format').required('Enter your email to verify'),
    verificationCode: Yup.string().required('Verification code is required'),
  });

  // Updated onSubmit function
  const onSubmit = async (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/login/verify`,
        {
          email: values.email,
          verificationCode: values.verificationCode,
        });
      if (response.data.token && response.data.userId) {
        sessionStorage.setItem('token', response.data.token);
        localStorage.setItem('userId', response.data.userId); // Store userId in localStorage
        setIsAuthenticated(true);

        const params = new URLSearchParams(location.search);
        const redirectUrl = params.get('redirect') || '/dashboard';
        navigate(redirectUrl, { replace: true });
      } else {
        throw new Error('No token or userId received from server');
      }
    } catch (error) {
      console.error('Login error:', error);
      setFieldError('verificationCode', 'Verification failed. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  // Email verification handler (resend verification code)
  const handleVerifyEmail = async (values, setFieldError) => {
    if (!values.email) {
      setFieldError('email', 'Email is required to resend the verification code');
      return;
    }

    setIsButtonDisabled(true);

    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/login`, {
        email: values.email,
      });
      toast.success('Verification code sent to your email', {
        autoClose: 3000,
        position: 'bottom-center',
        className: 'custom-toast',
        bodyClassName: 'custom-body-toast',
        icon: (
          <div className="custom-toast-icon">
            <img src={tick} alt="Success" style={{ width: '30px', height: '30px' }} />
          </div>
        ),
        progressClassName: 'custom-toast-progress',
        style: { width: '350px' },
      });
    } catch (error) {
      setFieldError('general', error.response?.data?.error || error.message);
      setIsButtonDisabled(false);
    }
  };

  return (
    <div className="col-md-6 d-flex flex-column justify-content-center align-items-center style">
      <div className="w-75">
        <RightContainerFormHeader
          title={'Sign In'}
          subtitle={'To continue to your Bokra account'}
        />
        <Formik
          initialValues={{
            email: '',
            verificationCode: '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, isSubmitting, setFieldError, values }) => (
            <Form>
              <div style={{ marginBottom: '16px' }}>
                <InputField
                  name="email"
                  label={'Email'}
                  type="text"
                  errors={errors.email}
                  touched={touched.email}
                  resendButtonProps={{
                    isButtonDisabled: isButtonDisabled,
                    setIsButtonDisabled: setIsButtonDisabled,
                    countdown: countdown,
                    setCountdown: setCountdown,
                    handleSendOTP: () => handleVerifyEmail(values, setFieldError),
                  }}
                />
              </div>
              <ErrorMessage
                name="general"
                component="p"
                className="text-center text-danger mb-3"
              />
              <div style={{ marginBottom: '16px' }}>
                <InputField
                  name="verificationCode"
                  type="text"
                  label={'Verification code'}
                  placeholder="Verification Code"
                  errors={errors.verificationCode}
                  touched={touched.verificationCode}
                />
              </div>
              <button
                style={{
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontWeight: '600',
                  marginBottom: '16px',
                }}
                type="submit"
                className="btn btn-dark btn-custom-size-continue w-100"
                disabled={isSubmitting}
              >
                Sign In
              </button>
            </Form>
          )}
        </Formik>
        <p
          className="text-center mb-3"
          style={{
            fontSize: '18px',
            fontFamily: 'Lato',
            marginBottom: '16px',
          }}
        >
          Don't have an account?{' '}
          <a
            href="/"
            style={{
              fontWeight: 'bold',
              cursor: 'pointer',
              textDecoration: 'none',
              color: 'black',
            }}
          >
            Sign up
          </a>
        </p>
      </div>
    </div>
  );
};

export default LoginUpRightContainerOne;
