import React from 'react';

function Calculator() {
  return (
    <div
      style={{
        display: 'flex',
        width: '30vw',
        height: '100%',
        padding: '24px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '22px',
        flexShrink: '0',
        borderRadius: '12px',
        border:
          'var(--Letter-spacing-Letter-spacing, 1px) solid var(--Border-1, #EAEDF2)',
        background: '#FFF',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <span
          style={{
            width: '100%',
            color: '#171717',
            fontSize: '20px',
            fontFamily: 'Lato',
            fontWeight: '600',
            wordWrap: 'break-word',
          }}
        >
          Calculator
        </span>
        <select
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            marginLeft: 'auto',
            gap: '18px',
            borderRadius: '12px',
            border: '0.5px #F1E8F8',
            backgroundColor: '#FFF',
            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.08)',
            fontFamily: 'Lato',
            fontSize: '16px',
            height: '36px',
            width: '104px',
          }}
          type="Currency"
          className="form-select"
          id="exampleGovernment"
          placeholder="Currency"
        >
          <option value="">In 1 year</option>
          <option value="Cairo">In 2 years</option>
          <option value="Giza">In 3 years</option>
          <option value="Giza">In 5 years</option>
          <option value="Giza">In 10 years</option>
          <option value="Alexandria">In 30 years</option>
        </select>
      </div>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'center',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '24px',
          flex: '1 0 0',
        }}
      >
        <div
          style={{
            width: '119px',
            height: '119px',
            background: '#DBC5E9',
            borderRadius: 9999,
            border: '2px white solid',
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <span
            style={{
              color: '3F3F46',
              fontFamily: 'Lato',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '155%',
            }}
          >
            Expected outcome
          </span>
          <span
            style={{
              color: '09090B',
              fontFamily: 'Lato',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '140%',
            }}
          >
            EGP80,493.00
          </span>
        </div>
      </div>
      <div style={{
        display: 'flex',
        padding: '12px 16px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '2px',
        alignSelf: 'stretch',
        borderRadius: '10px',
        background: 'var(--Background-Layer, #FAFAFA)'
      }}>
        <span style={{
            color: '#2F3035',
            fontFamily: 'Lato',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '140%'
        }}>
            Company contribution
        </span>
        <span style={{
            color: '#212226',
            fontFamily: 'Lato',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '140%'
        }}>
            400,000
        </span>
      </div>
      <div style={{
        display: 'flex',
        padding: '12px 16px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '2px',
        alignSelf: 'stretch',
        borderRadius: '10px',
        background: 'var(--Background-Layer, #FAFAFA)'
      }}>
        <span style={{
            color: '#2F3035',
            fontFamily: 'Lato',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '140%'
        }}>
            Employee contribution
        </span>
        <span style={{
            color: '#212226',
            fontFamily: 'Lato',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '140%'
        }}>
            400,000
        </span>
      </div>
    </div>
  );
}

export default Calculator;
