import React, { useState, useEffect } from 'react';
import CalendarIcon from '../../../../../assests/img/calendar.png';
import commercialRegistryIcon from '../../../../../assests/img/icon.svg';
import { toast } from 'react-toastify';
import { useToast } from '../../../toastProvider';
import { FaCheckCircle, FaExclamationCircle, FaSpinner } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import { addDays, format } from 'date-fns';
import CustomModal from '../../../../../components/modal';
import Title from '../components/title';
import tick from '../../../../../assests/img/tick.png';


function ThirdModal({ closeModal, isModalOpen }) {
  // Default to "Managed by Bokra" plan
  const [selectedPlan, setSelectedPlan] = useState(1);
  const [selectedPlanOne, setSelectedPlanOne] = useState('Managed by Bokra');
  const [inputValue, setInputValue] = useState('0');
  const [selectedDate, setSelectedDate] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({ screenshotFile: null });
  const [isUploading, setIsUploading] = useState(false);
  const { showToast } = useToast(); // Use the custom hook

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };

  const handleSelectPlan = (planId) => {
    setSelectedPlan(planId);
    const selected = plans.find((plan) => plan.id === planId);
    if (selected) {
      setSelectedPlanOne(selected.title);
    }
  };

  const plans = [
    {
      id: 1,
      title: 'Managed by Bokra',
      description:
        'Bokra optimizes your investment strategy based on market performance to ensure the best treasury portfolio for your company.',
      recommended: true,
    },
    {
      id: 2,
      title: '100% Fixed income & Money market',
      description:
        'Maximize stability with fully secured income and liquidity.',
    },
    {
      id: 3,
      title: '75% Fixed income & Money market, 25% Funds',
      description:
        'Balance safety with growth potential through a conservative mix.',
    },
    {
      id: 4,
      title: '50% Fixed income & Money market, 50% Funds',
      description:
        'Achieve a balanced approach with equal parts security and growth.',
    },
    {
      id: 5,
      title: '25% Fixed income & Money market, 75% Funds',
      description:
        'Target higher returns with a growth-focused, diversified portfolio.',
    },
    {
      id: 6,
      title: '100% Funds',
      description:
        'Pursue maximum growth with a fully diversified investment in funds',
    },
  ];

  const getButtonStyles = (planId) => {
    if (planId === selectedPlan) {
      // Styles for the selected plan
      return {
        border: '1px solid #572875',
        background: '#F3F2FB',
      };
    }
    // Styles for the unselected plans
    return {
      border: '1px solid #D0CAEE',
      background: '#FDFAFF',
    };
  };

  const submitThirdModal = () => {
    closeModal();
    toast.success('Portfolio has been updated successfully', {
      autoClose: 3000,
      position: 'bottom-center',
      className: 'custom-toast',
      bodyClassName: 'custom-body-toast',
      icon: <div className="custom-toast-icon"><img src={tick} alt="Success" style={{ width: '30px', height: '30px' }} /></div>,
      progressClassName: 'custom-toast-progress',
      style: {
        width: '377px', // Dynamically setting width for the toast
        
      },
    });
   
  };

  const isFridayOrSaturday = (date) => {
    const day = date.getDay();
    return day === 5 || day === 6; // 5 is Friday, 6 is Saturday
  };
  const filterFridaysAndSaturdays = (date) => {
    return !isFridayOrSaturday(date); // Return true for dates that are not Friday or Saturday
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Reset states before a new upload
      setIsLoading(true);
      setIsUploading(true);

      setIsUploaded(false);
      setUploadError('');

      // Simulate file upload
      uploadFile(file);
    }
  };

  const uploadFile = async (file) => {
    try {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          const success = Math.random() > 0.3; // 70% chance of success
          if (success) resolve();
          else reject(new Error('Failed to upload file.'));
        }, 2000); // 2-second delay for demonstration
      });

      // On success
      setIsLoading(false);
      setIsUploaded(true);
      setUploadedFiles({ screenshotFile: file.name });
    } catch (error) {
      // On failure
      setIsLoading(false);
      setUploadError(error.message);
    }
  };

  return (
    <CustomModal isModalOpen={isModalOpen} closeModal={closeModal}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          background: '#FAFAFA',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'flex-start',
              gap: '55px',
              width: '80vw',
              height: 'calc(100vh - 300px)',
              borderRadius: '12px',
              margin: 'auto',
              border: '1px solid #EAEDF2',
              background: '#FFF',
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
            }}
          
          className="hide-scrollbar"
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '450px',
            }}
          >
            <Title
              title={'Set custom portfolio'}
              subtitle={
                'Our team currently allocates your portfolio based on your company’s needs. Feel free to adjust the plan if you deem it necessary'
              }
            />

            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '548px',
                paddingBottom: '24px',
                alignItems: 'flex-start',
                gap: '16px',
              }}
            >
              {plans.map((plan) => (
                <div
                  key={plan.id}
                  style={{
                    ...getButtonStyles(plan.id),
                    display: 'flex',
                    padding: '16px',
                    width: '100%',
                    height: '95px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '12px',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleSelectPlan(plan.id)}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      gap: '8px',
                      flex: '1 0 0',
                    }}
                  >
                    <span
                      style={{
                        color: '#09090B',
                        textAlign: 'center',
                        height: '33px',
                        fontFamily: 'Lato',
                        fontSize: '22px',
                        fontWeight: '500',
                        lineHeight: '33px',
                      }}
                    >
                      {plan.title}
                    </span>
                    <span
                      style={{
                        color: '#3F3F46',
                        height: '22px',
                        fontFamily: 'Lato',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '21.7px',
                      }}
                    >
                      {plan.description}
                    </span>
                  </div>
                  {plan.recommended && (
                    <span
                      style={{
                        display: 'flex',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        background: '#CDC7FF',
                        position: 'relative',
                        margin: 'auto',
                        color: '#572875',
                      }}
                    >
                      Recommended
                    </span>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          background: '#FAFAFA',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '55px',
              width: '80%',
              borderRadius: '12px',
              margin: 'auto',
              border: '1px solid var(--Border-1, #EAEDF2)',
              background: 'var(--Background-Primary, #FFF)',
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
            }}
            className="hide-scrollbar"
          ></div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              width: '80%',
              height: '143px',
              display: 'flex',
              padding: '28px 133px',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignSelf: 'stretch',
              border: '1px #EAEDF2',
              background: '#FFF',
              boxShadow: '0px -5px 47px 0px rgba(0, 0, 0, 0.03)',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '(--2, 8px)',
              }}
            >
              <span
                style={{
                  color: 'var(--Text-Sub, #757575)',
                  height: '20px',
                  fontStyle: 'normal',
                  fontFamily: 'Lato',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                }}
              >
                You have selected
              </span>

              <span
                style={{
                  color: '#09090B',
                  height: '31px',
                  fontStyle: 'normal',
                  fontFamily: 'Lato',
                  fontSize: '22px',
                  fontWeight: '500',
                  lineHeight: '140%',
                }}
              >
                {selectedPlanOne}
              </span>

              <span
                style={{
                  color: 'var(--Text-Sub, #757575)',
                  height: '20px',
                  fontStyle: 'normal',
                  fontFamily: 'Lato',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '20px',
                }}
              >
                The changes to your portfolio will take effect in 1–4 business
                days.
              </span>
            </div>

            <button
              style={{
                display: 'flex',
                width: '340px',
                height: '38px',
                padding: '8px var(--Layout-x4, 16px)',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 'var(--Layout-x2, 8px)',
                borderRadius: 'var(--Radius-Large, 12px)',
                background: 'var(--Buttons-Primary, #1E1E20)',
                border:'none'
              }}
              onClick={submitThirdModal}
            >
              <span
                style={{
                  color: 'var(--Text-Invert, #FFF)',
                  textAlign: 'center',
                  height: '22px',
                  fontStyle: 'normal',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '140%',
                }}
              >
                Confirm and Set
              </span>
            </button>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}

export default ThirdModal;
