import React from 'react';
import { useRequests } from './components/requestContext';
import PurchaseIcon from '../../../assests/img/ArrowwIcon.png';
import LiquidationIcon from '../../../assests/img/DownArrowIcon.png';
import PlusIcon from '../../../assests/img/PlusIcon.png';

const RequestPage = () => {
  const { requests } = useRequests();

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        padding: '28px',
        boxSizing: 'border-box',

      }}
    >
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingBottom: '24px',
        }}
      >
        <span
          style={{
            color: '#121212',
            fontFamily: 'Lato',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '140%',
          }}
        >
          Requests
        </span>
        <div
          style={{
            display: 'flex',
            alignItems: 'flex-start',
            gap: '8px',
            alignSelf: 'stretch',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <button
            style={{
              display: 'flex',
              height: '36px',
              width: '125px',
              padding: '4px 8px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '4px',
              borderRadius: '8px',
              background: '#1E1E20',
              border: 'none',
              flexDirection: 'row',
            }}
          >
            <img
              style={{
                display: 'flex',
                width: '16px',
                height: '16px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              src={PlusIcon}
              alt="Plus"
              className="img-fluid"
            />
            <h1
              style={{
                color: '#FFFFFF',
                width: '100px',
                textAlign: 'center',
                fontFamily: 'Inter',
                fontSize: '13px',
                fontWeight: '400',
                lineHeight: '20px',
                margin: '0',
              }}
            >
              Export Report
            </h1>
          </button>
          <select
            style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'column',
              justifyContent: 'center',
              gap: '18px',
              borderRadius: '8px',
              border: 'none',
              backgroundColor: '#FAFAFA',
              fontFamily: 'Lato',
              fontSize: '16px',
              height: '36px',
              width: '104px',
            }}
            type="Currency"
            className="form-select"
            id="exampleGovernment"
            placeholder="Currency"
          >
            <option value="">Today</option>
            <option value="Week">Week to date</option>
            <option value="Month">Month to date</option>
            <option value="Quarter">Quarter to date</option>
            <option value="Year">Year to date</option>
            <option value="Inception">Since inception</option>
          </select>
        </div>
      </div>

      <table
        className="table"
        style={{
          width: '100%',
          border: '#FAFAFA',
          

        }}
      >
        <thead style={{}}>
          <tr
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'space-between',
              background: '#FAFAFA',
              padding: '8px',
              borderRadius: '8px 8px 0px 0px',

            }}
          >
            <th
              style={{
                flex: 2, 
                padding: '8px',
                textAlign: 'left',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',

              }}
            >
              Requests
            </th>
            <th
              style={{
                flex: 1,
                padding: '8px 12px',
                textAlign: 'left',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
              }}
            >
              Date
            </th>
            <th
              style={{
                flex: 1,
                padding: '8px 12px',
                textAlign: 'left',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
              }}
            >
              Type
            </th>
            <th
              style={{
                flex: 1,
                padding: '8px 12px',
                textAlign: 'right',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '20px',
                
              }}
            >
              Amount
            </th>
          </tr>
        </thead>

        <tbody>
          {requests.map((request, index) => (
            <tr
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '8px',
                border: 'white',
                
              

              }}
            >
              <td
                style={{
                  flex: 2, 
                  padding: '8px',
                  textAlign: 'left',
                }}
              >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <img
                    style={{ marginRight: '8px' }}
                    src={
                      request.type === 'Purchase' ? PurchaseIcon : LiquidationIcon
                    }
                    alt="icon"
                  />
                  <div>
                    <div>{request.requestName}</div>
                    <div
                      style={{
                        fontSize: '10px',
                        fontWeight: '400',
                        color: '#797979',
                      }}
                    >
                      {request.reference}
                    </div>
                  </div>
                </div>
              </td>
              <td
                style={{
                  flex: 1,
                  padding: '8px 12px',
                  textAlign: 'left',
                  fontSize: '14px',
                  color: '#121212',
                }}
              >
                {request.date}
              </td>
              <td
                style={{
                  flex: 1,
                  padding: '8px 12px',
                  textAlign: 'left',
                  color: '#121212',
                }}
              >
                {request.type}
              </td>
              <td
                style={{
                  flex: 1,
                  padding: '8px 12px',
                  textAlign: 'right',
                  fontSize: '14px',
                  fontWeight: '400',
                }}
              >
                {request.amount}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RequestPage;
