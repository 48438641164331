import React from 'react';
import SideNavBar from './sideNavBar';
import { Outlet } from 'react-router-dom';
import Top from '../../screens/treasury/top';

const LoansLayout = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        overflow: 'hidden',
        width: "100vw"
      }}
    >
      <SideNavBar />
      <div style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflowY: 'auto',
        }}>
         <Top />

          <div
            style={{
          
              //padding:"0 5vw"
            }}
          >
            <Outlet /> 
          </div>
      </div>
    </div>
  );
};

export default LoansLayout;
