import React from 'react';
import CreditIcon from '../../../../assests/img/NegativeIcon.png';
import DebitIcon from '../../../../assests/img/PlussIcon.png';

const TransactionItem = ({ fund, date, type, amount, reference }) => {
  const typeClass = type === 'Credit' ? 'transaction-type-credit' : 'transaction-type-debit';
  const icon = type === 'Credit' ? CreditIcon : DebitIcon;

  return (
    <div className="transaction-item">
      <div className="transaction-details">
        <img style={{ marginRight: '8px' }} src={icon} alt={`${type} Arrow`} />
        <div className="transaction-info">
        <div style={{
          display: 'flex',
          flexDirection: 'column'
        }}>
          <div className="transaction-fund">{fund}</div>
          <div className="transaction-reference1">{reference}</div>
          </div>
        </div>
      </div>
      <div className="transaction-reference">{date}</div>

      <div className={typeClass}>{type}</div>

      <div className="transaction-amount">{amount}</div>
    </div>
  );
};

export default TransactionItem;
