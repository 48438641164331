import React, { useState, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import ResendButton from '../../components/resendButton';
import InputField from '../../../loans/components/inputfield';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import tick from '../../../../assests/img/tick.png';
import axiosInstance from '../../../../utils/axiosInstance';


const SignUpRightContainerOne = () => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (isButtonDisabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsButtonDisabled(false);
      setCountdown(60);
    }
    return () => clearInterval(timer);
  }, [isButtonDisabled, countdown]);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    verificationCode: Yup.string().required('Verification code is required'),
  });

  const handleVerifyEmail = async (values, setFieldError) => {
    if (!values.email) return;

    setIsButtonDisabled(true);

    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/register`, {
        email: values.email,
      });
      toast.success('Verification code sent to your email', {
        autoClose: 3000,
        position: 'bottom-center',
        className: 'custom-toast',
        bodyClassName: 'custom-body-toast',
        icon: <div className="custom-toast-icon"><img src={tick} alt="Success" style={{ width: '30px', height: '30px' }} /></div>,
        progressClassName: 'custom-toast-progress',
        style: { width: '350px' },
      });
    } catch (error) {
      setFieldError(
        'general',
        `Error sending verification mail: ${error.response?.data?.error || error.message}`
      );
      setIsButtonDisabled(false);
    }
  };

  const onSubmit = async (values, { setSubmitting, setFieldError }) => {
    setSubmitting(true);
    try {
      const response = await axiosInstance.post('/verify', {
        email: values.email,
        verificationCode: values.verificationCode,
      });
      
      console.log('Verification response:', response.data);

      if (response.data.token) {
        sessionStorage.setItem('token', response.data.token);
        console.log('Token stored in sessionStorage');
      } else {
        console.warn('No token received from server');
        throw new Error('No token received from server');
      }

      if (response.data.userId) {
        localStorage.setItem('userId', response.data.userId);
        console.log('UserId stored in localStorage');
      } else {
        console.warn('No userId received from server');
      }

      // Use setTimeout to ensure storage is updated before navigation
      setTimeout(() => {
        navigate(`/registration?email=${values.email}`, { state: { fromSignup: true } });
      }, 200); 

    } catch (error) {
      console.error('Verification error:', error);
      setFieldError('verificationCode', 'Verification failed. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="col-md-6 d-flex flex-column justify-content-center align-items-center style">
      <div className="w-75">
        <h1
          style={{
            fontSize: '32px',
            fontFamily: 'Lato',
            fontWeight: 'bold',
            marginBottom: '12px',
          }}
        >
          Start with Bokra
        </h1>
        <p
          style={{ fontSize: '18px', fontFamily: 'Lato', marginBottom: '32px' }}
        >
          Use company's email to get started
        </p>

        <Formik
          initialValues={{ email: '', verificationCode: '' }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, setFieldError, isSubmitting, values }) => (
            <Form>
              <div
                style={{
                  marginBottom: '16px',
                }}
              >
                <InputField
                  name="email"
                  label={'Email'}
                  type="text"
                  errors={errors.email}
                  touched={touched.email}
                  resendButtonProps={{
                    isButtonDisabled: isButtonDisabled,
                    setIsButtonDisabled: setIsButtonDisabled,
                    countdown: countdown,
                    setCountdown: setCountdown,
                    handleSendOTP: () => handleVerifyEmail(values, setFieldError),
                  }}
                />
              </div>
          

              <ErrorMessage
                name="general"
                component="p"
                className="text-center text-danger mb-3"
              />

              <div
                style={{
                  marginBottom: '16px',
                }}
              >
                <InputField
                  name="verificationCode"
                  type="text"
                  label={'Verification code'}
                  placeholder="Verification Code"
                  errors={errors.verificationCode}
                  touched={touched.verificationCode}
                />
              </div>
              <button
                type="submit"
                className="btn btn-dark btn-custom-size-continue w-100"
                disabled={isSubmitting}
              >
                Continue to set up your company
              </button>
            </Form>
          )}
        </Formik>

        <p
          className="text-center mb-3"
          style={{ fontSize: '18px', fontFamily: 'Lato', marginBottom: '16px' }}
        >
          Already have an account?{' '}
          <a
            href="/login"
            style={{
              fontWeight: 'bold',
              cursor: 'pointer',
              textDecoration: 'none',
              color: 'black',
            }}
          >
            Sign in now
          </a>
        </p>
      </div>
    </div>
  );
};

export default SignUpRightContainerOne;
