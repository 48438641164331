import React, { useState, useEffect } from 'react';
import iconImage from '../../assests/img/Bosch.png';
import iconHome from '../../assests/img/iconHome.svg';
import TreasuryIcon from '../../assests/img/TreasuryIcon.svg';
import ActionRequestsIcon from '../../assests/img/ActionRequestsIcon.svg';
import RetirementIcon from '../../assests/img/RetirementIcon.svg';
import SettingsIcon from '../../assests/img/SettingsIcon.svg';
import LogoutIcon from '../../assests/img/LogoutIcon.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../../utils/axiosInstance';

function SideNavBar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeButton, setActiveButton] = useState('overview');
  const [companyName, setCompanyName] = useState('');


  const handleButtonClick = (buttonName, path) => {
    setActiveButton(buttonName); // Immediately highlight the button
    navigate(path); // Navigate to the new page
  };

  const isActive = (button) => activeButton === button;


  useEffect(() => {
    const fetchCompanyName = async () => {
      try {
        const userId = localStorage.getItem('userId');
        if (userId) {
          const response = await axiosInstance.get(`/companyDetails?userId=${userId}`);
          if (response.data && response.data.companyName) {
            setCompanyName(response.data.companyName);
          }
        }
      } catch (error) {
        console.error('Error fetching company name:', error);
      }
    };

    fetchCompanyName();
  }, []);

  useEffect(() => {
    if (location.pathname.includes('treasury')) {
      if(location.pathname.includes('transactions'))
      {
        setActiveButton('transactions');
      }
      else if (location.pathname.includes('requests')) {
        setActiveButton('requests');
      }
      else{
      setActiveButton('treasury');
    }
  }else if (location.pathname.includes('retirement')) {
     if (location.pathname.includes('transactions1')) {
      setActiveButton('transactions1');
    } else if (location.pathname.includes('segments')) {
      setActiveButton('segments');
    } 
    else{ setActiveButton('retirement');}
     
    } else if (location.pathname.includes('overview')) {
      setActiveButton('overview');
    } 
    else {
      setActiveButton('overview');
    }
  }, [location.pathname]);


  console.log(location.pathname)

  const handleLogout = () => {
    // Remove token from sessionStorage
    sessionStorage.removeItem('token');
    // Remove userId from localStorage if you're storing it there
    localStorage.removeItem('userId');
    // Navigate to login page
    navigate('/login');
  };


  return (
    <div
      style={{
        minHeight: '990px',
        height: 'auto',
        width: '232px',
        padding: '16px',
        display: 'flex',
        flexDirection: 'column',
        gap: '8px',
        flexShrink: '0',
        alignItems: 'center',
        borderRight: '1px solid #E8E8E8',
      }}
      className="overview"
    >
      <div
        style={{
          display: 'flex',
          paddingBottom: '12px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '4px',
          alignSelf: 'stretch',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '8px',
            alignItems: 'center',
          }}
        >
          <div>
            <img
              style={{
                display: 'flex',
                width: '40px',
                height: '40px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              src={iconImage}
              alt="Verification"
              className="img-fluid"
            />
          </div>
          <h1
            style={{
              fontFamily: 'Lato',
              fontSize: '28px',
              fontWeight: '600',
              alignItems: 'center',
              height: '39px',
              width: '76px',
              margin: '0px',
              textAlign: 'center',
            }}
          >
            {companyName }
          </h1>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          paddingBottom: '12px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
        }}
      >
        <h1
          style={{
            color: '#757575',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '140%',
            margin: '0',
            alignSelf: 'flex-start',
            textAlign: 'left',
          }}
        >
          Home
        </h1>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '12px',
            width: '100%',
          }}
        >
          <button
            style={{
              display: 'flex',
              padding: '12px',
              alignItems: 'center',
              gap: '12px',

              width: '100%',
              height: '44px',
              fontSize: '14px',
              borderRadius: '16px',
              fontFamily: 'Inter',
              fontWeight: '400',
              lineHeight: '140%',
              background: isActive('overview') ? '#FDFAFF' : '#FFFFFF', // Background color when active
              border: 'none',
              color: isActive('overview') ? '#000' : '#000',
              fontSize: '14px',
              outline: 'none',
              boxShadow: 'none', // Prevent blue highlight on collapse
              WebkitTapHighlightColor: 'transparent',
            }}
            type="button"
            onClick={() => handleButtonClick('overview', '/dashboard')}
          >
            <img
                          src={iconHome}
                          alt=" iconHome"
                        />
            Overview
          </button>
        </div>

        <div
          className="accordion"
          id="accordionExample"
          style={{ width: '100%' }}
        >
          {/* Treasury Accordion */}
          <div className="accordion-item" style={{ border: 'none' }}>
            <h2 className="accordion-header" id="headingTreasury">
              <button
                style={{
                  display: 'flex',
                  padding: '12px',
                  alignItems: 'center',
                  gap: '12px',
                  width: '100%',
                  height: '44px',
                  fontSize: '14px',
                  borderRadius: '16px',
                  fontFamily: 'Inter',
                  fontWeight: '400',
                  lineHeight: '140%',
                  background: isActive('treasury') ? '#FDFAFF' : '#FFFFFF', // Background color when active
                  border: 'none',
                  color: isActive('treasury') ? '#000' : '#000',
                  fontSize: '14px',
                  outline: 'none',
                  boxShadow: 'none', // Prevent blue highlight on collapse
                  WebkitTapHighlightColor: 'transparent',
                }}
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTreasury"
                aria-expanded={isActive('treasury')}
                aria-controls="collapseTreasury"
                onClick={() =>
                  handleButtonClick('treasury', '/dashboard/treasury')
                }
              >
                {/* Image */}
                <img
                  src={TreasuryIcon} // Replace with the image path
                  alt="Treasury Icon"
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                />
                {/* Text */}
                <span
                  style={{
                    width: '112px',
                    height: '20px',
                    textAlign: 'left',
                    flexGrow: 1,
                  }}
                >
                  Treasury
                </span>
                {/* Arrow */}
                <span
                  style={{
                    width: '20px',
                    height: '20px',
                    color: '#000',
                  }}
                >
                  <i className="bi bi-chevron-down"></i>{' '}
                  {/* Bootstrap icon for down arrow */}
                </span>
              </button>
            </h2>
            <div
              id="collapseTreasury"
              className="accordion-collapse collapse"
              aria-labelledby="headingTreasury"
              data-bs-parent="#accordionExample"
            >
              <div style={{ gap: '8px' }}>
                <button
                  style={{
                    display: 'flex',
                    //padding: '12px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '12px',
                    width: '100%',
                    height: '44px',
                    fontSize: '14px',

                    borderRadius: '16px',
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    lineHeight: '140%',
                    background: isActive('transactions')
                      ? '#FDFAFF'
                      : '#FFFFFF', // Background color when active
                    border: 'none',
                    color: isActive('transactions') ? '#000' : '#000',
                    fontSize: '14px',
                    outline: 'none',
                    boxShadow: 'none', // Prevent blue highlight on collapse
                    WebkitTapHighlightColor: 'transparent',
                  }}
                  type="button"
                  onClick={() =>
                    handleButtonClick(
                      'transactions',
                      '/dashboard/treasury/transactions'
                    )
                  }
                >
                  Transactions
                </button>
                <button
                  style={{
                    display: 'flex',
                    //padding: '12px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '12px',
                    width: '100%',
                    height: '44px',
                    fontSize: '14px',
                    left: 'px',
                    borderRadius: '16px',
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    lineHeight: '140%',
                    background: isActive('requests') ? '#FDFAFF' : '#FFFFFF', // Background color when active
                    border: 'none',
                    color: isActive('requests') ? '#000' : '#000',
                    fontSize: '14px',
                    outline: 'none',
                    boxShadow: 'none', // Prevent blue highlight on collapse
                    WebkitTapHighlightColor: 'transparent',
                  }}
                  type="button"
                  onClick={() =>
                    handleButtonClick(
                      'requests',
                      '/dashboard/treasury/requests'
                    )
                  }
                >
                  <span
                    style={{
                      marginLeft:'-1vw',
                      //position:'absolute',
                      alignContent:'center', // Offset 2 pixels to the left
                      fontSize: '14px', // Ensure the font size matches the button's
                    }}
                  >
                    Requests
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="accordion"
        id="accordionExample"
        style={{
          width: '100%',
          paddingBottom: '12px',
        }}
      >
        {/* Retirement Accordion */}
        <div className="accordion-item" style={{ border: 'none' }}>
          <h2 className="accordion-header" id="headingRetirement">
            <button
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '12px',
                gap: '12px',
                width: '200px',
                height: '44px',
                borderRadius: '16px',
                background: isActive('retirement') ? '#FDFAFF' : '#FFFFFF',
                border: 'none',
                color: isActive('retirement') ? '#000' : '#000',
                fontSize: '14px',
                outline: 'none',
                boxShadow: 'none', // Prevent blue highlight on collapse
                WebkitTapHighlightColor: 'transparent',
                fontSize: '14px',
              }}
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseRetirement"
              aria-expanded={isActive('retirement')}
              aria-controls="collapseRetirement"
              onClick={() =>
                handleButtonClick('retirement', '/dashboard/retirement')
              }
            >
              {/* Image */}
              <img
                src={RetirementIcon} // Replace with the image path
                alt="Retirement Icon"
                style={{
                  width: '20px',
                  height: '20px',
                }}
              />
              {/* Text */}
              <span
                style={{
                  width: '112px',
                  height: '20px',
                  textAlign: 'left',
                  flexGrow: 1,
                }}
              >
                Retirement
              </span>
              {/* Arrow */}
              <span
                style={{
                  width: '20px',
                  height: '20px',
                }}
              >
                <i className="bi bi-chevron-down"></i>{' '}
                {/* Bootstrap icon for down arrow */}
              </span>
            </button>
          </h2>
          <div
            id="collapseRetirement"
            className="accordion-collapse collapse"
            aria-labelledby="headingRetirement"
            data-bs-parent="#accordionExample"
          >
            <div style={{gap:'8px'}}>
              <button
                style={{
                  display: 'flex',
                    //padding: '12px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '12px',
                    width: '100%',
                    height: '44px',
                    fontSize: '14px',
                    left: 'px',
                    borderRadius: '16px',
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    lineHeight: '140%',
                    background: isActive('transactions1') ? '#FDFAFF' : '#FFFFFF', // Background color when active
                    border: 'none',
                    color: isActive('transactions1') ? '#000' : '#000',
                    fontSize: '14px',
                    outline: 'none',
                    boxShadow: 'none', // Prevent blue highlight on collapse
                    WebkitTapHighlightColor: 'transparent',
                  }}
                type="button"
              >
                Transactions
              </button>
              <button
                
                  style={{
                    display: 'flex',
                    //padding: '12px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '12px',
                    width: '100%',
                    height: '44px',
                    fontSize: '14px',
                    left: 'px',
                    borderRadius: '16px',
                    fontFamily: 'Inter',
                    fontWeight: '400',
                    lineHeight: '140%',
                    background: isActive('segments') ? '#FDFAFF' : '#FFFFFF', // Background color when active
                    border: 'none',
                    color: isActive('segments') ? '#000' : '#000',
                    fontSize: '14px',
                    outline: 'none',
                    boxShadow: 'none', // Prevent blue highlight on collapse
                    WebkitTapHighlightColor: 'transparent',
                  }}
                type="button"
                onClick={() =>
                  handleButtonClick(
                    'segments',
                    '/dashboard/retirement/segments'
                  )
                }
              >
                 <span
                    style={{
                      marginLeft:'-1vw',
                      alignContent:'center',
                      fontSize: '14px',
                    }}
                  >
                    Segments
                  </span>
                
              </button>
            </div>
          </div>
        </div>
        <div className="accordion-item" style={{ border: 'none' }}>
          {/* <h2 className="accordion-header" id="headingLoan">
            <button
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '12px',
                gap: '12px',
                width: '200px',
                height: '44px',
                borderRadius: '16px',
                background: isActive('loans') ? '#FDFAFF' : '#FFFFFF',
                fontSize: '14px',
              }}
              className="accordion-button collapsed"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseLoan"
              aria-expanded={isActive('loans')}
              aria-controls="collapseLoan"
              onClick={() => handleButtonClick('loans', '/dashboard/loans')}

            >
              <img
                src={LoansIcon} 
                alt="Loan Icon"
                style={{
                  width: '20px',
                  height: '20px',
                }}
              />
              <span
                style={{
                  width: '112px',
                  height: '20px',
                  textAlign: 'left',
                  flexGrow: 1,
                }}
              >
                Loan
              </span>
              <span
                style={{
                  width: '20px',
                  height: '20px',
                }}
              >
                <i className="bi bi-chevron-down"></i>{' '}
              </span>
            </button>
          </h2>
          <div
            id="collapseLoan"
            className="accordion-collapse collapse"
            aria-labelledby="headingLoan"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body" style={{ width:'100%' }}>
              <button
                style={{
                  display: 'flex',
                  fontFamily:'Inter',
                  fontSize:'14px',
                  flex:1,
                  flexDirection: 'row',
                  gap: '4px',
                  width: '9vw',
                  height:'36px',
                  padding: '8px',
                  borderRadius: '12px',
                  flexWrap: 'wrap',
                  border: 'none',
                  background: isActive('applications') ? '#FDFAFF' : '#FFFFFF',
                  textAlign: 'center',

                }}
                type="button"
                onClick={() => handleButtonClick('applications', '/dashboard/loans/applications')}

              >
                
                Applications
              </button>
            </div>
          </div> */}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          paddingBottom: '12px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          alignSelf: 'stretch',
        }}
      >
        <h1
          style={{
            color: '#757575',
            fontFamily: 'Inter',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '140%',
            margin: '0',
            alignSelf: 'flex-start',
            textAlign: 'left',
          }}
        >
          More
        </h1>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: '12px',
          }}
        >
          <button
            style={{
              display: 'flex',
              padding: '12px',
              alignItems: 'center',
              gap: '12px',
              width: '200px',
              height: '44px',
              fontSize: '14px',
              borderRadius: '16px',
              background: 'none',
              borderColor: '#FDFAFF',
              border: 'none',
            }}
            type="button"
          >
            <img
              style={{
                display: 'flex',
                width: '20px',
                height: '20px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              src={ActionRequestsIcon}
              alt="ActionRequests"
              className="img-fluid"
            />
            Action Requests
          </button>
        </div>
        <div
          className="accordion"
          id="accordionExample"
          style={{ width: '100%' }}
        >
          {/* Treasury Accordion */}
          <div className="accordion-item" style={{ border: 'none' }}>
            <h2 className="accordion-header" id="headingSettings">
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '12px',
                  gap: '12px',
                  width: '200px',
                  height: '44px',
                  borderRadius: '16px',
                  background: '#FFFFFF',
                  borderColor: '#FFFFFF',
                  fontSize: '14px',
                  outline: 'none',
                  boxShadow: 'none', // Prevent blue highlight on collapse
                  WebkitTapHighlightColor: 'transparent',
                  fontSize: '14px',
                  background: isActive('headingSettings')
                    ? '#FDFAFF'
                    : '#FFFFFF', // Background color when active
                  border: 'none',
                  color: isActive('headingSettings') ? 'black' : 'black',
                }}
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseSettings"
                aria-expanded="false"
                aria-controls="collapseSettings"
              >
                {/* Image */}
                <img
                  src={SettingsIcon} // Replace with the image path
                  alt="Settings Icon"
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                />
                {/* Text */}
                <span
                  style={{
                    width: '112px',
                    height: '20px',
                    textAlign: 'left',
                    flexGrow: 1,
                  }}
                >
                  Settings
                </span>
                {/* Arrow */}
                <span
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                >
                  <i className="bi bi-chevron-down"></i>{' '}
                  {/* Bootstrap icon for down arrow */}
                </span>
              </button>
            </h2>
            <div
              id="collapseSettings"
              className="accordion-collapse collapse"
              aria-labelledby="headingSettings"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body" style={{ paddingLeft: '32px' }}>
                <button
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: 'none',
                    background: 'none',
                    textAlign: 'left',
                  }}
                  type="button"
                >
                  Add Account
                </button>
              </div>
            </div>
          </div>
        </div>
        <button
          style={{
            display: 'flex',
            padding: '12px',
            alignItems: 'center',
            gap: '12px',
            width: '200px',
            height: '44px',
            fontSize: '14px',
            borderRadius: '16px',
            background: '#FFFFFF',
            borderColor: '#FDFAFF',
            color: '#BB2124',
            border: 'none',
          }}
          type="button"
          onClick={handleLogout}  // Add this onClick handler
        >
          <img
            style={{
              display: 'flex',
              width: '20px',
              height: '20px',
              justifyContent: 'center',
              alignItems: 'center',
            }}
            src={LogoutIcon}
            alt="LogOut"
            className="img-fluid"
          />
          Logout
        </button>
      </div>
    </div>
  );
}

export default SideNavBar;