import React from 'react';
import { Formik, Form } from 'formik';
import InputField from '../../../loans/components/inputfield';

const AddBankAccountModal = ({ onBack, onSave, onClose }) => {
  const getModalStyle = () => ({
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'clamp(280px, 90vw, 581px)',
    maxHeight: '90vh',
    background: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    padding: '32px',
    borderRadius: '12px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    overflowY: 'auto',
  });

  const inputStyle = {
    width: '100%',
    fontSize: '14px',
  };

  const inputWrapperStyle = {
    marginBottom: '16px',
  };

  const buttonStyle = {
    padding: '10px 20px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 'bold',
  };

  const initialValues = {
    accountName: '',
    accountNumber: '',
    iban: '',
    bankName: '',
    branchName: '',
  };

  return (
    <div style={getModalStyle()}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '32px' }}>
        <h2 style={{ margin: 0, fontSize: '18px', fontWeight: 'normal' }}>Add bank account</h2>
        <button onClick={onClose} style={{ background: 'none', border: 'none', fontSize: '20px', cursor: 'pointer' }}>×</button>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          onSave(values);
        }}
      >
        {({ values, handleChange, handleBlur }) => (
          <Form>
            <div style={inputWrapperStyle}>
              <InputField
                style={inputStyle}
                type="text"
                name="accountName"
                label="Account Name"
                value={values.accountName}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
            </div>
            <div style={inputWrapperStyle}>
              <InputField
                style={inputStyle}
                type="text"
                name="accountNumber"
                label="Account Number"
                value={values.accountNumber}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
            </div>
            <div style={inputWrapperStyle}>
              <InputField
                style={inputStyle}
                type="text"
                name="iban"
                label="IBAN"
                value={values.iban}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
            </div>
            <div style={inputWrapperStyle}>
              <InputField
                style={inputStyle}
                type="select"
                name="bankName"
                label="Bank Name"
                value={values.bankName}
                onChange={handleChange}
                onBlur={handleBlur}
                required
                options={[
                  { value: '', label: 'Select Bank' },
                  { value: 'bank1', label: 'Bank 1' },
                  { value: 'bank2', label: 'Bank 2' },
                ]}
              />
            </div>
            <div style={inputWrapperStyle}>
              <InputField
                style={inputStyle}
                type="text"
                name="branchName"
                label="Branch Name"
                value={values.branchName}
                onChange={handleChange}
                onBlur={handleBlur}
                required
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '20px', gap: '16px' }}>
              {onBack && (
                <button type="button" onClick={onBack} style={{...buttonStyle, backgroundColor: 'white', width: '100%', color: '#333', border: '1px solid #E0E0E0'}}>
                  Back
                </button>
              )}
              <button type="submit" style={{...buttonStyle, backgroundColor: '#000', color: '#FFF', width: '100%'}}>
                Withdraw amount
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddBankAccountModal;