import React from 'react';
import Top from '../treasury/top';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReportIcon from '../../assests/img/ReportIcon.png';
import TotalRetirementBalance from './totalRetirementBalance/TotalRetirementBalance';
import Calculator from './calculator/Calculator';
import AssetDistribution from './assetDistribution/AssetDistribution';
import TransactionsRetirement from './transactions';

function RetirementScreen() {
  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          //   overflowY: 'auto',
          width: '100%',
        }}
      >
        <Top />

        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            paddingTop: '23px',
            paddingLeft: '32px',
            paddingRight: '32px',
            paddingBottom: '23px',
          }}
        >
          <div
            style={{
              color: '#0F0F10',
              fontFamily: 'Lato',
              fontSize: '24px',
              fontStyle: 'normal',
              fontWeight: '700',
              lineHeight: '140%',
            }}
          >
            Retirement
          </div>
          <button
            style={{
              display: 'flex',
              padding: '8px 16px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              borderRadius: '12px',
              background: '#1E1320',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '8px',
              }}
            >
              <img src={ReportIcon} alt="Report" />
              <span
                style={{
                  color: '#FFF',
                  textAlign: 'center',
                  fontFamily: 'Lato',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '140%',
                }}
              >
                Export Report
              </span>
            </div>
          </button>
        </div>
        <div
          style={{
            paddingLeft: '32px',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '32px',
            }}
          >
            <TotalRetirementBalance />
            <Calculator />
          </div>
          <AssetDistribution />
          <TransactionsRetirement/>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </div>
  );
}

export default RetirementScreen;
