import React from 'react';
import Modal from 'react-modal';
import { useMediaQuery } from '@mui/material';

const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Offboard Employee Confirmation"
      style={{
        content: {
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: isSmallScreen ? '95%' : '90%',
          maxWidth: isSmallScreen ? '100%' : '31.25rem', 
          height: '17rem',
          background: '#FFF',
          display: 'flex',
          flexDirection: 'column',
          padding: '1.5rem', 
          alignItems: 'stretch',
          gap: '1rem', 
          borderRadius: '0.75rem',
          border: '1px solid #EAEDF2',
          boxShadow: '0px 0.25rem 7.5rem 0px rgba(145, 186, 131, 0.10)',
          overflow: 'hidden',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <div
        style={{
          textAlign: 'center',
          marginBottom: '1rem',
        }}
      >
        <span
          style={{
            color: '#171717',
            fontFamily: 'Lato',
            fontSize: '1.25rem',
            fontWeight: 600,
            lineHeight: 1.4,
          }}
        >
          Offboard Employee
        </span>
      </div>

      <div
        style={{
          color: '#171717',
          fontFamily: 'Lato',
          fontSize: '0.875rem',
          fontWeight: 400,
          lineHeight: 1.5,
        }}
      >
        <p>Are you sure you want to offboard this employee from the pension plan?</p>
        <span>
          By proceeding, the employee will be removed from the plan and will no longer
          have access to their pension account through our platform. This action is irreversible.
        </span>
      </div>

      <div style={{ marginTop: 'auto' }}>
        <button
          onClick={onConfirm}
          style={{
            backgroundColor: '#F8D7DA',
            color: '#C82333',
            fontFamily: 'Lato',
            fontSize: '0.875rem',
            fontWeight: 600,
            border: 'none',
            padding: '0.75rem', 
            borderRadius: '0.5rem',
            cursor: 'pointer',
            width: '100%',
            textAlign: 'center',
          }}
        >
          Withdraw amount and offboard employee
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
