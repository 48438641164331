import React, { useState } from 'react';
import TransactionItem from './components/transactionItem'
import RequestItem from './components/requestItem'


function TransactionsAndRequests() {

  return (
    <div
    style={{
      display: 'flex',
      flexDirection: 'row',
      gap: '16px',
      paddingTop:'17px',
      width:'100%',
      height:'auto'
    }}
  >
      <div
      style={{
        display: 'flex',
        width: '100%',
        minHeight:'514px',
        height: 'auto',
        padding: '16px',
        flexDirection: 'column',
        flex:1,
        gap: '16px',
        flexShrink: '0',
        borderRadius: '12px',
        border:
          'var(--Letter-spacing-Letter-spacing, 1px) solid var(--Border-1, #EAEDF2)',
        background: '#FFF',
      }}
    >
      <span
        style={{
          color: '#171717',
          fontFamily: 'Lato',
          fontSize: '20px',
          fontWeight: '600',
          lineHeight: '140%',
        }}
      >
        Recent Transactions
      </span>

      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width:'100%',
            flex:1,
          }}
        >
          <div
            style={{
              display: 'flex',
              flex:1,
              //width: '307px',
              padding: '8px 12px',
              alignItems: 'center',
              height: '36px',
              gap: '8px',
              borderRadius: ' 8px 0px 0px 0px',
              background: '#FAFAFA',
              color: '#1F2023',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
            }}
          >
            Transactions
          </div>

          <div
            style={{
              display: 'flex',
              flex:1,
              //width: '117.5px',
              padding: '8px 12px',
              alignItems: 'center',
              justifyContent:'center',
              height: '36px',
              gap: '8px',
              borderRadius: ' 8px 0px 0px 0px',
              background: '#FAFAFA',
              color: '#1F2023',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
            }}
          >
            Type
          </div>

          <div
            style={{
              display: 'flex',
              flex:1,
              //width: '117.5px',
              padding: '8px 12px',
              alignItems: 'center',
              justifyContent:'right',
              height: '36px',
              gap: '8px',
              borderRadius: ' 8px 0px 0px 0px',
              background: '#FAFAFA',
              color: '#1F2023',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
            }}
          >
            Amount
          </div>
        </div>

        <div
            style={{
              display: 'flex',
              padding: '12 8px',
              alignItems: 'center',
              alignSelf: 'stretch',
            }}
        ></div>
      </div> */}
      <TransactionItem/>
    </div>

    <div
      style={{
        display: 'flex',
        width: '574px',
        minHeight:'514px',
        height: 'auto',
        padding: '16px',
        flexDirection: 'column',
        flex:1,
        gap: '16px',
        flexShrink: '0',
        borderRadius: '12px',
        border:
          'var(--Letter-spacing-Letter-spacing, 1px) solid var(--Border-1, #EAEDF2)',
        background: '#FFF',
      }}
    >
      <span
        style={{
          color: '#171717',
          fontFamily: 'Lato',
          fontSize: '20px',
          fontWeight: '600',
          lineHeight: '140%',
        }}
      >
        Recent Requests
      </span>

      {/* <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            width:'100%',
            flex:1,
          }}
        >
          <div
            style={{
              display: 'flex',
              flex:1,
              width: '300px',
              padding: '8px 12px',
              alignItems: 'center',
              height: '36px',
              gap: '8px',
              borderRadius: ' 8px 0px 0px 0px',
              background: '#FAFAFA',
              color: '#1F2023',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
            }}
          >
            Requests
          </div>

          <div
            style={{
              display: 'flex',
              flex:1,
              //width: '117.5px',
              padding: '8px 12px',
              alignItems: 'center',
              justifyContent:'left',
              height: '36px',
              gap: '8px',
              borderRadius: ' 8px 0px 0px 0px',
              background: '#FAFAFA',
              color: '#1F2023',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
            }}
          >
            Type
          </div>

          <div
            style={{
              display: 'flex',
              flex:1,
              //width: '117.5px',
              padding: '8px 12px',
              alignItems: 'right',
              justifyContent:'right',
              height: '36px',
              gap: '8px',
              borderRadius: ' 8px 0px 0px 0px',
              background: '#FAFAFA',
              color: '#1F2023',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
            }}
          >
            Amount
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            padding: '12 8px',
            alignItems: 'center',
            alignSelf: 'stretch',
          }}
        ></div> */}
      {/* </div> */}
      <RequestItem/>
    </div>
  </div>
  );
}

export default TransactionsAndRequests;
