import React from 'react';
import { useTransactions } from '../../transactionsPage/components/transactionContext';
import '../../../../styles/TransactionItem.css';
import DebitIcon from '../../../../assests/img/NegativeIcon.png';
import CreditIcon from '../../../../assests/img/PlussIcon.png';

const TransactionItem = ({ fund, date, type, amount }) => {
  const typeClass =
    type === 'Credit' ? 'request-type-credit' : 'request-type-debit';
  const icon = type === 'Credit' ? CreditIcon : DebitIcon;

  return (
    <tr>
      {/* Transaction Details */}
      <td style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
        <img
          style={{ marginRight: '8px' }}
          src={icon}
          alt={`${type} Arrow`}
          width="16"
        />
        <div>
          <div className="transaction-fund">{fund}</div>
          <div
            className="transaction-date"
            style={{ fontSize: '12px', color: '#757575' }}
          >
            {date}
          </div>
        </div>
      </td>

      <td style={{ textAlign: 'center', padding: '10px 0' }}>
        <span className={typeClass}>{type}</span>
      </td>

      <td style={{ textAlign: 'right', padding: '10px 0' }}>
        <span className="transaction-amount">{amount}</span>
      </td>
    </tr>
  );
};

const TransactionList = () => {
  const { transactions, addTransaction } = useTransactions();

  const handleAddTransaction = (type) => {
    const currentDate = new Date().toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
    const newTransaction = {
      fund: 'B 72 Yawmy MM Fund @49.11',
      date: currentDate,
      type: type,
      amount: 'EGP 50,000',
    };

    addTransaction(newTransaction);
  };

  return (
    <div>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ backgroundColor: '#FAFAFA' }}>
            <th
              style={{
                textAlign: 'left',
                padding: '12px',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '20px',
              }}
            >
              Transactions
            </th>
            <th
              style={{
                textAlign: 'center',
                padding: '12px',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '20px',
              }}
            >
              Type
            </th>
            <th
              style={{
                textAlign: 'right',
                padding: '12px',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '20px',
              }}
            >
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => (
            <TransactionItem
              key={index}
              fund={transaction.fund}
              date={transaction.date}
              type={transaction.type}
              amount={transaction.amount}
            />
          ))}
        </tbody>
      </table>
      <button onClick={() => handleAddTransaction('Debit')}>
        Add Debit Transaction
      </button>
      <button onClick={() => handleAddTransaction('Credit')}>
        Add Credit Transaction
      </button>
    </div>
  );
};

export default TransactionList;
