import React, { useState, useEffect, useContext } from 'react';
import Top from '../../treasury/top';
import AlienIcon from '../../../assests/img/applicationsloan.png';
import { ApplicationContext } from '../components/ApplicationContext';
import { useNavigate } from 'react-router-dom';
import PlusIcon from '../../../assests/img/PlusIcon2.png';

function LoanApplication() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const closeModal = () => setIsModalOpen(false);
  const { applications } = useContext(ApplicationContext);
  const openModal = () => setIsModalOpen(true);
  const handleCreateNewLoan = () => {
    navigate('/dashboard/loans');
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        padding: '24px',
      
       
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'start',
          paddingBottom: '16px',
          width: '100%',
        }}
      >
        <h1
          style={{
            fontFamily: 'Lato',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '700',
          }}
        >
          Applications
        </h1>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignSelf: 'center',
          //height: '372px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {(applications?.length ?? 0) === 0 ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                alignSelf: 'center',
                width: '50vw',
                marginTop: '163px',
              }}
            >
              <img src={AlienIcon} alt="No Applications" />
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <h1
                  style={{
                    color: '#121212',
                    alignItems: 'center',
                    fontFamily: 'Lato',
                    fontSize: '24px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '150%',
                  }}
                >
                  {"You don't have applications yet"}
                </h1>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <span
                  style={{
                    color: '#757575',
                    alignItems: 'center',
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '160%',
                  }}
                >
                  This is where you'll see applications
                </span>
              </div>
              <button
                style={{
                  display: 'flex',
                  padding: '8px ',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                  alignSelf: 'stretch',
                  borderRadius: '12px',
                  background: '#1E1E20',
                  height: '38px',
                  marginTop: '44px',
                  boxShadow: 'none',
                  border: 'none',
                  outline: 'none',
                }}
                onClick={handleCreateNewLoan}
              >
                <span
                  style={{
                    color: '#FFFFFF',
                    textAlign: 'center',
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '140%',
                  }}
                >
                  Submit Application
                </span>
              </button>
            </div>
          ) : (
            <div>
              {applications.map((application, index) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    width: '80vw',
                    flexDirection: 'column',
                    
                    alignItems: 'flex-start',
                    padding: '24px',
                    gap: '16px',
                    alignSelf: 'stretch',
                    border: '1px solid var(--Border-1, #EAEDF2)',
                    borderRadius: '12px',
                    marginBottom: '24px',
                    cursor: 'pointer',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      flexDirection: 'row',
                      
                    }}
                  >
                    <span
                      style={{
                        color: '#121212',
                        fontFamily: 'Lato',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '140%',
                      }}
                    >
                      Loan Application #{application.applicationNumber + 1}
                    </span>
                    <span
                      style={{
                        color: '#757575',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '140%',
                      }}
                    >
                      Created on {application.applicationDate}
                    </span>
                  </div>

                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      flexDirection: 'row',
                    }}
                  >
                    <span
                      style={{
                        display: 'flex',
                        padding: '4px',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '4px',
                        borderRadius: '4px',
                        background: '#FFDFBF',
                        color: '#BF6000',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '140%',
                        width: '114px',
                        height: '30px',
                      }}
                    >
                      Under Review
                    </span>
                    <div
                      style={{
                        display: 'flex',
                        padding: '8px',

                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      <button
                        style={{
                          backgroundColor: '#1E1E20',
                          color: 'white',
                          border: 'none',
                          padding: '8px',
                          borderRadius: '12px',
                          cursor: 'pointer',
                          fontFamily: 'Lato',
                          fontSize: '16px',
                          width: '108px',
                          height: '38px',
                        }}
                      >
                        Contact us
                      </button>
                    </div>
                  </div>
                </div>
              ))}

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                  cursor: 'pointer',
                }}
                onClick={handleCreateNewLoan}
              >
                <img
                  style={{
                    maxWidth: '100%',
                    maxHeight: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    objectFit: 'contain',
                  }}
                  src={PlusIcon}
                  alt="Add New Period"
                />
                <div
                  style={{
                    color: '#121212',
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '140%',
                  }}
                >
                  Submit new application
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

LoanApplication.defaultProps = {
  applications: [],
};
export default LoanApplication;
