import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { CloseButton } from 'react-bootstrap';
import ChartIcon from '../../../../assests/img/ChartIcon.png';
import Avatar from 'react-avatar';
import ClockIcon from '../../../../assests/img/ClockIcon.png';
import EditContributionModal from './editContributionModal';
import EditInfoModal from './editInfoModal';
import ConfirmationModal from './confirmationModal';
import NextModal from './nextModal';

const EmployeeDetailModal = ({
  isOpen,
  onClose,
  employee,
  segment,
  onUpdateContributions,
  onUpdateEmployee,
}) => {
  const [localCompanyContribution, setLocalCompanyContribution] = useState(
    employee?.companyContribution || 0
  );
  const [localEmployeeContribution, setLocalEmployeeContribution] = useState(
    employee?.employeeContribution || 0
  );
  const [companyContribution, setCompanyContribution] = useState(
    segment.companyContribution
  );
  const [isContributionModalOpen, setIsContributionModalOpen] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isNextModalOpen, setIsNextModalOpen] = useState(false);
  const [employeeData, setEmployeeData] = useState([]);

  useEffect(() => {
    if (employee) {
      setLocalCompanyContribution(employee.companyContribution);
      setLocalEmployeeContribution(employee.employeeContribution);
    }
  }, [employee]);

  if (!employee) return null;

  const handleInfoSave = (
    updatedFullName,
    updatedPhoneNo,
    updatedEmail,
    updatedDepartment,
    updatedAge,
    updatedSegment
  ) => {
    const updatedEmployeeData = {
      fullName: updatedFullName,
      phoneNo: updatedPhoneNo,
      email: updatedEmail,
      department: updatedDepartment,
      age: updatedAge,
      segment: updatedSegment,
    };
    onUpdateEmployee(employee._id, updatedEmployeeData);
    employee.fullName = updatedFullName;
    employee.phoneNo = updatedPhoneNo;
    employee.email = updatedEmail;
    employee.department = updatedDepartment;
    employee.age = updatedAge;
    employee.segment = updatedSegment;
    setIsInfoModalOpen(false);
  };

  const handleOffboardConfirm = () => {
    setIsConfirmationModalOpen(false);
    setIsNextModalOpen(true);
  };

  const handleContributionSave = (
    newCompanyContribution,
    newEmployeeContribution
  ) => {
    setLocalCompanyContribution(newCompanyContribution);
    setLocalEmployeeContribution(newEmployeeContribution);
    onUpdateContributions(
      employee._id,
      newCompanyContribution,
      newEmployeeContribution
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Employee Details"
      style={{
        content: {
          position: 'fixed',
          top: '20px',
          left: '57.5vw',
          right: '0px',
          bottom: '0px',
          width: '41.8vw',
          height: '95vh',
          background: '#FAFAFA',
          padding: '0px',
          display: 'flex',
          padding: '17.054px 10.659px 10.659px 10.659px',
          alignItems: 'flex-start',
          gap: '10.659px',
          borderRadius: '12/791px',
          border: '1.066px solid var(--Border-1, #EAEDF2)',
          overflowY: 'scroll',
          overflowX: 'hidden',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '12.8px',
          width: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '3.3vh',
            padding: '0px 17.054px',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexDirection: 'row',
            gap: '95.9px',
            alignSelf: 'stretch',
          }}
        >
          <span
            style={{
              padding: '0',
              color: '#121212',
              fontFamily: 'Lato',
              fontSize: '24px',
              fontWeight: '700',
              lineHeight: '140%',
            }}
          >
            Employee details
          </span>
          <CloseButton
            style={{
              justifyContent: 'flex-end',
              margin: '0',
            }}
            onClick={onClose}
          />
        </div>
        <div
          style={{
            display: 'flex',
            padding: '25.5px',
            alignItems: 'flex-start',
            gap: '17px',
            alignSelf: 'stretch',
            borderRadius: '10.6px',
            border: '1.066px solid var(--Border-1, #EAEDF2)',
            background: 'var(--Background-Primary, #FFF)',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '16px',
              alignSelf: 'stretch',
            }}
          >
            <Avatar
              name={employee.fullName}
              size="50"
              round={true}
              style={{
                height: '100%',
                borderRadius: '50%',
              }}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <span
                style={{
                  color: '#0C0C0C',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '24px',
                }}
              >
                {employee.fullName}
              </span>
              {/* div for date*/}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '4px',
                  alignItems: 'center',
                }}
              >
                <img src={ClockIcon} />
                <span
                  style={{
                    color: '#757575',
                    fontFamily: 'Lato',
                    fontSize: '12.721px',
                    fontStyle: 'normal',
                    fontWeight: 400,
                    lineHeight: '140%',
                  }}
                >
                  Employee Since{' '}
                  {new Date(employee.createdAt).toLocaleDateString('en-GB', {
                    year: 'numeric',
                    month: 'short',
                  })}{' '}
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              height: 'var(--Letter-spacing-Letter-spacing, 1.066px)',
              background: 'var(--Border-1, #EAEDF2)',
              width: '100%',
            }}
          ></div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              alignSelf: 'stretch',
              gap: '13px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  width: '14vw',
                  color: '#757575',
                  fontFamily: 'Lato',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '140%',
                }}
              >
                Full Name
              </span>
              <span
                style={{
                  display: 'flex',
                  width: '14vw',
                  color: '#121212',
                  fontFamily: 'Lato',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '140%',
                }}
              >
                {employee.fullName}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  width: '14vw',
                  color: '#757575',
                  fontFamily: 'Lato',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '140%',
                }}
              >
                Phone Number
              </span>
              <span
                style={{
                  display: 'flex',
                  width: '14vw',
                  color: '#121212',
                  fontFamily: 'Lato',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '140%',
                }}
              >
                {employee.phoneNo}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  width: '14vw',
                  color: '#757575',
                  fontFamily: 'Lato',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '140%',
                }}
              >
                Email
              </span>
              <span
                style={{
                  display: 'flex',
                  width: '14vw',
                  color: '#121212',
                  fontFamily: 'Lato',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '140%',
                }}
              >
                {employee.email}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  width: '14vw',
                  color: '#757575',
                  fontFamily: 'Lato',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '140%',
                }}
              >
                Age
              </span>
              <span
                style={{
                  display: 'flex',
                  width: '14vw',
                  color: '#121212',
                  fontFamily: 'Lato',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '140%',
                }}
              >
                {employee.age}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  width: '14vw',
                  color: '#757575',
                  fontFamily: 'Lato',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '140%',
                }}
              >
                National ID
              </span>
              <span
                style={{
                  display: 'flex',
                  width: '14vw',
                  color: '#121212',
                  fontFamily: 'Lato',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '140%',
                }}
              >
                {employee.id}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  width: '14vw',
                  color: '#757575',
                  fontFamily: 'Lato',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '140%',
                }}
              >
                Department
              </span>
              <span
                style={{
                  display: 'flex',
                  width: '14vw',
                  color: '#121212',
                  fontFamily: 'Lato',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '140%',
                }}
              >
                {employee.department}
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  width: '14vw',
                  color: '#757575',
                  fontFamily: 'Lato',
                  fontSize: '12px',
                  fontWeight: 400,
                  lineHeight: '140%',
                }}
              >
                Segment
              </span>
              <span
                style={{
                  display: 'flex',
                  width: '14vw',
                  color: '#121212',
                  fontFamily: 'Lato',
                  fontSize: '14px',
                  fontWeight: 400,
                  lineHeight: '140%',
                }}
              >
                {segment.segmentName}
              </span>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              height: 'var(--Letter-spacing-Letter-spacing, 1.066px)',
              background: 'var(--Border-1, #EAEDF2)',
              width: '100%',
            }}
          ></div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: '4px',
            }}
          >
            <span
              style={{
                color: '#3F3F46',
                fontFamily: 'Lato',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '155%',
              }}
            >
              Total Pension Balance
            </span>
            <span
              style={{
                color: '#09090B',
                fontFamily: 'Lato',
                fontSize: '28px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '140%',
              }}
            >
              EGP0
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <span
              style={{
                color: '#171717',
                fontFamily: 'Lato',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '140%',
              }}
            >
              Monthly Contribution
            </span>
            <span
              style={{
                color: '#1F2023',
                fontFamily: 'Lato',
                justifyContent: 'flex-end',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '140%',
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => setIsContributionModalOpen(true)}
            >
              Edit Contribution
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <span
              style={{
                display: 'flex',
                width: '14vw',
                color: '#757575',
                fontFamily: 'Lato',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '140%',
              }}
            >
              By the company
            </span>
            <span
              style={{
                display: 'flex',
                width: '14vw',
                color: '#121212',
                fontFamily: 'Lato',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '140%',
              }}
            >
              EGP{Number(localCompanyContribution).toLocaleString()}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              width: '100%',
            }}
          >
            <span
              style={{
                display: 'flex',
                width: '14vw',
                color: '#757575',
                fontFamily: 'Lato',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '140%',
              }}
            >
              By the employee
            </span>
            <span
              style={{
                display: 'flex',
                width: '14vw',
                color: '#121212',
                fontFamily: 'Lato',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '140%',
              }}
            >
              EGP{Number(localEmployeeContribution).toLocaleString()}
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
              width: '100%',
              paddingBottom: '8px',
            }}
          >
            <span
              style={{
                color: '#171717',
                fontFamily: 'Lato',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                lineHeight: '140%',
              }}
            >
              Vesting
            </span>
            <span
              style={{
                color: '#1F2023',
                fontFamily: 'Lato',
                justifyContent: 'flex-end',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '140%',
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }}
            >
              Edit
            </span>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '24px',
              alignItems: 'center',
              width: '100%',
              paddingBottom: '80px',
            }}
          >
            <img src={ChartIcon} />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'center',
                gap: '8px',
                alignSelf: 'stretch',
              }}
            >
              <span
                style={{
                  display: 'flex',
                  color: '#121212',
                  fontFamily: 'Lato',
                  fontSize: '12.7px',
                  fontWeight: 400,
                }}
              >
                Employee vested balance
              </span>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'colummn',
                  gap: '4px',
                  alignItems: 'baseline',
                }}
              >
                <span
                  style={{
                    display: 'flex',
                    color: '#121212',
                    fontFamily: 'Lato',
                    fontSize: '18px',
                    fontWeight: 600,
                  }}
                >
                  EGP0
                </span>{' '}
                <span
                  style={{
                    display: 'flex',
                    color: '#757575',
                    fontFamily: 'Lato',
                    fontSize: '12px',
                    fontWeight: 400,
                  }}
                >
                  Out of 0
                </span>
              </div>
            </div>
          </div>
          <div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '54vw',
                gap: '8px',
              }}
            >
              <button
                type="submit"
                style={{
                  display: 'flex',
                  width: '18vw',
                  padding: '8px 16px',
                  justifySelf: 'center',
                  alignSelf: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '12px',
                  background: 'var(--Buttons-Primary, #F7F7F7)',
                  border: '1px solid var(--Buttons-Primary, #F7F7F7)',
                }}
                onClick={() => setIsInfoModalOpen(true)}
              >
                <span
                  style={{
                    color: 'var(--Text-Invert, #1E1E20)',
                    textAlign: 'center',
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '140%',
                  }}
                >
                  Edit Info
                </span>
              </button>
              <button
                type="submit"
                style={{
                  display: 'flex',
                  width: '18vw',
                  padding: '8px 16px',
                  justifySelf: 'center',
                  alignSelf: 'center',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '12px',
                  background: 'var(--Buttons-Primary, #1E1E20)',
                  border: 'none',
                }}
                onClick={() => setIsConfirmationModalOpen(true)}
              >
                <span
                  style={{
                    color: 'var(--Text-Invert, #FFF)',
                    textAlign: 'center',
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '140%',
                  }}
                >
                  Offboard Employee
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <EditContributionModal
        isOpen={isContributionModalOpen}
        onClose={() => setIsContributionModalOpen(false)}
        companyContribution={localCompanyContribution}
        employeeContribution={localEmployeeContribution}
        onSave={handleContributionSave}
      />
      <EditInfoModal
        isOpen={isInfoModalOpen}
        onClose={() => setIsInfoModalOpen(false)}
        fullName={employee.fullName}
        phoneNo={employee.phoneNo}
        email={employee.email}
        nationalID={employee.id}
        department={employee.department}
        age={employee.age}
        segment={segment.segmentName}
        id={employee._id}
        onSave={handleInfoSave}
      />
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={() => setIsConfirmationModalOpen(false)}
        onConfirm={handleOffboardConfirm}
      />
      <NextModal
        isOpen={isNextModalOpen}
        onClose={() => setIsNextModalOpen(false)}
        employee={employee}
      />
    </Modal>
  );
};

export default EmployeeDetailModal;
