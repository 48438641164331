import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Chart } from 'chart.js';
import { useNavigate } from 'react-router-dom';
import '../styles/customButton.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import iconImage from '../assests/img/Bosch.png';
import iconHome from '../assests/img/IconHome.png';
import TreasuryIcon from '../assests/img/TreasuryIcon.png';
import ActionRequestsIcon from '../assests/img/ActionRequestsIcon.png';
import LoansIcon from '../assests/img/LoansIcon.png';
import RetirementIcon from '../assests/img/RetirementIcon.png';
import SettingsIcon from '../assests/img/SettingsIcon.png';
import LogoutIcon from '../assests/img/LogoutIcon.png';
import SubmitIcon from '../assests/img/SubmitIcon.png';
import NotificationsIcon from '../assests/img/NotificationsIcon.png';
import LuggageIcon from '../assests/img/LuggageIcon.png';
import PlusIcon from '../assests/img/PlusIcon.png';
import LoadingIcon from '../assests/img/LoadingIcon.png';

const TreasuryRequest = () => {
  const [companyDetails, setCompanyDetails] = useState(null);
  const navigate = useNavigate();
  const [Calendar, setCalendar] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    axios
      .get('/api/companyDetails')
      .then((response) => {
        setCompanyDetails(response.data);
      })
      .catch((error) => {
        console.error('Error fetching company details:', error);
      });
  }, []);

  const handleRequest = async () => {
    try {
      navigate('/dashboard/treasury/request');
    } catch (error) {
      console.error(error);
      setMessage(`An error occurred while requesting. ${error}`);
    }
  };

  const handleTreasury = async () => {
    try {
      navigate('/dashboard/treasury');
      setMessage('Company details added sucessfully');
    } catch (error) {
      console.error(error);
      setMessage(
        `An error occurred while submitting company details. ${error}`
      );
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
      className="dashboard"
    >
      <div
        style={{
          height: '990px',
          width: '232px',
          padding: '16px',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px',
          flexShrink: '0',
          alignItems: 'center',
          borderRight: '1px solid #E8E8E8',
        }}
        className="overview"
      >
        <div
          style={{
            display: 'flex',
            paddingBottom: '12px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '4px',
            alignSelf: 'stretch',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '8px',
              alignItems: 'center',
            }}
          >
            <div>
              <img
                style={{
                  display: 'flex',
                  width: '40px',
                  height: '40px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                src={iconImage}
                alt="Verification"
                className="img-fluid"
              />
            </div>
            <h1
              style={{
                fontFamily: 'Lato',
                fontSize: '28px',
                fontWeight: '600',
                alignItems: 'center',
                height: '39px',
                width: '76px',
                margin: '0px',
                textAlign: 'center',
              }}
            >
              Bosch
            </h1>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            paddingBottom: '12px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
          }}
        >
          <h1
            style={{
              color: '#757575',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '140%',
              margin: '0',
              alignSelf: 'flex-start',
              textAlign: 'left',
            }}
          >
            Home
          </h1>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '12px',
            }}
          >
            <button
              style={{
                display: 'flex',
                padding: '12px',
                alignItems: 'center',
                gap: '12px',
                width: '200px',
                height: '44px',
                fontSize: '14px',
                borderRadius: '16px',
                background: '#FFFFFF',
                borderColor: '#FFFFFF',
                border: 'none',
              }}
              type="button"
            >
              <img
                style={{
                  display: 'flex',
                  width: '20px',
                  height: '20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                src={iconHome}
                alt="Verification"
                className="img-fluid"
              />
              Overview
            </button>
          </div>

          <div
            className="accordion"
            id="accordionExample"
            style={{ width: '100%' }}
          >
            {/* Treasury Accordion */}
            <div className="accordion-item" style={{ border: 'none' }}>
              <h2 className="accordion-header" id="headingTreasury">
                <button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px',
                    gap: '12px',
                    width: '200px',
                    height: '44px',
                    borderRadius: '16px',
                    background: '#FDFAFF',
                    borderColor: '#FDFAFF',
                    border: 'none',
                    fontSize: '14px',
                  }}
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTreasury"
                  aria-expanded="true"
                  aria-controls="collapseTreasury"
                  onClick={handleTreasury}
                >
                  {/* Image */}
                  <img
                    src={TreasuryIcon} // Replace with the image path
                    alt="Treasury Icon"
                    style={{
                      width: '20px',
                      height: '20px',
                    }}
                  />
                  {/* Text */}
                  <span
                    style={{
                      width: '112px',
                      height: '20px',
                      textAlign: 'left',
                      flexGrow: 1,
                    }}
                  >
                    Treasury
                  </span>
                  {/* Arrow */}
                  <span
                    style={{
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    <i className="bi bi-chevron-down"></i>{' '}
                    {/* Bootstrap icon for down arrow */}
                  </span>
                </button>
              </h2>
              <div
                id="collapseTreasury"
                className="accordion-collapse collapse show"
                aria-labelledby="headingTreasury"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body" style={{ paddingLeft: '32px' }}>
                  <button
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'row',
                      gap: '4px',
                      width: '100%',
                      padding: '8px',
                      flexWrap: 'wrap',
                      border: 'none',
                      background: 'none',
                      textAlign: 'left',
                    }}
                    type="button"
                  >
                    Transactions
                  </button>
                  <button
                    style={{
                      width: '100%',
                      padding: '8px',
                      border: 'none',
                      background: 'none',
                      textAlign: 'left',
                    }}
                    type="button"
                  >
                    Requests
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="accordion"
          id="accordionExample"
          style={{
            width: '100%',
            paddingBottom: '12px',
          }}
        >
          {/* Treasury Accordion */}
          <div className="accordion-item" style={{ border: 'none' }}>
            <h2 className="accordion-header" id="headingRetirement">
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '12px',
                  gap: '12px',
                  width: '200px',
                  height: '44px',
                  borderRadius: '16px',
                  background: '#FFFFFF',
                  borderColor: '#FFFFFF',
                  fontSize: '14px',
                }}
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseRetirement"
                aria-expanded="false"
                aria-controls="collapseRetirement"
              >
                {/* Image */}
                <img
                  src={RetirementIcon} // Replace with the image path
                  alt="Retirement Icon"
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                />
                {/* Text */}
                <span
                  style={{
                    width: '112px',
                    height: '20px',
                    textAlign: 'left',
                    flexGrow: 1,
                  }}
                >
                  Retirement
                </span>
                {/* Arrow */}
                <span
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                >
                  <i className="bi bi-chevron-down"></i>{' '}
                  {/* Bootstrap icon for down arrow */}
                </span>
              </button>
            </h2>
            <div
              id="collapseRetirement"
              className="accordion-collapse collapse"
              aria-labelledby="headingRetirement"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body" style={{ paddingLeft: '32px' }}>
                <button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '4px',
                    width: '100%',
                    padding: '8px',
                    flexWrap: 'wrap',
                    border: 'none',
                    background: 'none',
                    textAlign: 'left',
                  }}
                  type="button"
                >
                  Plans
                </button>
                <button
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: 'none',
                    background: 'none',
                    textAlign: 'left',
                  }}
                  type="button"
                >
                  Segments
                </button>
                <button
                  style={{
                    width: '100%',
                    padding: '8px',
                    border: 'none',
                    background: 'none',
                    textAlign: 'left',
                  }}
                  type="button"
                >
                  Vesting plans
                </button>
              </div>
            </div>
          </div>
          <div className="accordion-item" style={{ border: 'none' }}>
            <h2 className="accordion-header" id="headingLoan">
              <button
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '12px',
                  gap: '12px',
                  width: '200px',
                  height: '44px',
                  borderRadius: '16px',
                  background: '#FFFFFF',
                  borderColor: '#FFFFFF',
                  fontSize: '14px',
                }}
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseLoan"
                aria-expanded="false"
                aria-controls="collapseLoan"
              >
                {/* Image */}
                <img
                  src={LoansIcon} // Replace with the image path
                  alt="Loan Icon"
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                />
                {/* Text */}
                <span
                  style={{
                    width: '112px',
                    height: '20px',
                    textAlign: 'left',
                    flexGrow: 1,
                  }}
                >
                  Loan
                </span>
                {/* Arrow */}
                <span
                  style={{
                    width: '20px',
                    height: '20px',
                  }}
                >
                  <i className="bi bi-chevron-down"></i>{' '}
                  {/* Bootstrap icon for down arrow */}
                </span>
              </button>
            </h2>
            <div
              id="collapseLoan"
              className="accordion-collapse collapse"
              aria-labelledby="headingLoan"
              data-bs-parent="#accordionExample"
            >
              <div className="accordion-body" style={{ paddingLeft: '32px' }}>
                <button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    gap: '4px',
                    width: '100%',
                    padding: '8px',
                    flexWrap: 'wrap',
                    border: 'none',
                    background: 'none',
                    textAlign: 'left',
                  }}
                  type="button"
                >
                  Applications
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            paddingBottom: '12px',
            flexDirection: 'column',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
          }}
        >
          <h1
            style={{
              color: '#757575',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '140%',
              margin: '0',
              alignSelf: 'flex-start',
              textAlign: 'left',
            }}
          >
            More
          </h1>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '12px',
            }}
          >
            <button
              style={{
                display: 'flex',
                padding: '12px',
                alignItems: 'center',
                gap: '12px',
                width: '200px',
                height: '44px',
                fontSize: '14px',
                borderRadius: '16px',
                background: '#FDFAFF',
                borderColor: '#FDFAFF',
                border: 'none',
              }}
              type="button"
            >
              <img
                style={{
                  display: 'flex',
                  width: '20px',
                  height: '20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                src={ActionRequestsIcon}
                alt="ActionRequests"
                className="img-fluid"
              />
              Action Requests
            </button>
          </div>
          <div
            className="accordion"
            id="accordionExample"
            style={{ width: '100%' }}
          >
            {/* Treasury Accordion */}
            <div className="accordion-item" style={{ border: 'none' }}>
              <h2 className="accordion-header" id="headingSettings">
                <button
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '12px',
                    gap: '12px',
                    width: '200px',
                    height: '44px',
                    borderRadius: '16px',
                    background: '#FFFFFF',
                    borderColor: '#FFFFFF',
                    fontSize: '14px',
                  }}
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseSettings"
                  aria-expanded="false"
                  aria-controls="collapseSettings"
                >
                  {/* Image */}
                  <img
                    src={SettingsIcon} // Replace with the image path
                    alt="Settings Icon"
                    style={{
                      width: '20px',
                      height: '20px',
                    }}
                  />
                  {/* Text */}
                  <span
                    style={{
                      width: '112px',
                      height: '20px',
                      textAlign: 'left',
                      flexGrow: 1,
                    }}
                  >
                    Settings
                  </span>
                  {/* Arrow */}
                  <span
                    style={{
                      width: '20px',
                      height: '20px',
                    }}
                  >
                    <i className="bi bi-chevron-down"></i>{' '}
                    {/* Bootstrap icon for down arrow */}
                  </span>
                </button>
              </h2>
              <div
                id="collapseSettings"
                className="accordion-collapse collapse"
                aria-labelledby="headingSettings"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body" style={{ paddingLeft: '32px' }}>
                  <button
                    style={{
                      width: '100%',
                      padding: '8px',
                      border: 'none',
                      background: 'none',
                      textAlign: 'left',
                    }}
                    type="button"
                  >
                    Add Account
                  </button>
                </div>
              </div>
            </div>
          </div>
          <button
            style={{
              display: 'flex',
              padding: '12px',
              alignItems: 'center',
              gap: '12px',
              width: '200px',
              height: '44px',
              fontSize: '14px',
              borderRadius: '16px',
              background: '#FFFFFF',
              borderColor: '#FDFAFF',
              color: '#BB2124',
              border: 'none',
            }}
            type="button"
          >
            <img
              style={{
                display: 'flex',
                width: '20px',
                height: '20px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              src={LogoutIcon}
              alt="LogOut"
              className="img-fluid"
            />
            Logout
          </button>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: '1208px',
            height: '80px',
            display: 'flex',
            gap: '12px',
            padding: 'var(--Line-height-12-18, 18px) var(--6, 32px)',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderBottom: '1px solid #E8E8E8',
          }}
        >
          <button
            style={{
              display: 'flex',
              height: '44px',
              width: '171px',
              padding: '8px var(--Layout-x4, 16px)',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 'var(--Layout-x2, 8px)',
              borderRadius: '12px',
              background: '#F5F5F5',
              border: 'none',
              flexDirection: 'row',
              marginRight: '8px',
            }}
          >
            <img
              style={{
                display: 'flex',
                width: '20px',
                height: '20px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              src={SubmitIcon}
              alt="Submit Request"
              className="img-fluid"
            />
            <h1
              style={{
                color: '#121212',
                textAlign: 'center',
                fontFamily: 'Lato',
                fontSize: '16px',
                fontWeight: '400',
                margin: '0',
              }}
            >
              Submit Request
            </h1>
          </button>
          <button
            style={{
              display: 'flex',
              height: '44px',
              width: '44px',
              padding: '8px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              borderRadius: '8px',
              background: '#F5F5F5',
              border: 'none',
            }}
          >
            <img
              style={{
                display: 'flex',
                // width: '20px',
                // height: '20px',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4px',
                gap: '4px',
              }}
              src={NotificationsIcon}
              alt="Submit Request"
              className="img-fluid"
            />
          </button>
        </div>
        <div
          style={{
            display: 'flex',
            width: '1208px',
            height: '910px',
            padding: '24px',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
            gap: '17px 16px',
            flexDirection: 'column',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '1160px',
              height: '301px',
              flexDirection: 'column',
              padding: '16px',
              alignItems: 'flex-start',
              flexShrink: '0',
              gap: '16px',
            }}
          >
            <div>
              <h1
                style={{
                  width: '105px',
                  height: '34px',
                  fontFamily: 'Lato',
                  fontSize: '24px',
                  margin: '0',
                  fontWeight: '700',
                }}
              >
                Treasury
              </h1>
            </div>
            <div
              style={{
                display: 'flex',
                width: '1160px',
                height: '234px',
                padding: '16px',
                flexDirection: 'column',
                alignItems: 'flex-start',
                gap: '16px',
                flexShrink: '0',
                borderRadius: '12px',
                border: '1px solid var(--Border-1, #EAEDF2)',
              }}
            >
              <h1
                style={{
                  flex: '1 0 0',
                  color: 'var(--Grayscale-900, #171717)',
                  fontFamily: 'Lato',
                  fontSize: '20px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '140%',
                }}
              >
                Quick actions
              </h1>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '16px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '365px',
                    height: '158px',
                    padding: '16px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '16px',
                    borderRadius: '12px',
                    border: '1px #DCD8FB',
                    background: '#F3F2FB',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    <h1
                      style={{
                        color: 'var(--Greyscale-900, #09090B)',
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '150%',
                        margin: '0',
                      }}
                    >
                      Request to purchase or liquidate
                    </h1>
                    <h1
                      style={{
                        color: '#353535',
                        fontFamily: 'Lato',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '140%',
                        margin: '0',
                      }}
                    >
                      Submit a request for liquidity or asset purchases, and our
                      asset management team will handle it.
                    </h1>
                  </div>
                  <button
                    style={{
                      display: 'flex',
                      width: '173px',
                      height: '32px',
                      padding: '8px var(--Layout-x4, 16px)',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 'var(--Layout-x2, 8px)',
                      borderRadius: 'var(--Radius-Large, 12px)',
                      background: 'var(--Buttons-Primary, #1E1E20)',
                    }}
                    onClick={handleRequest}
                  >
                    <h1
                      style={{
                        color: '#FFF',
                        fontFamily: 'Lato',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '140%',
                        margin: '0',
                      }}
                    >
                      Request
                    </h1>
                  </button>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '365px',
                    height: '158px',
                    padding: '16px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '16px',
                    borderRadius: '12px',
                    border: '1px #DCD8FB',
                    background: '#F3F2FB',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    <h1
                      style={{
                        color: 'var(--Greyscale-900, #09090B)',
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '150%',
                        margin: '0',
                      }}
                    >
                      Confirm deposits
                    </h1>
                    <h1
                      style={{
                        color: '#353535',
                        fontFamily: 'Lato',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '140%',
                        margin: '0',
                      }}
                    >
                      Attach a screenshot of your account deposit to expedite
                      your treasury investments.
                    </h1>
                  </div>
                  <button
                    style={{
                      display: 'flex',
                      width: '173px',
                      height: '32px',
                      padding: '8px var(--Layout-x4, 16px)',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 'var(--Layout-x2, 8px)',
                      borderRadius: 'var(--Radius-Large, 12px)',
                      background: 'var(--Buttons-Primary, #1E1E20)',
                    }}
                  >
                    <h1
                      style={{
                        color: '#FFF',
                        fontFamily: 'Lato',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '140%',
                        margin: '0',
                      }}
                    >
                      Confirm Deposits
                    </h1>
                  </button>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '365px',
                    height: '158px',
                    padding: '16px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '16px',
                    borderRadius: '12px',
                    border: '1px #CADEE3',
                    background: '#E2EDF0',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '8px',
                    }}
                  >
                    <h1
                      style={{
                        color: 'var(--Greyscale-900, #09090B)',
                        fontFamily: 'Inter',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '150%',
                        margin: '0',
                      }}
                    >
                      Set custom portfolio
                    </h1>
                    <h1
                      style={{
                        color: '#353535',
                        fontFamily: 'Lato',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '140%',
                        margin: '0',
                      }}
                    >
                      Our team currently allocates your portfolio, feel free to
                      adjust to custom plan if you want to
                    </h1>
                  </div>
                  <button
                    style={{
                      display: 'flex',
                      width: '173px',
                      height: '32px',
                      padding: '8px var(--Layout-x4, 16px)',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 'var(--Layout-x2, 8px)',
                      borderRadius: 'var(--Radius-Large, 12px)',
                      background: 'var(--Buttons-Primary, #1E1E20)',
                    }}
                  >
                    <h1
                      style={{
                        color: '#FFF',
                        fontFamily: 'Lato',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '140%',
                        margin: '0',
                      }}
                    >
                      Set Custom Portfolio
                    </h1>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '16px',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '774px',
                height: '448px',
                padding: '24px',
                flexDirection: 'column',
                // justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '14px',
                flexShrink: '0',
                borderRadius: '12px',
                border:
                  'var(--Letter-spacing-Letter-spacing, 1px) solid var(--Border-1, #EAEDF2)',
                background: 'var(--Background-Primary, #FFF)',
              }}
            >
              <div
                style={{
                  width: '726px',
                  height: '67px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  alignSelf: 'stretch',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '4px',
                  }}
                >
                  <h1
                    style={{
                      color: 'var(--Greyscale-600, #3F3F46)',
                      fontFamily: 'Lato',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '155%',
                      margin: '0',
                    }}
                  >
                    Total Treasury balance
                  </h1>

                  <h1
                    style={{
                      width: '275px',
                      color: 'var(--Greyscale-900, #09090B)',
                      fontFamily: 'Lato',
                      fontSize: '28px',
                      fontWeight: '600',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    EGP140,493.00
                  </h1>
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    gap: '12px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '12px',
                    }}
                  >
                    <select
                      style={{
                        marginRight: '12px',
                        backgroundColor: '#f2f2f2',
                        justifyContent: 'center',
                        padding: 'var(--4, 4px) var(--8, 8px)',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        height: '36px',
                        width: '104px',
                      }}
                      type="Currency"
                      className="form-select"
                      id="exampleGovernment"
                      placeholder="Currency"
                      value={Calendar}
                      onChange={(e) => setCalendar(e.target.value)}
                    >
                      <option value="">Currency</option>
                      <option value="Cairo">Cairo</option>
                      <option value="Giza">Giza</option>
                      <option value="Alexandria">Alexandria</option>
                    </select>
                    <select
                      style={{
                        marginRight: '12px',
                        backgroundColor: '#f2f2f2',
                        justifyContent: 'center',
                        padding: 'var(--4, 4px) var(--8, 8px)',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        height: '36px',
                        width: '104px',
                      }}
                      type="Currency"
                      className="form-select"
                      id="exampleGovernment"
                      placeholder="Currency"
                      value={Calendar}
                      onChange={(e) => setCalendar(e.target.value)}
                    >
                      <option value="">Today</option>
                      <option value="Cairo">Cairo</option>
                      <option value="Giza">Giza</option>
                      <option value="Alexandria">Alexandria</option>
                    </select>
                  </div>
                  <h1
                    style={{
                      width: '126px',
                      color: 'var(--Greyscale-600, #3F3F46)',
                      fontFamily: 'Lato',
                      fontSize: '12px',
                      fontWeight: '400',
                      lineHeight: '155%',
                      margin: '0',
                    }}
                  >
                    Total return $30,000.00
                  </h1>
                </div>
              </div>

              <div
                style={{
                  width: '726px',
                  height: 'var(--Letter-spacing-Letter-spacing, 1px)',
                  border: '1px #EAEDF2 solid',
                }}
              ></div>

              <div
                style={{
                  display: 'flex',
                  width: '684px',
                  flexDirection: 'row',
                  gap: '16px',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    width: '171px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 'var(--spacing-0, 0px)',
                  }}
                >
                  <h1
                    style={{
                      width: '171px',
                      color: 'var(--Greyscale-600, #323741)',
                      fontFamily: 'Lato',
                      fontSize: '12px',
                      fontWeight: '400',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    Fixed income & Money market
                  </h1>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '6px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <h1
                      style={{
                        width: '73px',
                        color: 'var(--UI-Text-Primary, #0F0F10)',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontWeight: '500',
                        lineHeight: '150%',
                        margin: '0',
                      }}
                    >
                      10,000.00
                    </h1>
                    <h1
                      style={{
                        width: '24px',
                        height: '16px',
                        color: 'var(--accent, #572875',
                        fontFamily: 'Inter',
                        fontSize: '11px',
                        fontWeight: '500',
                        lineHeight: '12px',
                        letterSpacing: '0.22px',
                        textTransform: 'uppercase',
                        margin: '0',
                      }}
                    >
                      33%
                    </h1>
                  </div>
                  <h1
                    style={{
                      width: '171px',
                      color: 'var(--Text-Sub, #757575)',
                      fontFamily: 'Inter',
                      fontSize: '9px',
                      fontWeight: '400',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    Total returns : EGP7,997.86
                  </h1>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '171px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 'var(--spacing-0, 0px)',
                  }}
                >
                  <h1
                    style={{
                      width: '171px',
                      color: 'var(--Greyscale-600, #323741)',
                      fontFamily: 'Lato',
                      fontSize: '12px',
                      fontWeight: '400',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    Funds
                  </h1>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '6px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <h1
                      style={{
                        width: '73px',
                        color: 'var(--UI-Text-Primary, #0F0F10)',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontWeight: '500',
                        lineHeight: '150%',
                        margin: '0',
                      }}
                    >
                      EGP20,000
                    </h1>
                    <h1
                      style={{
                        width: '24px',
                        height: '16px',
                        color: 'var(--accent, #572875',
                        fontFamily: 'Inter',
                        fontSize: '11px',
                        fontWeight: '500',
                        lineHeight: '12px',
                        letterSpacing: '0.22px',
                        textTransform: 'uppercase',
                        margin: '0',
                      }}
                    >
                      25.2%
                    </h1>
                  </div>
                  <h1
                    style={{
                      width: '171px',
                      color: 'var(--Text-Sub, #757575)',
                      fontFamily: 'Inter',
                      fontSize: '9px',
                      fontWeight: '400',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    Total returns : EGP7,997.86
                  </h1>
                </div>

                <div
                  style={{
                    display: 'flex',
                    width: '171px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 'var(--spacing-0, 0px)',
                  }}
                >
                  <h1
                    style={{
                      width: '171px',
                      color: 'var(--Greyscale-600, #323741)',
                      fontFamily: 'Lato',
                      fontSize: '12px',
                      fontWeight: '400',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    Time Deposits (EGP)
                  </h1>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '6px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <h1
                      style={{
                        width: '73px',
                        color: 'var(--UI-Text-Primary, #0F0F10)',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontWeight: '500',
                        lineHeight: '150%',
                        margin: '0',
                      }}
                    >
                      EGP60,493
                    </h1>
                    <h1
                      style={{
                        width: '24px',
                        height: '16px',
                        color: 'var(--accent, #572875',
                        fontFamily: 'Inter',
                        fontSize: '11px',
                        fontWeight: '500',
                        lineHeight: '12px',
                        letterSpacing: '0.22px',
                        textTransform: 'uppercase',
                        margin: '0',
                      }}
                    >
                      75.76%
                    </h1>
                  </div>
                  <h1
                    style={{
                      width: '171px',
                      color: 'var(--Text-Sub, #757575)',
                      fontFamily: 'Inter',
                      fontSize: '9px',
                      fontWeight: '400',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    Total returns : EGP7,997.86
                  </h1>
                </div>
                <div
                  style={{
                    display: 'flex',
                    width: '171px',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: 'var(--spacing-0, 0px)',
                  }}
                >
                  <h1
                    style={{
                      width: '171px',
                      color: 'var(--Greyscale-600, #323741)',
                      fontFamily: 'Lato',
                      fontSize: '12px',
                      fontWeight: '400',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    Time Deposits (USD)
                  </h1>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      gap: '6px',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <h1
                      style={{
                        width: '73px',
                        color: 'var(--UI-Text-Primary, #0F0F10)',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontWeight: '500',
                        lineHeight: '150%',
                        margin: '0',
                      }}
                    >
                      $60,000
                    </h1>
                    <h1
                      style={{
                        width: '24px',
                        height: '16px',
                        color: 'var(--accent, #572875',
                        fontFamily: 'Inter',
                        fontSize: '11px',
                        fontWeight: '500',
                        lineHeight: '12px',
                        letterSpacing: '0.22px',
                        textTransform: 'uppercase',
                        margin: '0',
                      }}
                    >
                      75.76%
                    </h1>
                  </div>
                  <h1
                    style={{
                      width: '171px',
                      color: 'var(--Text-Sub, #757575)',
                      fontFamily: 'Inter',
                      fontSize: '9px',
                      fontWeight: '400',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    Total returns : EGP7,997.86
                  </h1>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  height: '219px',
                  width: '370px',
                  padding: '16px',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  flexShrink: '0',
                  alignSelf: 'stretch',
                  borderRadius: '12px',
                  border: '1px solid var(--Border-1, #EAEDF2)',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '8px',
                    alignSelf: 'stretch',
                  }}
                >
                  <h1
                    style={{
                      color: 'var(--Greyscale-900, #09090B)',
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      fontWeight: '600',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    Upcoming Maturity
                  </h1>
                  <h1
                    style={{
                      color: '#353535',
                      fontFamily: 'Lato',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    Some assets are going to liquidated soon
                  </h1>
                </div>

                <div
                  style={{
                    display: 'flex',
                    width: '338px',
                    height: '78px',
                    padding: 'var(--2, 8px)',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '4px',
                    alignSelf: 'stretch',
                    borderRadius: '8px',
                    background: '#F8FAFB',
                  }}
                  id="hoverText"
                >
                  <span
                    style={{
                      alignSelf: 'stretch',
                      color: 'var(--UI-Text-Secondary, #323741)',
                      fontFamily: 'Lato',
                      fontSize: '12px',
                      fontWeight: '400',
                      lineHeight: '140%',
                    }}
                    title="hover text"
                  >
                    Fixed income & Money market{' '}
                  </span>
                  <span
                    style={{
                      color: 'var(--UI-Text-Primary, #0F0F10)',
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      fontWeight: '500',
                      lineHeight: '120%',
                    }}
                    title="hover text"
                  >
                    EGP60,493{' '}
                  </span>
                  <span
                    style={{
                      color: 'var(--UI-Text-Primary, #757575)',
                      fontFamily: 'Inter',
                      fontSize: '9px',
                      fontWeight: '400',
                      lineHeight: '140%',
                    }}
                    title="hover text"
                  >
                    Total Returns : EGP7,997.86{' '}
                  </span>
                </div>
                <div></div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '4px',
                  }}
                >
                  <img
                    style={{
                      display: 'flex',
                      width: '20px',
                      height: '20px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginRight: '4px',
                      borderRadius: '815.894px',
                      background: 'var(--Secondary-0, #F5F3FF)',
                    }}
                    src={LuggageIcon}
                    alt="Verification"
                    className="img-fluid"
                  />
                  <span
                    style={{
                      color: 'var(--UI-Text-Primary, #0F0F10)',
                      fontFamily: 'Lato',
                      fontSize: '16px',
                      fontWeight: '600',
                      lineHeight: '140%',
                    }}
                  >
                    Next Tuesday 22 May
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreasuryRequest;
