import React from 'react';
import Modal from 'react-modal';
import CloseIcon from '../../../../assests/img/CloseIcon.png';
import IconDown from '../../../../assests/img/IconDown.png';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import InputField from '../../../loans/components/inputfield';
import { MenuItem } from '@mui/material';
import { toast } from 'react-toastify';

const departmentOptions = [
  { value: 'Human Resources (HR)', label: 'Human Resources (HR)' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Sales', label: 'Sales' },
  { value: 'Operations', label: 'Operations' },
  { value: 'IT (Information Technology)', label: 'IT (Information Technology)' },
  { value: 'Customer Service', label: 'Customer Service' },
  { value: 'Product', label: 'Product' },
  { value: 'Legal', label: 'Legal' },
  { value: 'Administration', label: 'Administration' },
  { value: 'Research and Development', label: 'Research and Development' },
  { value: 'Public Relations', label: 'Public Relations' },
  { value: 'Strategy and Planning', label: 'Strategy and Planning' },
  { value: 'Logistics and Supply Chain', label: 'Logistics and Supply Chain' },
  { value: 'Quality Assurance', label: 'Quality Assurance' },
];

const validationSchema = Yup.object({
  fullName: Yup.string()
  .matches(/^\D*$/, 'Full name should not contain numbers')
    .matches(/^[a-zA-Z\s]+$/, 'Full name should not contain special characters')
    .min(2, 'Full name must be at least 2 characters long')
    .max(50, 'Full name cannot be longer than 50 characters')
    .required('Full name is required'),
  phoneNo: Yup.string()
    .required('Phone Number is required')
    .matches(
      /^(010|011|012|015)/,
      'Phone number must start with 010, 011, 012, or 015'
    )
    .min(11, 'Phone number must be 11 digits long')
    .max(11, 'Phone number must be 11 digits long'),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Invalid email format'
    )
    .required('Email is required'),
  id: Yup.string()
    .required('National ID is required')
    .matches(
      /^\d{14}$/,
      'National ID should not contain letters or special characters')
      .length(14, 'National ID must be exactly 14 digits long'),
  department: Yup.string().required('Please select a department'),
  age: Yup.number()
    .typeError('Age must be a number')
    .min(18, 'Age must be greater than 18')
    .max(100, 'Age must be less than 100')
    .required('Age is required'),
  employeeContribution: Yup.number()
    .nullable()
    .typeError('Company contribution must be a number')
    .min(500, 'Minimum contribution must be greater than 499')
    .max(999999999, 'Company Contribution cannot exceed 9 digits'),
});

const EmployeeModal = ({ isOpen, onClose, onCreateEmployee }) => {
  if (!isOpen) return null;

  const initialValues = {
    fullName: '',
    phoneNo: '',
    email: '',
    id: '',
    department: '',
    age: '',
    employeeContribution: '',
  };

  const onSubmit = (values) => {
    const employeeData = {
      ...values,
      employeeContribution: values.employeeContribution || 0, 
      createdAt: new Date(),
    };
  
    onCreateEmployee(employeeData);  
    
  };
  

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Add New Employee"
      style={{
        content: {
          position: 'fixed',
          top: '75px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          background: 'white',
          padding: '0px',
          borderRadius: '12px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '8px 16px',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderRadius: '12px 12px 0px 0px',
            borderBottom: '1px solid var(--UI-Strock-Soft, #EAEDF2)',
            background: 'var(--Background-Primary, #FFF)',
          }}
        >
          <button
            style={{
              height: '20px',
              width: '20px',
              border: 'none',
              backgroundColor: 'transparent',
              padding: 0,
            }}
            onClick={onClose}
          >
            <img
              style={{
                display: 'flex',
                width: '20px',
                height: '20px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              src={CloseIcon}
              alt="Close"
            />
          </button>
        </div>

        <div
          style={{
            display: 'flex',
            borderRadius: '16px 16px 0px 0px',
            background: 'var(--Background-Layer, #FAFAFA)',
            width: '100vw',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '32px',
          }}
        >
          <div
            style={{
              display: 'flex',
              padding: '34px 121px 163px 121px',
              justifyContent: 'center',
              alignItems: 'flex-start',
              borderRadius: '12px',
              border: '1px solid var(--Border-1, #EAEDF2)',
              background: 'var(--Background-Primary, #FFF)',
              height: 'auto',
              width: '81vw',
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              maxHeight: '90vh',
            }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ touched, errors, isSubmitting }) => (
                <Form
                  style={{
                    width: '79vw',
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '32px',
                  }}
                >
                  <span
                    style={{
                      color: '#09090B',
                      fontFamily: 'Lato',
                      fontSize: '22px',
                      fontStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: '140%',
                    }}
                  >
                    Add New Employee
                  </span>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '80%',
                      gap: '16px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <InputField
                      label={'Full Name'}
                      type="text"
                      name={'fullName'}
                      errors={errors.fullName}
                      touched={touched.fullName}
                    />
                    <InputField
                      label={'Phone Number'}
                      type="text"
                      name={'phoneNo'}
                      errors={errors.phoneNo}
                      touched={touched.phoneNo}
                    />
                    <InputField
                      label={'Email'}
                      type="text"
                      name={'email'}
                      errors={errors.email}
                      touched={touched.email}
                    />
                    <InputField
                      label={'National ID number'}
                      type="text"
                      name={'id'}
                      errors={errors.id}
                      touched={touched.id}
                    />
                    <InputField
                      label={'Department'}
                      type="text"
                      name={'department'}
                      errors={errors.department}
                      touched={touched.department}
                      select
                    >
                      {departmentOptions.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item.value}
                          sx={{
                            fontFamily: 'Lato',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '140%',
                            color: '#121212',
                          }}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </InputField>

                    <InputField
                      label={'Age'}
                      type="text"
                      name={'age'}
                      errors={errors.age}
                      touched={touched.age}
                    />
                    <InputField
                      label={'Employee contribution (Optional)'}
                      type="text"
                      name={'employeeContribution'}
                      errors={errors.employeeContribution}
                      touched={touched.employeeContribution}
                    />
                  </div>

                  <button
                    type="submit"
                    style={{
                      display: 'flex',
                      width: '80%',
                      padding: '8px 16px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '12px',
                      background: 'var(--Buttons-Primary, #1E1E20)',
                      border:'none'
                    }}
                    disabled={isSubmitting}
                  >
                    <span
                      style={{
                        color: 'var(--Text-Invert, #FFF)',
                        textAlign: 'center',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '140%',
                      }}
                    >
                      Create
                    </span>
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmployeeModal;
