import { borderRadius, display, fontFamily, height, lineHeight, margin, padding, width } from '@mui/system';
import React from 'react';

function CustomAlertModal({ isOpen, onClose }) {
  if (!isOpen) return null; // Don't render the modal if it's not open

  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <div style={styles.header}>
          <h2 style={styles.title}>Select Employees</h2>
          <button onClick={onClose} style={styles.closeButton}>×</button>
        </div>
        <div style={{
            width: '100%',
            minHeight: '1px',
            background: '#D9D9D9',
            marginBottom: '18px',
            marginTop: '16px'
        }}>
            
        </div>
        <div style={styles.body}>
          <p style={{
            color: '#0C0C0C',
            fontFamily: 'Inter',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '24px'
          }}>Select employees to assign vesting plan to.</p>
        </div>
        <div style={styles.footer}>
          <button onClick={onClose} style={styles.button}>Select Employees</button>
        </div>
      </div>
    </div>
  );
}

// Define styles
const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  modal: {
    display: 'inline-flex',
    padding: '16px 0px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: '#fff',
    borderRadius: '16px',
    border: '1px solid var(--Grey-grey-300, #D9D9D9)',
    width: '34vw',
    // height: '30vh',
    boxShadow: '0px 4px 16px 0px rgba(12, 12, 12, 0.12)',
    padding: '16px',
    textAlign: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between', // Pushes title to the left and button to the right
    alignItems: 'center',
    width: '100%', 
  },
  title: {
    fontSize: '18px',
    fontFamily: 'Lato',
    fontWeight: '600',
    lineHeight: '27px',
    textAlign: 'left', 
    margin: '0px'
  },
  closeButton: {
    backgroundColor: 'transparent',
    border: 'none',
    alignSelf: 'center',
    fontSize: '22px',
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  body: {
    marginBottom: '18px',
  },
  avatar: {
    margin: '16px 0',
    display: 'flex',
    justifyContent: 'center',
  },
  avatarImage: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
  },
  footer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: '31vw',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000',
    color: '#fff',
    padding: '8px 16px',
    borderRadius: '4px',
    cursor: 'pointer',
    borderRadius: '12px',
    background: '#1E1E20'
  },
};

export default CustomAlertModal;
