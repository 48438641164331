import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Top from '../../treasury/top';
import SegmentModal from './modal/segmentModal';
import AlienIcon from '../../../assests/img/AlienIcon.png';
import PlusIcon from '../../../assests/img/PlusIcon2.png';
import axiosInstance from '../../../utils/axiosInstance'; // Use the configured Axios instance
import { toast } from 'react-toastify';
import tick from '../../../assests/img/tick.png';

function Segments() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [segments, setSegments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const openModal = () => setIsModalOpen(true);

  const closeModal = () => setIsModalOpen(false);

  useEffect(() => {
    axiosInstance
      .get('/segments')
      .then((response) => {
        setSegments(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('There was an error fetching segments!', error);
        setIsLoading(false);
      });
  }, []);

  const handleCreateSegment = (newSegment) => {
    axiosInstance
      .post('/segments', newSegment)
      .then((response) => {
        setSegments((prevSegments) => [...prevSegments, response.data]);
        closeModal();
        toast.success('The segment has been submitted successfully ', {
          autoClose: 3000,
          position: 'bottom-center',
          className: 'custom-toast',
          bodyClassName: 'custom-body-toast',
          icon: <div className="custom-toast-icon"><img src={tick} alt="Success" style={{ width: '30px', height: '30px' }} /></div>,
          progressClassName: 'custom-toast-progress',
          style: {
            width: '416px',
          },
        });
      })
      .catch((error) => {
        console.error('There was an error creating the segment!', error);
      });
  };

  const handleSegmentClick = (segment) => {
    const urlSafeName = segment.segmentName.replace(/\s+/g, '-').toLowerCase();
    navigate(`/dashboard/retirement/segments/${urlSafeName}`, { state: { segment } });
  };

  const formatDate = (date) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options).format(date).replace(',', '');
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
      }}
    >
      <Top />

      <div
        style={{
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          gap: segments.length === 0 ? '157px' : '16px',
          paddingTop: '24px',
          paddingLeft: '32px',
          paddingRight: '24px',
          paddingBottom: '24px',
        }}
      >
        <div
          style={{
            color: '#0F0F10',
            fontFamily: 'Lato',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '140%',
          }}
        >
          Segments
        </div>

        {isLoading ? ( // Show a loader or nothing while data is being fetched
          <div>Loading...</div>
        ) : segments.length === 0 ? ( // Show the empty state only if segments are truly empty after loading
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              alignSelf: 'center',
              gap: '44px',
              width: '50vw',
            }}
          >
            <img src={AlienIcon} alt="Alien" />
            <button
              style={{
                display: 'flex',
                padding: '8px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                alignSelf: 'stretch',
                borderRadius: '12px',
                background: '#1E1E20',
                height: '4vh',
                border:'none',
                height:'38px'
              }}
              onClick={openModal}
            >
              <span
                style={{
                  color: '#FFF',
                  textAlign: 'center',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '140%',
                  
                  
                }}
              >
                Create Segment
              </span>
            </button>
          </div>
        ) : (
          <div>
            {segments.map((segment, index) => (
              <div
                key={index}
                style={{
                  display: 'flex',
                  width: '80vw',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: '24px',
                  gap: '16px',
                  alignSelf: 'stretch',
                  border: '1px solid var(--Border-1, #EAEDF2)',
                  borderRadius: '12px',
                  marginBottom: '24px',
                  cursor: 'pointer',
                }}
                onClick={() => handleSegmentClick(segment)}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                  }}
                >
                  <span
                    style={{
                      color: '#121212',
                      fontFamily: 'Lato',
                      fontSize: '24px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '140%',
                    }}
                  >
                    {segment.segmentName}
                  </span>
                  <span
                    style={{
                      color: '#757575',
                      fontFamily: 'Lato',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '140%',
                    }}
                  >
                    Created on {formatDate(new Date())}
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: '8px',
                  }}
                >
                  <span
                    style={{
                      display: 'flex',
                      padding: '4px 8px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '4px',
                      borderRadius: '4px',
                      background: '#FDFAFF',
                      color: '#572875',
                      fontFamily: 'Lato',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '140%',
                    }}
                  >
                    {segment.employeeCount} Employees
                  </span>
                  <span
                    style={{
                      padding: '4px 8px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '4px',
                      borderRadius: '4px',
                      background: '#FDFAFF',
                      color: '#572875',
                      fontFamily: 'Lato',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '140%',
                    }}
                  >{`EGP${parseInt(segment.companyContribution).toLocaleString()} Contribution`}</span>
                </div>
              </div>
            ))}

            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                cursor: 'pointer',
              }}
              onClick={openModal}
            >
              <img
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  objectFit: 'contain',
                }}
                src={PlusIcon}
                alt="Add New Period"
              />
              <div
                style={{
                  color: '#121212',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '140%',
                }}
              >
                Add another segment
              </div>
            </div>
          </div>
        )}
      </div>

      <SegmentModal isOpen={isModalOpen} onClose={closeModal} onCreateSegment={handleCreateSegment} />
    </div>
  );
}

export default Segments;