import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import { format } from 'date-fns';
import CustomModal from '../../../../../components/modal';
import Title from '../components/title';
import FileUploader from '../../../../loans/components/fileupload';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import InputField from '../../../../loans/components/inputfield';
import CalendarIcon from '../../../../../assests/img/calendar.png';
import tick from '../../../../../assests/img/tick.png';


function SecondModal({ closeModal, isModalOpen }) {
  const [isUploaded, setIsUploaded] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState({ screenshotFile: null });

  // Yup validation schema
  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required('Please enter an amount.')
      .positive('Amount must be greater than 0.')
      .typeError('Please enter a valid number'),
    selectedDate: Yup.mixed()
      .nullable()
      .test('date', 'Please select a date.', (value) => value !== null),
    screenshot: Yup.mixed().required('Please upload a screenshot.'),
  });

  const resetForm = () => {
    setUploadedFiles({ screenshotFile: null });
    setIsUploaded(false);
    setUploadError('');
    setIsLoading(false);
  };

  const submitSecondModal = (values) => {
    resetForm();
    closeModal();
    toast.success('Deposit confirmation submitted successfully!', {
      autoClose: 3000,
      position: 'bottom-center',
      className: 'custom-toast',
      bodyClassName: 'custom-body-toast',
      icon: <div className="custom-toast-icon"><img src={tick} alt="Success" style={{ width: '30px', height: '30px' }} /></div>,
      progressClassName: 'custom-toast-progress',
      style: {
        width: '420px', 
        
      },
    });
  };

  const handleFileChange = (file, setFieldValue) => {
    if (file) {
      setIsLoading(true);
      setIsUploaded(false);
      setUploadError('');

      uploadFile(file, setFieldValue);
    }
  };

  const uploadFile = async (file, setFieldValue) => {
    try {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          const success = Math.random() > 0.01; // Simulate 99% success rate
          if (success) resolve();
          else reject(new Error('Failed to upload file.'));
        }, 2000);
      });

      setIsLoading(false);
      setIsUploaded(true);
      setUploadedFiles({ screenshotFile: file.name });
      setFieldValue('screenshot', file); // Set file value in Formik
    } catch (error) {
      setIsLoading(false);
      setUploadError(error.message);
    }
  };

  return (
    <CustomModal isModalOpen={isModalOpen} closeModal={closeModal}>
      <Formik
        initialValues={{
          amount: '',
          selectedDate: null,
          screenshot: '',
        }}
        validationSchema={validationSchema}
        onSubmit={submitSecondModal}
      >
        {({ setFieldValue, values, errors, touched }) => (
          <Form>
            <div
              style={{
                height: '24px',
                width: '100%',
                background: 'var(--Background-Layer, #FAFAFA)',
              }}
            ></div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                background: '#FAFAFA',
                width: '100%',
                justifyContent: 'center',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    gap: '55px',
                    width: '80vw',
                    height: 'calc(100vh - 300px)',
                    borderRadius: '12px',
                    margin: 'auto',
                    border: '1px solid #EAEDF2',
                    background: '#FFF',
                    overflowY: 'scroll',
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                  }}
                  className="hide-scrollbar"
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '70%',
                      height: '450px',
                    }}
                  >
                    <Title
                      title="Confirm deposits"
                      subtitle="Attach a screenshot of your transfer to accelerate your treasury investments"
                    />
                    <InputField
                      label={'Amount you deposited'}
                      type="text"
                      name={'amount'}
                      value={values.amount}
                      errors={errors.amount}
                      touched={touched.amount}
                    />

                    <div
                      style={{
                        width: '100%',
                        padding: '12px 0',
                        display: 'flex',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '8px',
                        marginBottom: '16px',
                        flexDirection: 'row',
                      }}
                    >
                      <div
                        style={{
                          height: '1px',
                          width: '100%',
                          backgroundColor: '#EAEDF2',
                        }}
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          padding: '3.264px 14px 3.264px 3.264px',
                          gap: '6.5px',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '80px',
                          border:
                            'var(--Letter-spacing-Letter-spacing, 0.816px) solid #E4E4E7',
                          background: '#FFF',
                        }}
                      >
                        <div
                          style={{
                            borderRadius: '815.894px',
                            background: '#F5F3FF',
                          }}
                        >
                          <img
                            src={CalendarIcon}
                            alt="Select Period"
                            style={{ width: '24px', height: '24px' }}
                          />
                        </div>
                        <span
                          style={{
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontWeight: '400',
                            color: '#121212',
                            whiteSpace: 'nowrap',
                            lineHeight: '24px',
                          }}
                        >
                          Select date of deposit
                        </span>
                      </div>
                      <div
                        style={{
                          height: '1px',
                          width: '100%',
                          backgroundColor: '#EAEDF2',
                        }}
                      />
                    </div>

                    <div
                      style={{
                        display: 'flex',
                        width: '301.733px',
                        padding: '13.767px',
                        flexDirection: 'column',
                        gap: '13.767px',
                        margin: 'auto',
                        marginBottom: '8px',
                      }}
                    >
                      <DatePicker
                        selected={values.selectedDate}
                        onChange={(date) => setFieldValue('selectedDate', date)}
                        inline
                        maxDate={new Date()}
                        filterDate={(date) => {
                          const day = date.getDay();
                          return date <= new Date() && day !== 5 && day !== 6;
                        }}
                      />
                      {errors.selectedDate && touched.selectedDate && (
                        <div style={{
                          textAlign: 'center',
                          color: '#BB2124', 
                          fontFamily: 'lato',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                        }}>{errors.selectedDate}</div>
                      )}
                    </div>
                    <div
                      style={{
                        paddingBottom: '24px',
                      }}
                    >
                      <FileUploader
                        uploadText="Upload a screenshot"
                        name="screenshot"
                        acceptedFormats={['image/jpeg', 'image/png']}
                        setFieldValue={setFieldValue}
                        value={values.screenshot}
                        handleFileChange={(e) =>
                          handleFileChange(e.target.files[0], setFieldValue)
                        }
                      />
                       {errors.screenshot && touched.screenshot && (
                        <div style={{
                          color: '#BB2124',
                          paddingLeft: '12px', 
                          fontFamily: 'lato',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: '400',
                        }}>{errors.screenshot}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div
                style={{
                  width: '100%',
                  height: '143px',
                  display: 'flex',
                  padding: '28px 133px',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                  border: '1px #EAEDF2',
                  background: '#FFF',
                  boxShadow: '0px -5px 47px 0px rgba(0, 0, 0, 0.03)',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '(--2, 8px)',
                  }}
                >
                  <span
                    style={{
                      color: 'var(--Text-Sub, #757575)',
                      height: '20px',
                      fontStyle: 'normal',
                      fontFamily: 'Lato',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '20px',
                    }}
                  >
                    Confirm your deposit
                  </span>

                  <span
                    style={{
                      color: '#09090B',
                      height: '31px',
                      fontStyle: 'normal',
                      fontFamily: 'Lato',
                      fontSize: '22px',
                      fontWeight: '500',
                      lineHeight: '140%',
                    }}
                  >
                    EGP {values.amount || 0}
                  </span>

                  <span
                    style={{
                      color: 'var(--Text-Sub, #757575)',
                      height: '20px',
                      fontStyle: 'normal',
                      fontFamily: 'Lato',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '20px',
                    }}
                  >
                    On{' '}
                    {values.selectedDate
                      ? format(values.selectedDate, 'dd MMMM yyyy')
                      : 'Select a date'}
                  </span>
                </div>

                <button
                  style={{
                    display: 'flex',
                    width: '340px',
                    height: '38px',
                    padding: '8px var(--Layout-x4, 16px)',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 'var(--Layout-x2, 8px)',
                    borderRadius: 'var(--Radius-Large, 12px)',
                    background: 'var(--Buttons-Primary, #1E1E20)',
                    border:'none'
                  }}
                  type="submit"
                >
                  <span
                    style={{
                      color: 'var(--Text-Invert, #FFF)',
                      textAlign: 'center',
                      height: '22px',
                      fontStyle: 'normal',
                      fontFamily: 'Lato',
                      fontSize: '16px',
                      fontWeight: '600',
                      lineHeight: '140%',
                    }}
                  >
                    Submit to confirm
                  </span>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </CustomModal>
  );
}

export default SecondModal;
