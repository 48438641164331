import React, {useState} from 'react';
import "../../../styles/customButton.css";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import SignUpLeftContainer from '../leftContainer/signUpLeftContainer';
import LoginRightContainer from './rightContainer/loginRightContainer';

function Login({ setIsAuthenticated }) {
  return (
    <div className='container'>
      <div className='row vh-100'>
         <SignUpLeftContainer />
         <LoginRightContainer setIsAuthenticated={setIsAuthenticated}/>
      </div>
    </div>
  );
}

export default Login;
