// ApplicationContext.js
import React, { createContext, useState } from 'react';

export const ApplicationContext = createContext();

export const ApplicationProvider = ({ children }) => {
  const [applications, setApplications] = useState([]);

  const addApplication = (application) => {
    setApplications((prevApplications) => [...prevApplications, application]);
  };

  return (
    <ApplicationContext.Provider value={{ applications, addApplication }}>
      {children}
    </ApplicationContext.Provider>
  );
};
