import React, { useState } from 'react';
import Select from 'react-select';

function TreasuryBalance() {
  const [Calendar, setCalendar] = useState('');

  const options = [
    { value: '', label: 'Today' },
    { value: 'Week to date', label: 'Week to date' },
    { value: 'Month to date', label: 'Month to date' },
    { value: 'Quarter to date', label: 'Quarter to date' },
    { value: 'Year to date', label: 'Year to date' },
    { value: 'Since inception', label: 'Since inception' },
  ];

  return (
    <div style={{ flexGrow: 1, width: '100%' }}>
      <div
        style={{
          display: 'flex',
          height: '448px',
          padding: '24px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '14px',
          flexShrink: '0',
          borderRadius: '12px',
          border: '1px solid #EAEDF2',
          background: '#FFF',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '67px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: '4px',
            }}
          >
            <h1
              style={{
                color: '#3F3F46',
                fontFamily: 'Lato',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '155%',
                margin: '0',
              }}
            >
              Total Treasury balance
            </h1>

            <h1
              style={{
                width: '100%',
                color: '#09090B',
                fontFamily: 'Lato',
                fontSize: '28px',
                fontWeight: '600',
                lineHeight: '140%',
                margin: '0',
              }}
            >
              EGP140,493.00
            </h1>
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '12px' }}>
            <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
              <select
                style={{
                  borderRadius: '12px',
                  border: '0.5px #F1E8F8',
                  backgroundColor: '#FFF',
                  boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.08)',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  height: '36px',
                  width: '104px',
                  marginRight: '12px',
                }}
                value={Calendar}
                onChange={(e) => setCalendar(e.target.value)}
              >
                <option value="Currency" disabled>
                  Currency
                </option>
                <option value="All">All</option>
                <option value="EGP">EGP</option>
                <option value="USD">USD</option>
              </select>
              <Select
                options={options}
                value={options.find((option) => option.value === Calendar)}
                onChange={(selectedOption) => setCalendar(selectedOption.value)}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    borderRadius: '12px',
                    border: '0.5px solid #F1E8F8',
                    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.08)',
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    height: '36px',
                  }),
                }}
              />
            </div>
            <h1
              style={{
                color: '#3F3F46',
                fontFamily: 'Lato',
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '155%',
                margin: '0',
              }}
            >
              Total return $30,000.00
            </h1>
          </div>
        </div>

        <hr style={{ backgroundColor: '#EAEDF2', height: 2, width: '100%' }} />

        {/* Section for 4 Items */}
        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            gap: '16px',
          }}
        >
          {/* First Item */}
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <h1
              style={{
                color: '#323741',
                fontFamily: 'Lato',
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '140%',
                margin: '0',
              }}
            >
              Fixed income & Money market
            </h1>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
              <h1
                style={{
                  color: '#0F0F10',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '150%',
                  margin: '0',
                }}
              >
                10,000.00
              </h1>
              <h1
                style={{
                  color: '#572875',
                  fontFamily: 'Inter',
                  fontSize: '11px',
                  fontWeight: '500',
                  letterSpacing: '0.22px',
                  textTransform: 'uppercase',
                  margin: '0',
                }}
              >
                33%
              </h1>
            </div>
            <h1
              style={{
                color: '#757575',
                fontFamily: 'Inter',
                fontSize: '9px',
                fontWeight: '400',
                lineHeight: '140%',
                margin: '0',
              }}
            >
              Total returns : EGP7,997.86
            </h1>
          </div>

          {/* Second Item */}
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <h1
              style={{
                color: '#323741',
                fontFamily: 'Lato',
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '140%',
                margin: '0',
              }}
            >
              Funds
            </h1>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
              <h1
                style={{
                  color: '#0F0F10',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '150%',
                  margin: '0',
                }}
              >
                EGP20,000
              </h1>
              <h1
                style={{
                  color: '#572875',
                  fontFamily: 'Inter',
                  fontSize: '11px',
                  fontWeight: '500',
                  letterSpacing: '0.22px',
                  textTransform: 'uppercase',
                  margin: '0',
                }}
              >
                25.2%
              </h1>
            </div>
            <h1
              style={{
                color: '#757575',
                fontFamily: 'Inter',
                fontSize: '9px',
                fontWeight: '400',
                lineHeight: '140%',
                margin: '0',
              }}
            >
              Total returns : EGP7,997.86
            </h1>
          </div>

          {/* Third Item */}
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <h1
              style={{
                color: '#323741',
                fontFamily: 'Lato',
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '140%',
                margin: '0',
              }}
            >
              Time Deposits (EGP)
            </h1>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
              <h1
                style={{
                  color: '#0F0F10',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '150%',
                  margin: '0',
                }}
              >
                EGP60,493
              </h1>
              <h1
                style={{
                  color: '#572875',
                  fontFamily: 'Inter',
                  fontSize: '11px',
                  fontWeight: '500',
                  letterSpacing: '0.22px',
                  textTransform: 'uppercase',
                  margin: '0',
                }}
              >
                75.76%
              </h1>
            </div>
            <h1
              style={{
                color: '#757575',
                fontFamily: 'Inter',
                fontSize: '9px',
                fontWeight: '400',
                lineHeight: '140%',
                margin: '0',
              }}
            >
              Total returns : EGP7,997.86
            </h1>
          </div>

          {/* Fourth Item */}
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
            <h1
              style={{
                color: '#323741',
                fontFamily: 'Lato',
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '140%',
                margin: '0',
              }}
            >
              Time Deposits (USD)
            </h1>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '6px' }}>
              <h1
                style={{
                  color: '#0F0F10',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '150%',
                  margin: '0',
                }}
              >
                $60,000
              </h1>
              <h1
                style={{
                  color: '#572875',
                  fontFamily: 'Inter',
                  fontSize: '11px',
                  fontWeight: '500',
                  letterSpacing: '0.22px',
                  textTransform: 'uppercase',
                  margin: '0',
                }}
              >
                75.76%
              </h1>
            </div>
            <h1
              style={{
                color: '#757575',
                fontFamily: 'Inter',
                fontSize: '9px',
                fontWeight: '400',
                lineHeight: '140%',
                margin: '0',
              }}
            >
              Total returns : EGP7,997.86
            </h1>
          </div>
        </div>
        <hr style={{ backgroundColor: '#EAEDF2', height: 2, width: '100%' }} />
      </div>
    </div>
  );
}

export default TreasuryBalance;
