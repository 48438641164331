import React, { useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { useToast } from '../../toastProvider';
import CalendarIcon from '../../../../assests/img/calendar.png';
import DatePicker from 'react-datepicker';
import { addDays, format } from 'date-fns';
import CustomModal from '../../../../components/modal';
import Title from '../components/title';
import '../../../../styles/customToast.css';

function FourthModal({ closeModal, isModalOpen }) {
  const [inputValue, setInputValue] = useState('0');
  const [selectedDate, setSelectedDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const submitFourthModal = () => {
    closeModal();
    // showToast('Liquidity request has been submitted successfully!', {
    //   className: 'custom-toast',
    // });
  };
  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
  };

  return (
    <CustomModal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      contentLabel="Request to Buy or Liquidate"
      style={{
        content: {
          position: 'fixed',
          width: '1420px',
          height: '665px',
          top: '75px',
          left: '10px',
          right: '10px',
          bottom: '-23px',
          background: 'white',
          padding: '0px',
          borderRadius: '12px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          overflow: 'auto',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            width: '1420px',
            height: '52px',
            padding: '8px 16px',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '12px 12px 0px 0px',
            borderBottom: '1px solid var(--UI-Strock-Soft, #EAEDF2)',
            background: 'var(--Background-Primary, #FFF)',
          }}
        ></div>

        <div
          style={{
            height: '24px',
            width: '1420px',
            background: 'var(--Background-Layer, #FAFAFA)',
          }}
        ></div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            background: '#FAFAFA',
            height: '838px',
            width: '1420px',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '47px',
              marginLeft: '133px',
              marginRight: '133px',
              width: '1154px',
              height: '838px',
              borderRadius: '12px',
              border: '1px solid var(--Border-1, #EAEDF2)',
              background: 'var(--Background-Primary, #FFF)',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 'var(--spacing-10, 10px)',
                  alignSelf: 'stretch',
                  paddingTop: '34px',
                  paddingBottom: '32px',
                }}
              >
                <span
                  style={{
                    color: 'var(--Greyscale-900, #09090B)',
                    fontFamily: 'Lato',
                    fontSize: '22px',
                    fontWeight: '500',
                    lineHeight: '140%',
                  }}
                >
                  Manage liquidity plan
                </span>
                <span
                  style={{
                    width: '688px',
                    color: 'var(--UI-Text-Tertiary, #888E9E)',
                    textAlign: 'center',
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                  }}
                >
                  Set your cash flow needs, and we'll optimize your investments
                  to be ready when required.
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '16px',
                  height: '78px',
                  width: '688px',
                }}
              >
                <span
                  style={{
                    alignSelf: 'flex-start',
                    color: '#121212',
                    width: '688px',
                    textAlign: 'start',
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '22.4px',
                  }}
                >
                  Period #1
                </span>

                <input
                  style={{
                    display: 'flex',
                    height: '56px',
                    width: '688px',
                    padding: 'var(--Line-height-12-18, 18px) 16px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                    borderRadius: '10px',
                    border: 'none',
                    background: '#FAFAFA',
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '140%',
                    color: '#121212',
                    marginBottom: '16px',
                  }}
                  placeholder="Liquidity amount"
                  onChange={handleInputChange}
                ></input>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '688px',
                  height: '48px',
                  padding: '12px 0px',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  gap: '8px',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                  marginBottom: '16px',
                }}
              >
                <div
                  style={{
                    width: '194px',
                    height: 'var(--Letter-spacing-Letter-spacing, 1px)',
                    background: '#D9D9D9',
                  }}
                ></div>
                <div
                  style={{
                    width: '176px',
                    height: '24px',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '4px',
                  }}
                >
                  <img
                    style={{
                      display: 'flex',
                      width: '24px',
                      height: '24px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                    src={CalendarIcon}
                    alt="Submit Request"
                    className="img-fluid"
                  />

                  <span
                    style={{
                      color: 'var(--Text-Primary, #121212)',
                      height: '24px',
                      alignSelf: 'center',
                      textAlign: 'center',
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '24px',
                      wordWrap: 'break-word',
                    }}
                  >
                    Select liquidity period
                  </span>
                </div>
                <div
                  style={{
                    width: '194px',
                    height: 'var(--Letter-spacing-Letter-spacing, 1px)',
                    background: '#D9D9D9',
                  }}
                ></div>
              </div>

              <div
                style={{
                  display: 'flex',
                  width: '301.733px',
                  padding: '13.767px',
                  flexDirection: 'column',
                  gap: '13.767px',
                  margin: 'auto',
                  marginBottom: '24px',
                }}
              >
                <DatePicker
                  selected={selectedDate}
                  onChange={(dates) => {
                    const [start, end] = dates;
                    setStartDate(start);
                    setEndDate(end);
                  }}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  inline
                  minDate={addDays(new Date(), 1)}
                />
              </div>
              <div
                className="custom-file-input2"
                style={{
                  display: 'flex',
                  height: '56px',
                  alignItems: 'center',
                  padding: '16px',
                  border: '1px solid #E4E4E7',
                  borderRadius: '8px',
                  justifyContent: 'center', // Centers the content horizontally
                  gap: '16px', // Adds spacing between the label and the image
                  position: 'relative', // Needed for positioning icons
                }}
              ></div>
            </div>

            <div
              style={{
                width: '1154px',
                height: '143px',
                display: 'flex',
                padding: '28px 133px',
                justifyContent: 'space-between',
                alignItems: 'center',
                alignSelf: 'stretch',
                border: '1px #EAEDF2',
                background: '#FFF',
                boxShadow: '0px -5px 47px 0px rgba(0, 0, 0, 0.03)',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '(--2, 8px)',
                }}
              >
                <span
                  style={{
                    color: 'var(--Text-Sub, #757575)',
                    height: '20px',
                    fontStyle: 'normal',
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                  }}
                >
                  Confirm your deposit
                </span>

                <span
                  style={{
                    color: '#09090B',
                    height: '31px',
                    fontStyle: 'normal',
                    fontFamily: 'Lato',
                    fontSize: '22px',
                    fontWeight: '500',
                    lineHeight: '140%',
                  }}
                >
                  EGP{inputValue || 0}
                </span>

                <span
                  style={{
                    color: 'var(--Text-Sub, #757575)',
                    height: '20px',
                    fontStyle: 'normal',
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                  }}
                >
                  On{' '}
                  {selectedDate
                    ? format(selectedDate, 'dd MMMM yyyy')
                    : 'Select a date'}
                </span>
              </div>

              <button
                style={{
                  display: 'flex',
                  width: '340px',
                  height: '38px',
                  padding: '8px var(--Layout-x4, 16px)',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 'var(--Layout-x2, 8px)',
                  borderRadius: 'var(--Radius-Large, 12px)',
                  background: 'var(--Buttons-Primary, #1E1E20)',
                }}
                // onClick={closeSecondModal}
              >
                <span
                  style={{
                    color: 'var(--Text-Invert, #FFF)',
                    textAlign: 'center',
                    height: '22px',
                    fontStyle: 'normal',
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    fontWeight: '600',
                    lineHeight: '140%',
                  }}
                >
                  Submit to confirm
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}

export default FourthModal;
