import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axiosInstance from '../utils/axiosInstance';
import { useNavigate, useLocation } from 'react-router-dom';
import "../styles/customButton.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import landingImage from "../assests/img/landingImage.png";

const CompanyDetails = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const location = useLocation();
  const { companyId } = location.state || {}; 
  // Get userId from localStorage
  const userId = localStorage.getItem('userId');

  useEffect(() => {
    const token = sessionStorage.getItem('token');
    if (!token) {
      console.error('No token found. Redirecting to login.');
      navigate('/login');
    } else {
      console.log('Token found in sessionStorage');
      // Proceed with your component logic
    }
  }, [navigate]);

  const validationSchema = Yup.object({
    companyName: Yup.string()
      .required('Company Name is required')
      .matches(/^[a-zA-Z0-9 ]*$/, 'Company Name cannot contain special characters')
      .min(5, 'Company Name must be at least 5 characters')
      .max(50, 'Company Name must not exceed 50 characters'),
    companySector: Yup.string().required('Company Sector is required'),
    numberOfEmployees: Yup.string().required('Number of Employees is required'),
    isConfirmed: Yup.boolean().oneOf([true], 'You must confirm that you are an Owner or Manager'),
  });

  const formik = useFormik({
    initialValues: {
      companyName: '',
      companySector: '',
      numberOfEmployees: '',
      isConfirmed: false,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);
      try {
        const response = await axiosInstance.post('/companyDetails', {
          ...values,
          userId, // Include userId in the request
        });
        navigate('/uploadDocuments', {
          state: { companyId: response.data.companyDetails._id },
        });
        setMessage('Company details added successfully');
      } catch (error) {
        console.error('Error submitting company details:', error);
        setFieldError('general', `An error occurred while submitting company details: ${error.response?.data?.message || error.message}`);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const handleSkip = (e) => {
    e.preventDefault(); 
    navigate('/dashboard' , {
      state: { companyId },  
    });
  };
  

  return (
    <div className='container'>
      <div className='row vh-100'>
        <div className="col-md-6 d-flex justify-content-center align-items-center bg-light">
          <img src={landingImage} alt="Company Setup" className="img-fluid" />
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-center align-items-center style">
          <div className="w-75">
            <h1 style={{
              fontSize: '32px',
              fontFamily: 'Lato',
              fontWeight: 'bold',
              marginBottom: '12px',
            }}>Company Information</h1>
            <p style={{
              fontSize: '18px',
              fontFamily: 'Lato',
              marginBottom: '32px',
            }}>Provide the info below to start your account</p>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group mb-4 input-container">
                <input
                  style={{
                    marginBottom: '24px',
                    backgroundColor: '#f2f2f2',
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    fontWeight: 'regular',
                  }}
                  type="text"
                  className="form-control"
                  placeholder="Company Name"
                  {...formik.getFieldProps('companyName')}
                />
                {formik.touched.companyName && formik.errors.companyName && (
                  <div className="text-danger">{formik.errors.companyName}</div>
                )}
              </div>
              <div className="form-group mb-4 input-container">
                <select
                  style={{
                    marginBottom: '24px',
                    backgroundColor: '#f2f2f2',
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    fontWeight: 'regular',
                    height: '56px',
                  }}
                  className="form-select"
                  {...formik.getFieldProps('companySector')}
                >
                  <option value='' disabled>Select Sector</option>
                  <option value='Technology'>Health Care</option>
                  <option value='Finance'>Financials</option>
                  <option value='Healthcare'>Information Technology</option>
                  <option value='Healthcare'>Consumer Staples</option>
                </select>
                {formik.touched.companySector && formik.errors.companySector && (
                  <div className="text-danger">{formik.errors.companySector}</div>
                )}
              </div>
              <div className="form-group mb-4 input-container">
                <select
                  style={{
                    marginBottom: '32px',
                    backgroundColor: '#f2f2f2',
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    fontWeight: 'regular',
                    height: '56px',
                  }}
                  className="form-select"
                  {...formik.getFieldProps('numberOfEmployees')}
                >
                   <option value="" disabled>Number of Employees</option>
                <option value="1-10">1-9</option>
                <option value="11-50">10-49</option>
                <option value="51-200">50-199</option>
                <option value="201-500">200-499</option>
                <option value="501-1000">500-999</option>
                <option value="1001+">More than 1000</option>
                </select>
                {formik.touched.numberOfEmployees && formik.errors.numberOfEmployees && (
                  <div className="text-danger">{formik.errors.numberOfEmployees}</div>
                )}
              </div>
              <div className='form-group form-check d-flex align-items-start flex-column' style={{
                marginBottom: '32px',
                fontFamily: 'Lato',
                fontSize: '14px',
              }}>
                <div className="d-flex align-items-center">
                  <input
                    style={{
                      width: '20px',
                      height: '20px',
                      marginRight: '8px',
                    }}
                    type='checkbox'
                    className='custom-control-input'
                    id='confirmCheck'
                    {...formik.getFieldProps('isConfirmed')}
                  />
                  <label className='custom-control-label' htmlFor='confirmCheck'>
                    I confirm that I'm an Owner or Manager on company documents
                  </label>
                </div>
                {formik.touched.isConfirmed && formik.errors.isConfirmed && (
                  <div className="text-danger mt-2">{formik.errors.isConfirmed}</div>
                )}
              </div>

              <button
                style={{
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '140%',
                  marginBottom: '16px',
                }}
                type="submit"
                className="btn btn-dark btn-custom-size-continue w-100"
                disabled={formik.isSubmitting}
              >
                Save and Continue
              </button>

              <p className="text-center mb-3" style={{
                fontSize: '18px',
                fontFamily: 'Lato',
                marginBottom: '16px',
              }}>
                <a href="#"
                  style={{
                    fontWeight: '400',
                    lineHeight: '140%',
                    textDecorationLine: 'underline',
                    fontFamily: 'Lato',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    color: '#000',
                  }}
                  onClick={handleSkip}
                >
                  I’ll set up the company details later
                </a>
              </p>
              {formik.errors.general && (
                <p className="text-center mb-3 text-danger">{formik.errors.general}</p>
              )}
            </form>
            {message && <p className="text-center mb-3">{message}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyDetails;
