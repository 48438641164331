import React from 'react';
import Modal from 'react-modal';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import CloseIcon from '../../../../assests/img/CloseIcon.png';
import InputField from '../../../loans/components/inputfield';

const validationSchema = Yup.object({
  companyContribution: Yup.number()
  .typeError('Company contribution must be a number')  
    .required('Company contribution is required')
    .min(1, 'Company contribution cannot be zero or less')
    .max(999999999, 'Company contribution cannot exceed 9 digits'),

  employeeContribution: Yup.number()
  .typeError('Employee contribution must be a number')  
    .required('Employee contribution is required')
    .min(0, 'Contribution cannot be negative')
    .max(999999999, 'Employee contribution cannot exceed 9 digits'),
});

const EditContributionModal = ({
  isOpen,
  onClose,
  companyContribution,
  employeeContribution,
  onSave,
}) => {
  const initialValues = {
    companyContribution: companyContribution || 0, // Set initial value from props
    employeeContribution: employeeContribution || 0, // Set initial value from props
  };

  const handleSave = (values) => {
    onSave(values.companyContribution, values.employeeContribution);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Edit Contributions"
      style={{
        content: {
          position: 'fixed',
          top: '75px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          background: 'white',
          padding: '0px',
          borderRadius: '12px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '4.5vh',
            padding: '8px 16px',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderRadius: '12px 12px 0px 0px',
            borderBottom: '1px solid var(--UI-Strock-Soft, #EAEDF2)',
            background: 'var(--Background-Primary, #FFF)',
          }}
        >
          <button
            style={{
              height: '20px',
              width: '20px',
              border: 'none',
              backgroundColor: 'transparent',
              padding: 0,
            }}
            onClick={onClose}
          >
            <img
              style={{
                display: 'flex',
                width: '20px',
                height: '20px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              src={CloseIcon}
              alt="Close"
            />
          </button>
        </div>

        <div
          style={{
            display: 'flex',
            borderRadius: '16px 16px 0px 0px',
            background: 'var(--Background-Layer, #FAFAFA)',
            width: '100vw',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '24px',
          }}
        >
          <div
            style={{
              display: 'flex',
              padding: '269px 206px 102px 205px',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'center',
              borderRadius: '12px',
              border: '1px solid var(--Border-1, #EAEDF2)',
              background: 'var(--Background-Primary, #FFF)',
              height: '100vh',
              width: '81vw',
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              maxHeight: '100vh',
            }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSave}
            >
              {({ touched, errors, isSubmitting, values }) => (
                <Form
                  style={{
                    width: '79vw',
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                    alignSelf: 'center',
                    justifySelf: 'center',
                    gap: '32px',
                  }}
                >
                  <span
                    style={{
                      color: '#09090B',
                      fontFamily: 'Lato',
                      fontSize: '22px',
                      fontWeight: 500,
                      lineHeight: '140%',
                    }}
                  >
                    Edit Employee Contribution
                  </span>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '70%',
                      gap: '16px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <InputField
                      label={'Company contribution'}
                      type="text"
                      name={'companyContribution'}
                      value={values.companyContribution}
                      errors={errors.companyContribution}
                      touched={touched.companyContribution}
                    />
                    <InputField
                      label={'Employee contribution'}
                      type="text"
                      name={'employeeContribution'}
                      value={values.employeeContribution}
                      errors={errors.employeeContribution}
                      touched={touched.employeeContribution}
                    />
                  </div>

                  <button
                    type="submit"
                    style={{
                      display: 'flex',
                      width: '70%',
                      padding: '8px 16px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '12px',
                      background: 'var(--Buttons-Primary, #1E1E20)',
                    }}
                    disabled={isSubmitting}
                  >
                    <span
                      style={{
                        color: 'var(--Text-Invert, #FFF)',
                        textAlign: 'center',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '140%',
                      }}
                    >
                      Edit
                    </span>
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditContributionModal;
