import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
  useLocation
} from 'react-router-dom';
import VerifyEmail from './components/VerifyEmail';
import CompanyDetails from './components/CompanyDetails';
import UploadDocuments from './components/UploadDocuments';
import CompanyInformation from './components/CompanyInformation';
import Dashboard from './components/Dashboard';
import DashboardLayout from './components/dasboardlayout';
import TreasuryLayout from './components/layout/treasuryLayout';
import DashboardSetup from './components/DashboardSetup';
import DashboardDocumentsUploading from './components/DashboardDocumentsUploading';
import DashboardCompanyInformation from './components/DashboardCompanyInformation';
import Treasury from './components/Treasury';
import TreasuryRequest from './components/TreasuryRequest';
import SignUpScreen from './screens/auth/signup';
import TreasuryScreen from './screens/treasury';
import SignUpFirstScreen from './screens/auth/signup1';
import Login from './screens/auth/login';
import TransactionPage from './screens/treasury/transactionsPage';
import { TransactionProvider } from './screens/treasury/transactionsPage/components/transactionContext';
import { RequestProvider } from './screens/treasury/requestsPage/components/requestContext';
import RequestPage from './screens/treasury/requestsPage';
import LoansLayout from './components/layout/loansLayout';
import LoanScreen from './screens/loans';
import CustomForm from './screens/loans/components/forms';
import { ToastProvider } from './screens/treasury/toastProvider';
import RetirementScreen from './screens/retirement';
import RetirementLayout from './components/layout/retirementLayout';
import Segments from './screens/retirement/segments';
import SegmentDetail from './screens/retirement/segmentDetail';
import { ApplicationProvider } from './screens/loans/components/ApplicationContext';
import LoanApplication from './screens/loans/loanapplication';
import LoanManager from './screens/loans/loanManager';
import { ToastContainer } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const checkTokenValidity = () => {
      const token = sessionStorage.getItem('token');
      console.log('Checking token validity, token exists:', !!token);
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          const currentTime = Date.now() / 1000;
          console.log('Token expiration:', decodedToken.exp, 'Current time:', currentTime);
          if (decodedToken.exp > currentTime) {
            console.log('Setting isAuthenticated to true');
            setIsAuthenticated(true);
          } else {
            console.log('Token expired, logging out');
            logout();
          }
        } catch (error) {
          console.error('Error decoding token:', error);
          logout();
        }
      } else {
        console.log('No token found, setting isAuthenticated to false');
        setIsAuthenticated(false);
      }
    };

    checkTokenValidity();
  }, []); // Remove the interval and only check on component mount

  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
  };

  const ProtectedRoute = () => {
    const location = useLocation();
    const token = sessionStorage.getItem('token');
    console.log('ProtectedRoute: token exists:', !!token);

    if (!token) {
      console.log('Redirecting to login from:', location.pathname);
      return <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname)}`} replace />;
    }
    return <Outlet />;
  };

  return (
    <div style={{ display: 'flex' }}>
      <ToastContainer
        toastClassName="custom-toast"
        bodyClassName="custom-toast-body"
      />
      <ApplicationProvider>
        <RequestProvider>
          <TransactionProvider>
            <ToastProvider>
              <Routes>
                <Route path="/" element={<SignUpFirstScreen />} />
                <Route path="/registration" element={<SignUpScreen />} />
                <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />

                <Route path="/companyDetails" element={<CompanyDetails />} />
                <Route path="/uploadDocuments" element={<UploadDocuments />} />
                <Route
                  path="/companyInformation"
                  element={<CompanyInformation />}
                />
                <Route
                  path="/dashboard/setupCompany"
                  element={<DashboardSetup />}
                />
                <Route
                  path="/dashboard/uploadingDocuments"
                  element={<DashboardDocumentsUploading />}
                />
                <Route
                  path="/dashboard/companyInformation"
                  element={<DashboardCompanyInformation />}
                />
                  <Route path="/dashboard" element={<DashboardLayout />}>
                    <Route path="/dashboard" element={<Dashboard />} />
                  </Route>
              
                {/* Treasury Routes */}
                <Route path="/dashboard/treasury" element={<TreasuryLayout />}>
                  <Route index element={<TreasuryScreen />} />
                  <Route path="transactions" element={<TransactionPage />} />
                  <Route path="requests" element={<RequestPage />} />
                </Route>
                <Route
                  path="/dashboard/treasury/request"
                  element={<TreasuryRequest />}
                />
                {/* Loans Routes */}
                <Route path="/dashboard/loans" element={<LoansLayout />}>
                  <Route index element={<LoanScreen />} />
                  <Route
                    path="/dashboard/loans/applications"
                    element={<LoanApplication />}
                  />
                  <Route
                    path="/dashboard/loans/applications"
                    element={<LoanManager />}
                  />
                </Route>
                {/* Retirement Routes */}
                <Route
                  path="/dashboard/retirement"
                  element={<RetirementLayout />}
                >
                  <Route index element={<RetirementScreen />} />
                  <Route path="segments" element={<Segments />} />
                  <Route
                    path="segments/:segmentName"
                    element={<SegmentDetail />}
                  />
                </Route>
                <Route path="*" element={<Navigate to="/login" replace />} />
              </Routes>
            </ToastProvider>
          </TransactionProvider>
        </RequestProvider>
      </ApplicationProvider>
    </div>
  );
};

export default App;
