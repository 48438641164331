import React from 'react';
import NotificationsIcon from '../../../assests/img/NotificationsIcon.png';

function top() {
  return (
    <div
      style={{
        width: '100%',
        height: '80px',
        display: 'flex',
        gap: '12px',
        padding: 'var(--Line-height-12-18, 18px) var(--6, 32px)',
        justifyContent: 'flex-end',
        alignItems: 'center',
        borderBottom: '1px solid #E8E8E8',
      }}
    >
      <button
        style={{
          display: 'flex',
          height: '44px',
          width: '44px',
          padding: '8px',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '8px',
          borderRadius: '8px',
          background: '#F5F5F5',
          border: 'none',
        }}
      >
        <img
          style={{
            display: 'flex',
            // width: '20px',
            // height: '20px',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '4px',
            gap: '4px',
          }}
          src={NotificationsIcon}
          alt="Submit Request"
          className="img-fluid"
        />
      </button>
     
    </div>
  );
}

export default top;
