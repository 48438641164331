import React from 'react';
import "../../../styles/customButton.css";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import SignUpLeftContainer from '../leftContainer/signUpLeftContainer';
import SignUpRightContainer from './rightContainer/signUpRightContainer';

function SignUpScreen() {

  return (
    <div className='container'>
      <div className='row vh-100'>
         <SignUpLeftContainer />
         <SignUpRightContainer style={{
          position: 'relative'
         }}> 

         </SignUpRightContainer>
      </div>
    </div>
  );
}


export default SignUpScreen