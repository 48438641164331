export const transactions = [
    {
      description: "description",
      name: "name",
      ref: "id",
      type: "type",
      date: "Sep 18, 2024",
      amount: 'EGP50,000',
    },
    {
      description: "description",
      name: "name",
      ref: "id",
      type: "type",
      date: "Sep 18, 2024",
      amount: 'EGP50,000',
    },
    {
      description: "description",
      name: "name",
      ref: "id",
      type: "type",
      date: "Sep 18, 2024",
      amount: 'EGP50,000',
    },
    {
      description: "description",
      name: "name",
      ref: "id",
      type: "type",
      date: "Sep 18, 2024",
      amount: 'EGP50,000',
    },
    {
      description: "description",
      name: "name",
      ref: "id",
      type: "type",
      date: "Sep 18, 2024",
      amount: 'EGP50,000',
    },
  ];
  