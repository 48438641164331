import { fontStyle, fontWeight, height, lineHeight } from '@mui/system';
import React from 'react';

function LegalCasesForm({ input, name, value, onChange }) {
  return (
    <div style={styles.container}>
      <label style={styles.question}>
        {input}
      </label>
      <div style={styles.radioGroup}>
        <label style={styles.radioLabel}>
          <input
            type="radio"
            name={name}
            value="true" // Use boolean-compatible values
            checked={value === 'true'}
            onChange={onChange}
           // onBlur={onBlur}
            style={styles.radioButton}
          />
          Yes
        </label>
        <label style={styles.radioLabel}>
          <input
            type="radio"
            name={name}
            value="false" // Use boolean-compatible values
            checked={value === 'false'}
            onChange={onChange}
           // onBlur={onBlur}
            style={styles.radioButton}
          />
          No
        </label>
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: '#FAFAFA',
    width: '100%',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
  },
  question: {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight:'400',
    lineHeight:'140%',
    fontStyle:'normal',
    marginBottom: '22px',
    
    color: '#757575',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: '22px',
  },
  radioLabel: {
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Lato',
    fontSize: '14px',
    fontWeight:'400',
    lineHeight:'140%',
    fontStyle:'normal',
    color: '#757575',
  },
  radioButton: {
    marginRight: '8px',
    accentColor:'#121212',
    // width:'20px',
    // height:'20px'
  },
};

export default LegalCasesForm;
