import React, { useState } from 'react';

function TotalRetirementBalance() {
  const [Calendar, setCalendar] = useState('');

  return (

      <div
        style={{
          padding: '24px',
          display: 'inline-flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: '14px',
          borderRadius: '12px',
          border:
            'var(--Letter-spacing-Letter-spacing, 1px) solid var(--Border-1, #EAEDF2)',
          background: '#FFF',
          width: '48vw',
          height: '56vh'
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            alignSelf: 'stretch',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: '4px',
            }}
          >
            <span
              style={{
                color: '#3F3F46',
                fontFamily: 'Lato',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '155%',
              }}
            >
              Total Retirement Balance
            </span>
            <span
              style={{
                color: '#09090B',
                fontFamily: 'Lato',
                fontSize: '28px',
                fontStyle: 'normal',
                fontWeight: '600',
                lineHeight: '140%',
              }}
            >
              EGP80,493.00
            </span>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-end',
              gap: '12px',
            }}
          >
            <select
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                justifyContent: 'center',
                gap: '18px',
                borderRadius: '12px',
                border: '0.5px #F1E8F8',
                backgroundColor: '#FFF',
                boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.08)',
                fontFamily: 'Lato',
                fontSize: '16px',
                height: '36px',
                width: '104px',
              }}
              type="Currency"
              className="form-select"
              id="exampleGovernment"
              placeholder="Currency"
            >
              <option value="">Today</option>
              <option value="Cairo">Week to date</option>
              <option value="Giza">Month to date</option>
              <option value="Giza">Quarter to date</option>
              <option value="Giza">Year to date</option>
              <option value="Alexandria">Since inception</option>
            </select>
            <span
              style={{
                color: '#3F3F46',
                fontFamily: 'Lato',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '155%',
              }}
            >
              Total return EGP30,000.00
            </span>
          </div>
        </div>
        <div
          style={{
            height: '1px',
            background: '#EAEDF2',
            width: '100%',
          }}
        ></div>

        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            gap: '32px',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '25vw'
            }}
          >
            <span
              style={{
                color: '#323741',
                fontFamily: 'Lato',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '140%',
              }}
            >
              Redeemed
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: ' row',
                alignItems: 'center',
                gap: '6px',
              }}
            >
              <span
                style={{
                  color: '#0F0F10',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '24px',
                }}
              >
                0.00
              </span>
            </div>
          </div>
          <div style={{
            width: '1px',
            height: '10vh',
            background: '#EAEDF2'
          }}>

          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '25vw'
            }}
          >
            <span
              style={{
                color: '#323741',
                fontFamily: 'Lato',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '140%',
              }}
            >
              Redeemable
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: ' row',
                alignItems: 'center',
                gap: '6px',
              }}
            >
              <span
                style={{
                  color: '#0F0F10',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '24px',
                }}
              >
                0.00
              </span>
              <span style={{
                display: 'flex',
                padding: '6px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '12px',
                background: '#FDFAFF',
                color: '#572875',
                textAlign: 'center',
                fontFamily: 'Inter',
                fontSize: '11px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '12px',
                letterSpacing: '0.22px',
                textTransform: 'uppercase'
              }}>
                25.2%
              </span>
            </div>
          </div>
          <div style={{
            width: '1px',
            height: '10vh',
            background: '#EAEDF2'
          }}>

          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '25vw'
            }}
          >
            <span
              style={{
                color: '#323741',
                fontFamily: 'Lato',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '140%',
              }}
            >
              Iredeemable
            </span>
            <div
              style={{
                display: 'flex',
                flexDirection: ' row',
                alignItems: 'center',
                gap: '6px',
              }}
            >
              <span
                style={{
                  color: '#0F0F10',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '24px',
                }}
              >
                0.00
              </span>
              <span style={{
                display: 'flex',
                padding: '6px',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '12px',
                background: '#FDFAFF',
                color: '#572875',
                textAlign: 'center',
                fontFamily: 'Inter',
                fontSize: '11px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '12px',
                letterSpacing: '0.22px',
                textTransform: 'uppercase'
              }}>
                75.76%
              </span>
            </div>
          </div>
        </div>
        <div>
            graph
        </div>
      </div>
  );
}

export default TotalRetirementBalance;
