import React, { useState } from 'react';
import TransactionItem from './components/transactionItem'


function TransactionsRetirement() {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '80vw',
            height: '514px',
            padding: '16px',
            flexDirection: 'column',
            gap: '16px',
            flexShrink: '0',
            borderRadius: '12px',
            border:
              'var(--Letter-spacing-Letter-spacing, 1px) solid var(--Border-1, #EAEDF2)',
            background: '#FFF',
          }}
        >
          <span
            style={{
              color: '#171717',
              fontFamily: 'Lato',
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: '140%',
            }}
          >
            Recent Transactions
          </span>
  
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            padding: '8px 12px',
            borderRadius: '8px 0px 0px 0px',
            background: 'var(--UI-Surface-Weak, #F8FAFB)',
          }}>
            <div style={{
                display: 'flex',
                width: '26vw'
            }}>Transactions</div>
            <div style={{
                display: 'flex',
                width: '16vw'
            }}>Date</div>
            <div style={{
                display: 'flex',
                width: '17vw'
            }}>Type</div>
            <div>Amount</div>
          </div>
  
          <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
            <TransactionItem
              fund="B 72 Yawmy MM Fund @49.11"
              date="Jan 01, 2023"
              type="Debit"
              amount="EGP 50,000"
              reference="A847D"
            />
            <TransactionItem
              fund="B 72 Yawmy MM Fund @49.11"
              date="Jan 01, 2023"
              type="Credit"
              amount="EGP 25,000"
              reference="A847E"

            />
          </div>
        </div>
      </div>
    );
  }
    

export default TransactionsRetirement;
