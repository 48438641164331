import React from 'react';
import { Slider, Typography, Box } from '@mui/material';

const CustomSlider = ({ value, onChange }) => {

  const handleSliderChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Box display="flex" alignItems="center">
      <Box
        width="46vw"
        padding="10px"
        bgcolor="#F8F8F8" 
        borderRadius="8px"
        boxShadow="0px 1px 2px rgba(0, 0, 0, 0.1)"
        display="flex"
        flexDirection="column"
      >
        <Slider
          value={value}
          onChange={handleSliderChange}
          min={0}
          max={100}
          step={1}
          valueLabelDisplay="off"
          sx={{
            color: '#D3BADD',
            height: 15,
            '& .MuiSlider-thumb': {
              width: 20,
              height: 20,
              backgroundColor: '#fff',
              border: '2px solid #D3BADD',
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
            },
            '& .MuiSlider-rail': {
              color: '#ECECEC', 
            },
          }}
        />
        <Box display="flex" justifyContent="space-between" mt={-1} px={1}>
          <Typography variant="caption" color="textSecondary">
            0%
          </Typography>
          <Typography variant="caption" color="textSecondary">
            25%
          </Typography>
          <Typography variant="caption" color="textSecondary">
            50%
          </Typography>
          <Typography variant="caption" color="textSecondary">
            75%
          </Typography>
          <Typography variant="caption" color="textSecondary">
            100%
          </Typography>
        </Box>
      </Box>

      {/* Percentage Value Display */}
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-end"
        ml={4} 
      >
        <Typography
          variant="h6"
          style={{
            color: '#222326',
            fontFamily: 'Lato',
            fontSize: '22px',
            fontWeight: '600',
          }}
        >
          {value}%
        </Typography>
        <Typography
          variant="caption"
          style={{
            color: '#4D5874',
            fontFamily: 'Lato',
            fontSize: '10px',
            fontWeight: '500',
          }}
        >
          Per year
        </Typography>
      </Box>
    </Box>
  );
};

export default CustomSlider;
