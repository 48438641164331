import React from 'react';
import SideNavBar from './sideNavBar'; 
import { Outlet } from 'react-router-dom';

const SharedLayout = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100vh',
        overflow: 'hidden',
        flex: 1
      }}
    >
      <SideNavBar />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          overflowY: 'auto',
        }}
      >
        <Outlet /> 
      </div>
    </div>
  );
};

export default SharedLayout;
