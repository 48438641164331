import React from 'react';
import Modal from 'react-modal';
import CloseIcon from '../../../../assests/img/CloseIcon.png';
import IconDown from '../../../../assests/img/IconDown.png';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import InputField from '../../../loans/components/inputfield';
import { MenuItem } from '@mui/material';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { Input } from 'postcss';

const validationSchema = Yup.object({
  segmentName: Yup.string()
    .required('Segment name is required')
    .max(50, 'Segment Name must not exceed 50 characters'),
  employeeLevel: Yup.string().required('Please select an employee level'),
  companyContribution: Yup.number()
    .typeError('Company contribution must be a number')
    .min(500, 'Minimum contribution must be greater than 499')
    .max(999999999, 'Company Contribution cannot exceed 9 digits')
    .required('Company contribution is required'),
});

const data1 = [
  {
    label: 'Entry-Level Employees (Junior)',
    value: 'Entry-Level Employees (Junior)',
  },
  {
    label: ' Individual Contributors (Mid-Level)',
    value: 'Individual Contributors (Mid-Level)',
  },
  {
    label: 'Senior Employees (Senior-Level) ',
    value: 'Senior Employees (Senior-Level)',
  },
  {
    label: 'Management (Lower to Mid-Level Managers)',
    value: 'Management (Lower to Mid-Level Managers)',
  },
  {
    label: 'Senior Management (Upper Management)',
    value: 'Senior Management (Upper Management)',
  },
  {
    label: 'Executives (C-Level or Top-Level Management)',
    value: 'Executives (C-Level or Top-Level Management)',
  },
  { label: 'Board of Directors', value: 'Board of Directors' },
];
const data = [
  {
    label: '10,000 to 19,999',
    value: '10,000 to 19,999',
  },
  {
    label: '20,000 to 39,999',
    value: '20,000 to 39,999',
  },
  {
    label: '40,000 to 59,999 ',
    value: '40,000 to 59,999',
  },
  { label: '60,000 to 79,999', value: '60,000 to 79,999' },
  { label: '80,000 to 99,999', value: '80,000 to 99,999' },
  { label: '100,000 to 200,000', value: '100,000 to 200,000' },
  { label: 'Above 200,000', value: 'Above 200,000' },
];

const SegmentModal = ({ isOpen, onClose, onCreateSegment }) => {
  if (!isOpen) return null;

  const initialValues = {
    segmentName: '',
    employeeLevel: '',
    salaryRange: '',
    companyContribution: '',
  };

  const onSubmit = (values) => {
    onCreateSegment(values);
   
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Request to Buy or Liquidate"
      style={{
        content: {
          position: 'fixed',
          top: '75px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          background: 'white',
          padding: '0px',
          borderRadius: '12px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '8px 16px',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderRadius: '12px 12px 0px 0px',
            borderBottom: '1px solid var(--UI-Strock-Soft, #EAEDF2)',
            background: 'var(--Background-Primary, #FFF)',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '8px 0px',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '10px',
              flexShrink: '0',
            }}
          >
            <button
              style={{
                height: '20px',
                width: '20px',
                border: 'none',
                backgroundColor: 'transparent',
                padding: 0,
              }}
              onClick={onClose}
            >
              <img
                style={{
                  display: 'flex',
                  width: '20px',
                  height: '20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                src={CloseIcon}
                alt="Close"
                className="img-fluid"
              />
            </button>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            borderRadius: '16px 16px 0px 0px',
            background: 'var(--Background-Layer, #FAFAFA)',
            width: '100vw',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '32px',
          }}
        >
          <div
            style={{
              display: 'flex',
              padding: '34px 121px  163px 121px',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '12px',
              border: '1px solid var(--Border-1, #EAEDF2)',
              background: 'var(--Background-Primary, #FFF)',
              height: '100vh',
              width: '81vw',
            }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ touched, errors, isSubmitting }) => (
                <Form
                  style={{
                    width: '79vw',
                    height: '70vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '32px',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '10px',
                      alignSelf: 'stretch',
                    }}
                  >
                    <span
                      style={{
                        color: '#09090B',
                        fontFamily: 'Lato',
                        fontSize: '22px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '140%',
                      }}
                    >
                      Segment Details
                    </span>
                    <span
                      style={{
                        color: '#757575',
                        fontFamily: 'Lato',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                      }}
                    >
                      Enter the key details for this segment to customize your
                      experience.
                    </span>

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '80%',
                        gap: '16px',
                        paddingTop: '20px',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <InputField
                        label={'Segment name'}
                        type="text"
                        name={'segmentName'}
                        errors={errors.segmentName}
                        touched={touched.segmentName}
                      />
                      <InputField
                        label={'Employee level'}
                        type="text"
                        name={'employeeLevel'}
                        errors={errors.employeeLevel}
                        touched={touched.employeeLevel}
                        select
                      >
                        {data1.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item.value}
                            sx={{
                              fontFamily: 'Lato',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: '600',
                              lineHeight: '140%',
                              color: '#121212',
                            }}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </InputField>
                      <InputField
                        label={'Salary Range (Optional)'}
                        type="text"
                        name={'salaryRange'}
                        errors={errors.salaryRange}
                        touched={touched.salaryRange}
                        select
                      >
                        {data.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item.value}
                            sx={{
                              fontFamily: 'Lato',
                              fontSize: '14px',
                              fontStyle: 'normal',
                              fontWeight: '600',
                              lineHeight: '140%',
                              color: '#121212',
                            }}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </InputField>

                      <InputField
                        label={'Company contribution'}
                        type="text"
                        name={'companyContribution'}
                        errors={errors.companyContribution}
                        touched={touched.companyContribution}
                      />
                    </div>
                  </div>

                  <button
                    type="submit"
                    style={{
                      display: 'flex',
                      width: '80%',
                      padding: '8px 16px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '12px',
                      background: 'var(--Buttons-Primary, #1E1E20)',
                      border: 'none',
                    }}
                    disabled={isSubmitting}
                  >
                    <span
                      style={{
                        color: 'var(--Text-Invert, #FFF)',
                        textAlign: 'center',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '140%',
                      }}
                    >
                      Create
                    </span>
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default SegmentModal;
