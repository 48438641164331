import React from 'react';

function title({title, subtitle}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: 'var(--spacing-10, 10px)',
        alignSelf: 'stretch',
        padding: '34px 32px',
        paddingBottom: '32px',
      }}
    >
      <span
        style={{
          color: 'var(--Greyscale-900, #09090B)',
          fontFamily: 'Lato',
          fontSize: '22px',
          fontWeight: '500',
          lineHeight: '140%',
        }}
      >
        {title}
      </span>
      <span
        style={{
          width: '100%',
          color: 'var(--UI-Text-Tertiary, #888E9E)',
          textAlign: 'center',
          fontFamily: 'Lato',
          fontSize: '14px',
          fontWeight: '400',
          lineHeight: '20px',
        }}
      >
        {subtitle}
      </span>
    </div>
  );
}

export default title;
