
import React from 'react';

function BasicButton({ label, onClick, style, className, disabled,...args }) {
  return (
    <button
      onClick={onClick}
      style={style}
      className={className}
      disabled={disabled}
      {...args}
    >
      {label}
    </button>
  );
}

export default BasicButton;
