import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Formik, Field, Form } from 'formik';
import { MenuItem } from '@mui/material';
import * as Yup from 'yup';
import CloseIcon from '../../../../assests/img/CloseIcon.png';
import InputField from '../../../loans/components/inputfield';
import axios from 'axios';

const validationSchema = Yup.object({
  fullName: Yup.string()
    .min(2, 'Full name must be at least 2 characters long')
    .max(50, 'Full name cannot be longer than 50 characters')
    .required('Full name is required'),

  phoneNo: Yup.string()
    .required('Phone Number is required')
    .matches(
      /^(010|011|012|015)\d{8}$/,
      'Phone number must start with 010, 011, 012, or 015 and be 11 digits long'
    ),
  email: Yup.string()
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
      'Invalid email format'
    )
    .required('Email is required'),
  age: Yup.number()
    .typeError('Age must be a number')
    .min(18, 'Age must be greater than 18')
    .max(100, 'Age must be less than 100')
    .required('Age is required'),
});

const departmentOptions = [
  { value: 'Human Resources (HR)', label: 'Human Resources (HR)' },
  { value: 'Finance', label: 'Finance' },
  { value: 'Marketing', label: 'Marketing' },
  { value: 'Sales', label: 'Sales' },
  { value: 'Operations', label: 'Operations' },
  { value: 'IT (Information Technology)', label: 'IT (Information Technology)' },
  { value: 'Customer Service', label: 'Customer Service' },
  { value: 'Product', label: 'Product' },
  { value: 'Legal', label: 'Legal' },
  { value: 'Administration', label: 'Administration' },
  { value: 'Research and Development', label: 'Research and Development' },
  { value: 'Public Relations', label: 'Public Relations' },
  { value: 'Strategy and Planning', label: 'Strategy and Planning' },
  { value: 'Logistics and Supply Chain', label: 'Logistics and Supply Chain' },
  { value: 'Quality Assurance', label: 'Quality Assurance' },
];


const EditInfoModal = ({
  isOpen,
  onClose,
  fullName,
  phoneNo,
  email,
  nationalID,
  department,
  age,
  segment,
  id,
  onSave,
}) => {
  const initialValues = {
    fullName: fullName || '',
    phoneNo: phoneNo || '',
    email: email || '',
    nationalID: nationalID || '',
    department: department || '',
    age: age || 0,
    segment: segment || '',
  };

  const [segments, setSegments] = useState([]);

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}/segments`
        );
        setSegments(response.data);
      } catch (error) {
        console.error('Error fetching segments:', error);
      }
    };

    fetchSegments();
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  const handleSave = (values) => {
    onSave(
      values.fullName,
      values.phoneNo,
      values.email,
      values.department,
      values.age,
      values.segment
    );
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Edit Employee Info"
      style={{
        content: {
          position: 'fixed',
          top: '75px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          background: 'white',
          padding: '0px',
          borderRadius: '12px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            height: '4.5vh',
            padding: '8px 16px',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderRadius: '12px 12px 0px 0px',
            borderBottom: '1px solid var(--UI-Strock-Soft, #EAEDF2)',
            background: 'var(--Background-Primary, #FFF)',
          }}
        >
          <button
            style={{
              height: '20px',
              width: '20px',
              border: 'none',
              backgroundColor: 'transparent',
              padding: 0,
            }}
            onClick={onClose}
          >
            <img
              style={{
                display: 'flex',
                width: '20px',
                height: '20px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              src={CloseIcon}
              alt="Close"
            />
          </button>
        </div>

        <div
          style={{
            display: 'flex',
            borderRadius: '16px 16px 0px 0px',
            flex: 1,
            background: '#FAFAFA',
            width: '100vw',
            justifyContent: 'center',
            alignItems: 'center',
            overflowY: 'scroll',
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            paddingTop: '5vh',
          }}
        >
          <div
            style={{
              background: 'white',
              borderRadius: '20px',
              height: '100%',
              width: '90%',
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              padding: '15vw',
            }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSave}
              enableReinitialize={true}
            >
              {({ touched, errors, isSubmitting, values, handleChange }) => (
                <Form
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    alignSelf: 'center',
                    justifySelf: 'center',
                    gap: '32px',
                  }}
                >
                  <span
                    style={{
                      color: '#09090B',
                      fontFamily: 'Lato',
                      fontSize: '22px',
                      fontWeight: 500,
                      lineHeight: '140%',
                    }}
                  >
                    Edit Employee Info
                  </span>

                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      gap: '16px',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {/* Full Name Field */}
                    <Field
                      name="fullName"
                      as={InputField}
                      label="Full Name"
                      errors={errors.fullName}
                      touched={touched.fullName}
                    />

                    {/* Phone Number Field */}
                    <Field
                      name="phoneNo"
                      as={InputField}
                      type="text"
                      label="Phone Number"
                      errors={errors.phoneNo}
                      touched={touched.phoneNo}
                    />

                    {/* Email Field */}
                    <Field
                      name="email"
                      as={InputField}
                      type="email"
                      label="Email"
                      errors={errors.email}
                      touched={touched.email}
                    />

                    {/* National ID (read-only) */}
                    <Field
                      name="nationalID"
                      as={InputField}
                      type="text"
                      label="National ID"
                      InputProps={{ readOnly: true }}
                    />

                    <InputField
                      label={'Department'}
                      type="text"
                      name={'department'}
                      value={values.department}
                      errors={errors.department}
                      touched={touched.department}
                      select
                    >
                      {departmentOptions.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item.value}
                          sx={{
                            fontFamily: 'Lato',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '140%',
                            color: '#121212',
                          }}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </InputField>

                    <Field
                      name="age"
                      as={InputField}
                      type="text"
                      label="Age"
                      errors={errors.age}
                      touched={touched.age}
                    />

                    <InputField
                      label={'Segment'}
                      type="text"
                      name={'segment'}
                      value={values.segment}
                      errors={errors.segment}
                      touched={touched.segment}
                      select
                    >
                      {segments.map((segment, index) => (
                        <MenuItem
                          key={index}
                          value={segment.segmentName}
                          sx={{
                            fontFamily: 'Lato',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '140%',
                            color: '#121212',
                          }}
                        >
                          {segment.segmentName}
                        </MenuItem>
                      ))}
                    </InputField>
                  </div>

                  <button
                    type="submit"
                    style={{
                      display: 'flex',
                      width: '52vw',
                      padding: '8px 16px',
                      justifyContent: 'center',
                      alignItems: 'center',
                      borderRadius: '12px',
                      background: 'var(--Buttons-Primary, #1E1E20)',
                    }}
                    disabled={isSubmitting}
                  >
                    <span
                      style={{
                        color: 'var(--Text-Invert, #FFF)',
                        textAlign: 'center',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: '140%',
                      }}
                    >
                      Edit
                    </span>
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditInfoModal;
