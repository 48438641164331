import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import '../styles/customButton.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import BokraLogo from '../assests/img/BokraLogo.png';

const DashboardSetup = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  

  const commonStyles = {
    display: 'flex',
    color: '#FFF',
  };

  const validationSchema = Yup.object({
    companyName: Yup.string().required('Company Name is required'),
    companySector: Yup.string().required('Company Sector is required'),
    numberOfEmployees: Yup.string().required('Number of Employees is required'),
    isConfirmed: Yup.boolean().oneOf(
      [true],
      'You must confirm that you are an Owner or Manager'
    ),
  });

  const formik = useFormik({
    initialValues: {
      companyName: '',
      companySector: '',
      numberOfEmployees: '',
      isConfirmed: false,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      setSubmitting(true);

      try {
        await axios.post(`${process.env.REACT_APP_SERVER_URL}/companyDetails`, {
          companyName: values.companyName,
          companySector: values.companySector,
          numberOfEmployees: values.numberOfEmployees,
          isConfirmed: values.isConfirmed,
        });
        navigate('/dashboard/uploadingDocuments');
        setMessage('Company details added successfully');
      } catch (error) {
        setFieldError(
          'general',
          `An error occurred while submitting company details: ${error.message}`
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        overflowY: 'hidden',
        scrollbarWidth: 'none',
      }}
      className="hide-scrollbar"
    >
      <div
        style={{
          display: 'flex',
          //  width: '1440px',
          height: '13vh',
          padding: '18px 32px',
          alignItems: 'center',
          gap: '12px',
          flexShrink: '0',
          borderBottom: '1px solid #E8E8E8',
          background: '#FFF',
        }}
      >
        <img
          style={{
            display: 'flex',
            width: '7.8vw',
            height: '3.9vh',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          src={BokraLogo}
          alt="bokralogo"
          className="img-fluid"
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '87vh',
        }}
      >
        <div
          style={{
            ...commonStyles,
            width: '100%',
            height: '100%',
            padding: '40px',
            background: '#FAFAFA',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.06)',
            borderTop: '1px #E4E4E7 solid',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '24px',
          }}
        >
          <div
            style={{
              ...commonStyles,
              alignSelf: 'stretch',
              //height: '282px',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: '24px',
            }}
          >
            <div
              style={{
                ...commonStyles,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '4px',
              }}
            >
              <div
                style={{
                  color: '#09090B',
                  fontSize: '24px',
                  fontFamily: 'Lato',
                  fontWeight: 500,
                  lineHeight: '36px',
                  wordWrap: 'break-word',
                }}
              >
                Set up your company account
              </div>
            </div>
            <div
              style={{
                ...commonStyles,
                alignSelf: 'stretch',
                height: '222px',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: '24px',
              }}
            >
              <div
                style={{
                  ...commonStyles,
                  borderRadius: '99px',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '24px',
                }}
              >
                <div
                  style={{
                    ...commonStyles,
                    width: '40px',
                    height: '40px',
                    padding: '5.71px',
                    background: 'white',
                    borderRadius: '1428.57px',
                    border: '2px #572875 solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '11.43px',
                  }}
                >
                  <div
                    style={{
                      width: '29px',
                      height: '29px',
                      textAlign: 'center',
                      color: '#572875',
                      fontSize: '20px',
                      fontFamily: 'Lato',
                      fontWeight: 500,
                      lineHeight: '20px',
                      wordWrap: 'break-word',
                    }}
                  >
                    1
                  </div>
                </div>
                <div
                  style={{
                    ...commonStyles,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '4px',
                  }}
                >
                  <div
                    style={{
                      color: '#09090B',
                      fontSize: '20px',
                      fontFamily: 'Lato',
                      fontWeight: 500,
                      lineHeight: '28px',
                      wordWrap: 'break-word',
                    }}
                  >
                    Company Information
                  </div>
                  <div
                    style={{
                      color: '#52525B',
                      fontSize: '16px',
                      fontFamily: 'Lato',
                      fontWeight: 400,
                      lineHeight: '25.60px',
                      wordWrap: 'break-word',
                    }}
                  >
                    Let’s get started. Tell about you and your company.
                  </div>
                </div>
              </div>

              <div
                style={{
                  ...commonStyles,
                  alignSelf: 'stretch',
                  borderRadius: '99px',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '24px',
                }}
              >
                <div
                  style={{
                    ...commonStyles,
                    width: '40px',
                    height: '40px',
                    padding: '5.71px',
                    background: 'white',
                    borderRadius: '1428.57px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '11.43px',
                  }}
                >
                  <div
                    style={{
                      width: '29px',
                      height: '29px',
                      textAlign: 'center',
                      color: '#572875',
                      fontSize: '18px',
                      fontFamily: 'Lato',
                      fontWeight: 400,
                      lineHeight: '25.20px',
                      wordWrap: 'break-word',
                    }}
                  >
                    2
                  </div>
                </div>
                <div
                  style={{
                    ...commonStyles,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '4px',
                  }}
                >
                  <div
                    style={{
                      color: '#52525B',
                      fontSize: '20px',
                      fontFamily: 'Lato',
                      fontWeight: 500,
                      lineHeight: '28px',
                      wordWrap: 'break-word',
                    }}
                  >
                    Company Verifying
                  </div>
                  <div
                    style={{
                      color: '#52525B',
                      fontSize: '16px',
                      fontFamily: 'Lato',
                      fontWeight: 400,
                      lineHeight: '25.60px',
                      wordWrap: 'break-word',
                    }}
                  >
                    You can just draft a name now and change it later.
                  </div>
                </div>
              </div>

              <div
                style={{
                  ...commonStyles,
                  alignSelf: 'stretch',
                  borderRadius: '99px',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '24px',
                }}
              >
                <div
                  style={{
                    ...commonStyles,
                    width: '40px',
                    height: '40px',
                    padding: '5.71px',
                    background: 'white',
                    borderRadius: '1428.57px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '11.43px',
                  }}
                >
                  <div
                    style={{
                      width: '29px',
                      height: '29px',
                      textAlign: 'center',
                      color: '#572875',
                      fontSize: '20px',
                      fontFamily: 'Lato',
                      fontWeight: 500,
                      lineHeight: '20px',
                      wordWrap: 'break-word',
                    }}
                  >
                    3
                  </div>
                </div>
                <div
                  style={{
                    ...commonStyles,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '4px',
                  }}
                >
                  <div
                    style={{
                      color: '#52525B',
                      fontSize: '20px',
                      fontFamily: 'Lato',
                      fontWeight: 500,
                      lineHeight: '28px',
                      wordWrap: 'break-word',
                    }}
                  >
                    Office Address
                  </div>
                  <div
                    style={{
                      color: '#52525B',
                      fontSize: '16px',
                      fontFamily: 'Lato',
                      fontWeight: 400,
                      lineHeight: '25.60px',
                      wordWrap: 'break-word',
                    }}
                  >
                    You can just draft a name now and change it later.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            width: '100%',
            // height: '915px',
            padding: '40px',
            background: '#FAFAFA',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.06)',
            borderTop: '1px #E4E4E7 solid',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '24px',
          }}
        >
          <h1
            style={{
              fontSize: '32px',
              fontFamily: 'Lato',
              fontWeight: 'bold',
              marginBottom: '12px',
            }}
          >
            Company Information
          </h1>
          <p
            style={{
              fontSize: '18px',
              fontFamily: 'Lato',
              marginBottom: '32px',
            }}
          >
            Provide the info below to start your account
          </p>

          <form onSubmit={formik.handleSubmit}>
            <div className="form-group mb-4 input-container">
              <input
                style={{
                  marginBottom: '24px',
                  backgroundColor: '#f2f2f2',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  height: '56px',
                  width: '100%',
                  fontWeight: 'regular',
                }}
                type="text"
                className="form-control"
                id="exampleInputCompanyName"
                placeholder="Company Name"
                {...formik.getFieldProps('companyName')}
              />
              {formik.touched.companyName && formik.errors.companyName && (
                <div className="text-danger">{formik.errors.companyName}</div>
              )}
            </div>

            <div className="form-group mb-4 input-container">
              <select
                style={{
                  marginBottom: '24px',
                  backgroundColor: '#f2f2f2',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  height: '56px',
                  width: '100%',
                  fontWeight: 'regular',
                }}
                className="form-select"
                id="exampleInputCompanySector"
                {...formik.getFieldProps('companySector')}
              >
                <option value="" disabled>
                  Select Sector
                </option>
                <option value="Technology">Technology</option>
                <option value="Finance">Finance</option>
                <option value="Healthcare">Healthcare</option>
              </select>
              {formik.touched.companySector && formik.errors.companySector && (
                <div className="text-danger">{formik.errors.companySector}</div>
              )}
            </div>

            <div className="form-group mb-4 input-container">
              <select
                style={{
                  marginBottom: '32px',
                  backgroundColor: '#f2f2f2',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  height: '56px',
                  width: '100%',
                  fontWeight: 'regular',
                }}
                className="form-select"
                id="exampleInputNumberOfEmployees"
                {...formik.getFieldProps('numberOfEmployees')}
              >
                <option value="" disabled>
                  Number of Employees
                </option>
                <option value="1-10">1-9</option>
                <option value="11-50">10-49</option>
                <option value="51-200">50-199</option>
                <option value="201-500">200-499</option>
                <option value="501-1000">500-999</option>
                <option value="1001+">More than 1000</option>
              </select>
              {formik.touched.numberOfEmployees &&
                formik.errors.numberOfEmployees && (
                  <div className="text-danger">
                    {formik.errors.numberOfEmployees}
                  </div>
                )}
            </div>

            <div
              style={{
                marginBottom: '32px',
                fontFamily: 'Lato',
                fontSize: '14px',
              }}
              className="form-group form-check d-flex align-items-center"
            >
              <input
                style={{ width: '20px', height: '20px', marginRight: '8px' }}
                type="checkbox"
                className="custom-control-input"
                id="confirmCheck"
                {...formik.getFieldProps('isConfirmed')}
              />
              <label className="custom-control-label" htmlFor="confirmCheck">
                I confirm that I'm an Owner or Manager on company documents
              </label>
            </div>
            {formik.touched.isConfirmed && formik.errors.isConfirmed && (
              <div
                style={{ textAlign: 'left', marginBottom: '32px' }}
                className="text-danger"
              >
                {formik.errors.isConfirmed}
              </div>
            )}

            <button
              style={{
                fontFamily: 'Lato',
                fontSize: '16px',
                fontWeight: '600',
                marginBottom: '16px',
                width: '200px',
              }}
              type="submit"
              className="btn btn-dark btn-custom-size-continue w-100"
              disabled={formik.isSubmitting}
            >
              Save and Continue
            </button>

            {formik.errors.general && (
              <p className="text-center mb-3 text-danger">
                {formik.errors.general}
              </p>
            )}
          </form>

          {message && <p className="text-center mb-3">{message}</p>}
        </div>
      </div>
    </div>
  );
};

export default DashboardSetup;
