import React from 'react';

function assetDistribution() {
  return (
    <div
      style={{
        display: 'flex',
        width: '80vw',
        padding: '24px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
        flexShrink: 0,
        borderRadius: '12px',
        border:
          'var(--Letter-spacing-Letter-spacing, 1px) solid var(--Border-1, #EAEDF2)',
        background: 'var(--Background-Primary, #FFF)',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
        }}
      >
        <span
          style={{
            width: '100%',
            color: '#171717',
            fontSize: '20px',
            fontFamily: 'Lato',
            fontWeight: '600',
            wordWrap: 'break-word',
          }}
        >
          Asset Distribution
        </span>
        <select
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            justifyContent: 'center',
            marginLeft: 'auto',
            gap: '18px',
            borderRadius: '12px',
            border: '0.5px #F1E8F8',
            backgroundColor: '#FFF',
            boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.08)',
            fontFamily: 'Lato',
            fontSize: '16px',
            height: '36px',
            width: '104px',
          }}
          type="Currency"
          className="form-select"
          id="exampleGovernment"
          placeholder="Currency"
        >
          <option value="">Today</option>
          <option value="Cairo">Week to date</option>
          <option value="Giza">Month to date</option>
          <option value="Giza">Quarter to date</option>
          <option value="Giza">Year to date</option>
          <option value="Alexandria">Since inception</option>
        </select>
      </div>
      <div style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '16px'
      }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        width: '30vw',
          gap: '16px',
        }}
      >
        <span
          style={{
            // width: '20vw',
          }}
        >
          40%
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '4px',
          }}
        >
          <span
            style={{
                color: 'var(--Text-Primary, #121212',
                fontFamily: 'Lato',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '900',
                lineHeight: '140%' 
            }}
          >
            Fixed income & Money market 
          </span>
          <span
            style={{
                color: 'var(--Text-Secondary, #1F2023)',
                fontFamily: 'Lato',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '140%' /* 19.6px */
            }}
          >
            EGP20,000.00
          </span>
          <span
            style={{
                color: 'var(--Text-Sub, #757575)',
                fontFamily: 'Lato',
                fontSize: '9px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '140%' /* 12.6px */
            }}
          >
            Total Returns : EGP7,997.86 
          </span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        width: '30vw',
          gap: '16px',
        }}
      >
        <span
          style={{
            // width: '20vw',
          }}
        >
          20%
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '4px',
          }}
        >
          <span
            style={{
                color: 'var(--Text-Primary, #121212',
                fontFamily: 'Lato',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '900',
                lineHeight: '140%' 
            }}
          >
            Funds
          </span>
          <span
            style={{
                color: 'var(--Text-Secondary, #1F2023)',
                fontFamily: 'Lato',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '140%' /* 19.6px */
            }}
          >
            EGP20,000.00
          </span>
          <span
            style={{
                color: 'var(--Text-Sub, #757575)',
                fontFamily: 'Lato',
                fontSize: '9px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '140%' /* 12.6px */
            }}
          >
            Total Returns : EGP7,997.86 
          </span>
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        width: '30vw',
          gap: '16px',
        }}
      >
        <span
          style={{
            // width: '20vw',
          }}
        >
          40%
        </span>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: '4px',
          }}
        >
          <span
            style={{
                color: 'var(--Text-Primary, #121212',
                fontFamily: 'Lato',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: '900',
                lineHeight: '140%' 
            }}
          >
            Time Deposits
          </span>
          <span
            style={{
                color: 'var(--Text-Secondary, #1F2023)',
                fontFamily: 'Lato',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '140%' /* 19.6px */
            }}
          >
            EGP20,000.00
          </span>
          <span
            style={{
                color: 'var(--Text-Sub, #757575)',
                fontFamily: 'Lato',
                fontSize: '9px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '140%' /* 12.6px */
            }}
          >
            Total Returns : EGP7,997.86 
          </span>
        </div>
      </div>
      </div>
    </div>
  );
}

export default assetDistribution;
