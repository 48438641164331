import React, { useState } from 'react';
import Modal from 'react-modal';
import CloseIcon from '../../../../assests/img/CloseIcon.png';
import IconDown from '../../../../assests/img/IconDown.png';
import VestingPlanModalTwo from './vestingPlanModalTwo';
import CustomSlider from '../../../../components/slider/customSlider';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  distributionFreq: Yup.string().required('Distribution Frequency is required'),
  period: Yup.string().required('Please select a period'),
  vestingPeriod: Yup.string().required('Please select a period'),
});

const VestingPlanModal = ({ isOpen, onClose }) => {
  const [formData, setFormData] = useState({
    distributionFreq: '',
    period: '',
    vestingPeriod: '',
  });

  const [isVestingPlanTwoOpen, setIsVestingPlanTwoOpen] = useState(false);
  const [vestingPeriod, setVestingPeriod] = useState(1);
  const [period, setPeriod] = useState(4);

  const onSubmit = (values, { setSubmitting }) => {
    setSubmitting(false);
    setVestingPeriod(parseInt(values.vestingPeriod, 10));
    setPeriod(parseInt(values.period, 10));
    setFormData(values); // Save form data in the parent state
    setIsVestingPlanTwoOpen(true);
  };

  const handleBackToVestingPlanModal = () => {
    setIsVestingPlanTwoOpen(false);
  };

  const closeBothModals = () => {
    setIsVestingPlanTwoOpen(false); // Close the second modal
    onClose(); // Close the parent modal
  };

  return (
    <>
      <Modal
        isOpen={isOpen && !isVestingPlanTwoOpen}
        contentLabel="Add New Employee"
        style={{
          content: {
            position: 'fixed',
            top: '75px',
            left: '0px',
            right: '0px',
            bottom: '0px',
            background: 'white',
            padding: '0px',
            borderRadius: '12px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '8px 16px',
              justifyContent: 'flex-end',
              alignItems: 'center',
              borderRadius: '12px 12px 0px 0px',
              borderBottom: '1px solid var(--UI-Strock-Soft, #EAEDF2)',
              background: 'var(--Background-Primary, #FFF)',
            }}
          >
            <button
              style={{
                height: '20px',
                width: '20px',
                border: 'none',
                backgroundColor: 'transparent',
                padding: 0,
              }}
              onClick={onClose}
            >
              <img
                style={{
                  display: 'flex',
                  width: '20px',
                  height: '20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                src={CloseIcon}
                alt="Close"
              />
            </button>
          </div>

          <div
            style={{
              display: 'flex',
              borderRadius: '16px 16px 0px 0px',
              background: 'var(--Background-Layer, #FAFAFA)',
              width: '100vw',
              height: '100vh',
              justifyContent: 'center',
              alignItems: 'center',
              paddingTop: '32px',
            }}
          >
            <div
              style={{
                display: 'flex',
                padding: '74px 121px 123px 121px',
                justifyContent: 'center',
                alignItems: 'flex-start',
                borderRadius: '12px',
                border: '1px solid var(--Border-1, #EAEDF2)',
                background: 'var(--Background-Primary, #FFF)',
                height: 'auto',
                width: '81vw',
                overflowY: 'scroll',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
                maxHeight: '90vh',
              }}
            >
              <Formik
                initialValues={formData}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ touched, errors, isSubmitting }) => (
                  <Form
                    style={{
                      width: '79vw',
                      display: 'flex',
                      height: '100%',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: '74px',
                    }}
                  >
                    <div>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '10px',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          style={{
                            color: '#09090B',
                            fontFamily: 'Lato',
                            fontSize: '22px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '140%',
                          }}
                        >
                          Vesting Plan
                        </span>
                        <span
                          style={{
                            color: '#888E9E',
                            fontFamily: 'Lato',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '140%',
                          }}
                        >
                          Set vesting plan for your employees retirement
                        </span>
                      </div>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '55vw',
                      }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '16px',
                          padding: '24px',
                          alignItems: 'flex-start',
                          alignSelf: 'stretch',
                          border: 'none',
                        }}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <span
                            style={{
                              alignSelf: 'stretch',
                              color: '#09090B',
                              fontSize: '16px',
                              fontWeight: '500',
                              lineHeight: '160%',
                            }}
                          >
                            Cliff period
                          </span>
                          <span
                            style={{
                              alignSelf: 'stretch',
                              color: '#52526B',
                              fontSize: '14px',
                              fontWeight: '400',
                              lineHeight: '155%',
                            }}
                          >
                            Employees start vesting after
                          </span>
                        </div>
                        <div style={{ position: 'relative', width: '51.5vw' }}>
                          <Field
                            name="period"
                            style={{
                              width: '100%',
                              padding: '12px',
                              paddingRight: '60px',
                              borderRadius: '8px',
                              border: '1px solid #ddd',
                              background: '#FAFAFA',
                            }}
                          />
                          <span
                            style={{
                              position: 'absolute',
                              right: '20px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              color: '#555',
                            }}
                          >
                            Years
                          </span>
                        </div>

                        {touched.period && errors.period && (
                          <p
                            style={{
                              fontSize: '16px',
                              color: '#BB2124',
                              textAlign: 'left',
                              width: '80%',
                              marginBottom: '3px',
                            }}
                          >
                            {errors.period}
                          </p>
                        )}
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <span
                            style={{
                              alignSelf: 'stretch',
                              color: '#09090B',
                              fontSize: '16px',
                              fontWeight: '500',
                              lineHeight: '160%',
                            }}
                          >
                            Vesting period
                          </span>
                          <span
                            style={{
                              alignSelf: 'stretch',
                              color: '#52526B',
                              fontSize: '14px',
                              fontWeight: '400',
                              lineHeight: '155%',
                            }}
                          >
                            Duration of employees vesting
                          </span>
                        </div>
                        <div style={{ position: 'relative', width: '51.5vw' }}>
                          <Field
                            name="vestingPeriod"
                            style={{
                              width: '100%',
                              padding: '12px',
                              paddingRight: '60px',
                              borderRadius: '8px',
                              border: '1px solid #ddd',
                              background: '#FAFAFA',
                            }}
                          />
                          <span
                            style={{
                              position: 'absolute',
                              right: '20px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              color: '#555',
                            }}
                          >
                            Years
                          </span>
                        </div>

                        {touched.vestingPeriod && errors.vestingPeriod && (
                          <p
                            style={{
                              fontSize: '16px',
                              color: '#BB2124',
                              textAlign: 'left',
                              width: '80%',
                              marginBottom: '3px',
                            }}
                          >
                            {errors.vestingPeriod}
                          </p>
                        )}
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <span
                            style={{
                              alignSelf: 'stretch',
                              color: '#09090B',
                              fontSize: '16px',
                              fontWeight: '500',
                              lineHeight: '160%',
                            }}
                          >
                            Distribution frequency
                          </span>
                          <span
                            style={{
                              alignSelf: 'stretch',
                              color: '#52526B',
                              fontSize: '14px',
                              fontWeight: '400',
                              lineHeight: '155%',
                            }}
                          >
                            Decide how Frequent employees are vesting each year
                          </span>
                        </div>
                        <div style={{ position: 'relative', width: '51.5vw' }}>
                          <Field
                            type="text"
                            name="distributionFreq"
                            style={{
                              width: '100%',
                              padding: '12px',
                              paddingRight: '60px',
                              borderRadius: '8px',
                              border: '1px solid #ddd',
                              background: '#FAFAFA',
                            }}
                          />
                          <span
                            style={{
                              position: 'absolute',
                              right: '20px',
                              top: '50%',
                              transform: 'translateY(-50%)',
                              color: '#555',
                            }}
                          >
                            Yearly
                          </span>
                        </div>

                        {touched.distributionFreq &&
                          errors.distributionFreq && (
                            <p
                              style={{
                                fontSize: '16px',
                                color: '#BB2124',
                                textAlign: 'left',
                                width: '80%',
                                marginBottom: '3px',
                              }}
                            >
                              {errors.distributionFreq}
                            </p>
                          )}
                      </div>
                    </div>

                    <button
                      type="submit"
                      style={{
                        display: 'flex',
                        width: '51.5vw',
                        padding: '8px 16px',
                        justifySelf: 'center',
                        alignSelf: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '12px',
                        background: 'var(--Buttons-Primary, #1E1E20)',
                        border:'none'
                      }}
                      disabled={isSubmitting}
                    >
                      <span
                        style={{
                          color: 'var(--Text-Invert, #FFF)',
                          textAlign: 'center',
                          fontFamily: 'Lato',
                          fontSize: '16px',
                          fontStyle: 'normal',
                          fontWeight: '600',
                          lineHeight: '140%',
                        }}
                      >
                        Next
                      </span>
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
      <VestingPlanModalTwo
        isOpen={isVestingPlanTwoOpen}
        onClose={closeBothModals}
        vestingPeriod={vestingPeriod}
        period={period}
        onBack={() => setIsVestingPlanTwoOpen(false)}
      />
    </>
  );
};

export default VestingPlanModal;
