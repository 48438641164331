import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import AddBankAccountModal from './AddBankAccountModal';
import axiosInstance from '../../../../utils/axiosInstance'; // Import your axios instance

const Spinner = () => (
  <div className="spinner">
    <div className="bounce1"></div>
    <div className="bounce2"></div>
    <div className="bounce3"></div>
    <style jsx>{`
      .spinner {
        margin: 100px auto 0;
        width: 70px;
        text-align: center;
      }

      .spinner > div {
        width: 18px;
        height: 18px;
        background-color: #333;
        border-radius: 100%;
        display: inline-block;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;
      }

      .spinner .bounce1 {
        animation-delay: -0.32s;
      }

      .spinner .bounce2 {
        animation-delay: -0.16s;
      }

      @keyframes sk-bouncedelay {
        0%, 80%, 100% { 
          transform: scale(0);
        } 40% { 
          transform: scale(1.0);
        }
      }
    `}</style>
  </div>
);

const NextModal = ({ isOpen, onClose, employee, onStatusUpdate }) => {
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [accounts, setAccounts] = useState([

  ]);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  }, [isOpen]);


  const getModalStyle = () => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '581px',
    height: 'auto',
    maxHeight: '90vh',
    background: '#FFF',
    display: 'flex',
    flexDirection: 'column',
    padding: '24px',
    borderRadius: '12px',
    boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
    overflowY: 'auto',
  });


  const handleAddAccount = () => {
    setShowAddAccount(true);
  };

  const handleBackToAccounts = () => {
    setShowAddAccount(false);
  };

  const handleSaveAccount = (newAccount) => {
    const newAccountWithId = {
      ...newAccount,
      id: (accounts.length + 1).toString(), // Generate a new ID
    };
    setAccounts([...accounts, newAccountWithId]);
    setShowAddAccount(false);
  };

  const handleSelectAccount = (accountId) => {
    setSelectedAccountId(prevSelected => prevSelected === accountId ? null : accountId);
  };

  const renderCheckCircle = (isSelected) => (
    <div
      style={{
        position: 'absolute',
        top: '8px',
        right: '8px',
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        border: '2px solid #E2E4E9',
        backgroundColor: isSelected ? '#000000' : 'white',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        fontSize: '14px',
        fontWeight: 'bold',
        transition: 'background-color 0.3s ease',
      }}
    >
      {isSelected && '✓'}
    </div>
  );

  const updateEmployeeStatus = async () => {
    try {
      console.log('reached here')
      console.log(employee._id)
      const response = await axiosInstance.put(`/employees/${employee._id}/status`, {
        status: 'inactive'
      });
      
      if (response.status === 200) {
        console.log('Employee status updated successfully');
        onStatusUpdate(employee.id, 'inactive');
        onClose();
      } else {
        console.error('Failed to update employee status');
      }
    } catch (error) {
      console.error('Error updating employee status:', error);
    }
  };

  if (isLoading) {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Loading"
        style={{
          content: {
            ...getModalStyle(),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <Spinner />
      </Modal>
    );
  }

  if (showAddAccount) {
    return (
      <AddBankAccountModal 
        onBack={accounts.length > 0 ? handleBackToAccounts : null} 
        onSave={handleSaveAccount}
        onClose={() => {
          setShowAddAccount(false);
          onClose();
        }}
      />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel="Select Withdrawal Account"
      style={{
        content: {
          ...getModalStyle(),
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: 1000,
        },
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '24px' }}>
        <span style={{ fontSize: '18px', fontWeight: 600 }}>
          Select withdrawal account
        </span>
        <button
          onClick={onClose}
          style={{
            cursor: 'pointer',
            background: 'none',
            border: 'none',
            fontSize: '24px',
          }}
        >
          ×
        </button>
      </div>

      {accounts.map((account) => (
        <div 
          key={account.id} 
          style={{ 
            marginBottom: '16px', 
            padding: '16px', 
            backgroundColor: '#F8F8F8',
            borderRadius: '8px',
            cursor: 'pointer',
            position: 'relative',
          }}
          onClick={() => handleSelectAccount(account.id)}
        >
          {renderCheckCircle(selectedAccountId === account.id)}
          <p style={{ fontWeight: 'bold', margin: '0 0 8px 0', fontSize: '16px' }}>{account.accountName}</p>
          <p style={{ margin: '0 0 4px 0', color: '#333', fontSize: '14px' }}>{account.accountNumber}</p>
          <p style={{ margin: '0', fontSize: '12px', color: '#888' }}>Account Number</p>
          <p style={{ margin: '8px 0 4px 0', color: '#333', fontSize: '14px' }}>{account.iban}</p>
          <p style={{ margin: '0', fontSize: '12px', color: '#888' }}>IBAN</p>
        </div>
      ))}

      <button
        onClick={handleAddAccount}
        style={{
          padding: '12px 0',
          backgroundColor: 'transparent',
          color: '#000000',
          border: 'none',
          cursor: 'pointer',
          textAlign: 'left',
          fontSize: '16px',
          fontWeight: 'bold',
          marginBottom: '24px',
        }}
      >
        + Add bank account
      </button>

      <button
        style={{
          padding: '12px',
          backgroundColor: selectedAccountId ? '#000' : '#ccc',
          color: 'white',
          border: 'none',
          borderRadius: '8px',
          cursor: selectedAccountId ? 'pointer' : 'not-allowed',
          width: '100%',
          fontSize: '16px',
          fontWeight: 'bold',
        }}
        disabled={!selectedAccountId}
        onClick={() => {
          if (selectedAccountId) {
            updateEmployeeStatus();
          }
        }}
      >
        Withdraw amount
      </button>
    </Modal>
  );
};


export default NextModal;
