import React, { useEffect, useState } from 'react';

function ResendButton({
  isButtonDisabled,
  setIsButtonDisabled,
  countdown,
  setCountdown,
  handleSendOTP,
}) {
  const [buttonText, setButtonText] = useState('Verify');

  useEffect(() => {
    let timer;
    if (isButtonDisabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setButtonText('Resend code');
      setIsButtonDisabled(false);
    }
    return () => clearInterval(timer);
  }, [isButtonDisabled, countdown, setCountdown, setIsButtonDisabled]);

  return (
    <>
      {isButtonDisabled ? (
        <button
          type="button"
          style={{
            fontFamily: 'Lato',
            fontSize: '14px',
            padding: '8px 16px',
          }}
          className="btn btn-secondary btn-circle resend-button"
          disabled
        >
          Resend code in {countdown}s
        </button>
      ) : (
        <button
          type="button"
          style={{
            borderRadius: '12px',
            padding: '8px 16px',
            fontFamily: 'Lato',
            fontSize: '14px',
            border: 'none',

            whiteSpace: 'nowrap',
          }}
          className="btn btn-dark btn-circle verify-button"
          onClick={handleSendOTP}
        >
          {buttonText}
        </button>
      )}
    </>
  );
}

export default ResendButton;
