import React from 'react';

function Header() {
  return (
    <h1
      style={{
        flex: '1 0 0',
        color: 'var(--Grayscale-900, #171717)',
        fontFamily: 'Lato',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '600',
        lineHeight: '140%',
      }}
    >
      Quick actions
    </h1>
  );
}

export default Header;
