import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { FaCheckCircle, FaExclamationCircle, FaSpinner } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../styles/customButton.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import BokraLogo from '../assests/img/BokraLogo.png';
import commercialRegistryIcon from '../assests/img/icon.svg';

const DashboardDocumentsUploading = () => {
  const [isLoading, setIsLoading] = useState({ commercialRegistryFile: false, taxIdFile: false });
  const [isSuccess, setIsSuccess] = useState({ commercialRegistryFile: false, taxIdFile: false });
  const [uploadError, setUploadError] = useState({ commercialRegistryFile: '', taxIdFile: '' });
  const [uploadedFiles, setUploadedFiles] = useState({ commercialRegistryFile: null, taxIdFile: null });
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  const SUPPORTED_FORMATS = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];


  const validationSchema = Yup.object({
    commercialRegistryFile: Yup.mixed().required('Commercial Registry is required')
    .test(
      'fileFormat',
      'Unsupported file format. Only PNG, PDF, JPG are allowed.',
      value => value && SUPPORTED_FORMATS.includes(value.type)
    ),
    taxIdFile: Yup.mixed().required('Tax ID is required')
    .test(
      'fileFormat',
      'Unsupported file format. Only PNG, PDF, JPG are allowed.',
      value => value && SUPPORTED_FORMATS.includes(value.type)
    ),
  });

  const commonStyles = {
        display: 'flex',
        color: '#FFF',
      };

  const formik = useFormik({
    initialValues: {
      commercialRegistryFile: null,
      taxIdFile: null,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading({ commercialRegistryFile: true, taxIdFile: true }); 
      const formData = new FormData();
      formData.append('commercialRegistry', values.commercialRegistryFile);
      formData.append('taxId', values.taxIdFile);

      try {
        await axios.post(`${process.env.REACT_APP_SERVER_URL}/uploadDocuments`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        setMessage('Files uploaded successfully');
        setIsSuccess({ commercialRegistryFile: true, taxIdFile: true });
        navigate('/dashboard/companyInformation');
      } catch (error) {
        console.error(error);
        setUploadError({ commercialRegistryFile: 'Error uploading Commercial Registry', taxIdFile: 'Error uploading Tax ID' });
        setIsSuccess({ commercialRegistryFile: false, taxIdFile: false });
      } finally {
        setIsLoading({ commercialRegistryFile: false, taxIdFile: false });
        setSubmitting(false);
      }
    },
  });

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
      setIsLoading((prev) => ({ ...prev, [name]: true }));
      setIsSuccess((prev) => ({ ...prev, [name]: false }));
      setUploadError((prev) => ({ ...prev, [name]: '' }));

      formik.setFieldValue(name, file);

      uploadFile(name, file);
    }
  };

  const uploadFile = async (name, file) => {
    try {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          const success = Math.random() > 0; 
          if (success) resolve();
          else reject(new Error(`Failed to upload ${name}`));
        }, 2000); 
      });

      setIsLoading((prev) => ({ ...prev, [name]: false }));
      setIsSuccess((prev) => ({ ...prev, [name]: true }));
      setUploadedFiles((prevFiles) => ({ ...prevFiles, [name]: file.name }));
    } catch (error) {
      setIsLoading((prev) => ({ ...prev, [name]: false }));
      setUploadError((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div
        style={{
          display: 'flex',
         // width: '1440px',
          height: '13vh',
          padding: 'var(--Line-height-12-18, 18px) var(--6, 32px)',
          alignItems: 'center',
          gap: '12px',
          flexShrink: '0',
          borderBottom: '1px solid var(--Border-1, #E8E8E8)',
          background: 'var(--Background-Primary, #FFF)',
        }}
      >
        <img
          style={{ display: 'flex',  width: '7.8vw',
            height: '3.9vh', justifyContent: 'center', alignItems: 'center' }}
          src={BokraLogo}
          alt="bokralogo"
          className="img-fluid"
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'row',height: '87vh',width:'100vw' }}>
      <div
          style={{
            ...commonStyles,
            //width: '720px',
            flex:1,
            //height: '87vh',
            padding: '40px',
            background: '#FAFAFA',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.06)',
            borderTop: '1px #E4E4E7 solid',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            gap: '24px',
          }}
        >
          <div
            style={{
              ...commonStyles,
              alignSelf: 'stretch',
              height: '282px',
              flexDirection: 'column',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              gap: '24px',
            }}
          >
            <div
              style={{
                ...commonStyles,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: '4px',
              }}
            >
              <div
                style={{
                  color: '#09090B',
                  fontSize: '24px',
                  fontFamily: 'Lato',
                  fontWeight: 500,
                  lineHeight: '36px',
                  wordWrap: 'break-word',
                }}
              >
                Set up your company account
              </div>
            </div>
            <div
              style={{
                ...commonStyles,
                alignSelf: 'stretch',
                height: '222px',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: '24px',
              }}
            >
              <div
                style={{
                  ...commonStyles,
                  borderRadius: '99px',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '24px',
                }}
              >
                <div
                  style={{
                    ...commonStyles,
                    width: '40px',
                    height: '40px',
                    padding: '5.71px',
                    background: 'white',
                    borderRadius: '1428.57px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '11.43px',
                  }}
                >
                  <div
                    style={{
                      width: '29px',
                      height: '29px',
                      textAlign: 'center',
                      color: '#572875',
                      fontSize: '20px',
                      fontFamily: 'Lato',
                      fontWeight: 500,
                      lineHeight: '20px',
                      wordWrap: 'break-word',
                    }}
                  >
                    1
                  </div>
                </div>
                <div
                  style={{
                    ...commonStyles,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '4px',
                  }}
                >
                  <div
                    style={{
                      color: '#09090B',
                      fontSize: '20px',
                      fontFamily: 'Lato',
                      fontWeight: 500,
                      lineHeight: '28px',
                      wordWrap: 'break-word',
                    }}
                  >
                    Company Information
                  </div>
                  <div
                    style={{
                      color: '#52525B',
                      fontSize: '16px',
                      fontFamily: 'Lato',
                      fontWeight: 400,
                      lineHeight: '25.60px',
                      wordWrap: 'break-word',
                    }}
                  >
                    Let’s get started. Tell about you and your company.
                  </div>
                </div>
              </div>

              <div
                style={{
                  ...commonStyles,
                  alignSelf: 'stretch',
                  borderRadius: '99px',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '24px',
                }}
              >
                <div
                  style={{
                    ...commonStyles,
                    width: '40px',
                    height: '40px',
                    padding: '5.71px',
                    background: 'white',
                    borderRadius: '1428.57px',
                    border: '2px #572875 solid',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '11.43px',
                  }}
                >
                  <div
                    style={{
                      width: '29px',
                      height: '29px',
                      textAlign: 'center',
                      color: '#572875',
                      fontSize: '18px',
                      fontFamily: 'Lato',
                      fontWeight: 400,
                      lineHeight: '25.20px',
                      wordWrap: 'break-word',
                    }}
                  >
                    2
                  </div>
                </div>
                <div
                  style={{
                    ...commonStyles,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '4px',
                  }}
                >
                  <div
                    style={{
                      color: '#52525B',
                      fontSize: '20px',
                      fontFamily: 'Lato',
                      fontWeight: 500,
                      lineHeight: '28px',
                      wordWrap: 'break-word',
                    }}
                  >
                    Company Verifying
                  </div>
                  <div
                    style={{
                      color: '#52525B',
                      fontSize: '16px',
                      fontFamily: 'Lato',
                      fontWeight: 400,
                      lineHeight: '25.60px',
                      wordWrap: 'break-word',
                    }}
                  >
                    You can just draft a name now and change it later.
                  </div>
                </div>
              </div>

              {/** Step 3 */}
              <div
                style={{
                  ...commonStyles,
                  alignSelf: 'stretch',
                  borderRadius: '99px',
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                  gap: '24px',
                }}
              >
                <div
                  style={{
                    ...commonStyles,
                    width: '40px',
                    height: '40px',
                    padding: '5.71px',
                    background: 'white',
                    borderRadius: '1428.57px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '11.43px',
                  }}
                >
                  <div
                    style={{
                      width: '29px',
                      height: '29px',
                      textAlign: 'center',
                      color: '#572875',
                      fontSize: '20px',
                      fontFamily: 'Lato',
                      fontWeight: 500,
                      lineHeight: '20px',
                      wordWrap: 'break-word',
                    }}
                  >
                    3
                  </div>
                </div>
                <div
                  style={{
                    ...commonStyles,
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                    gap: '4px',
                  }}
                >
                  <div
                    style={{
                      color: '#52525B',
                      fontSize: '20px',
                      fontFamily: 'Lato',
                      fontWeight: 500,
                      lineHeight: '28px',
                      wordWrap: 'break-word',
                    }}
                  >
                    Office Address
                  </div>
                  <div
                    style={{
                      color: '#52525B',
                      fontSize: '16px',
                      fontFamily: 'Lato',
                      fontWeight: 400,
                      lineHeight: '25.60px',
                      wordWrap: 'break-word',
                    }}
                  >
                    You can just draft a name now and change it later.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flex:1,
           // width: '720px',
            //height: '915px',
            padding: 'var(--7, 40px)',
            flexDirection: 'column',
            alignItems: 'flex-start',
            borderTop: '1px solid var(--Greyscale-100, #E4E4E7)',
            borderLeft: '1px solid var(--Greyscale-100, #E4E4E7)',
            boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.06)',
          }}
        >
          <div className="w-75">
            <h1 style={{ fontSize: '32px', fontFamily: 'Lato', fontWeight: 'bold', marginBottom: '12px' }}>
              Verify Your Company Identity
            </h1>
            <p style={{ fontSize: '18px', fontFamily: 'Lato', marginBottom: '32px' }}>
              Provide the info below to start your account
            </p>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group mb-4 input-container">
                <div className="custom-file-input" style={{ position: 'relative' }}>
                  <input
                    type="file"
                    className="form-control-file"
                    name="commercialRegistryFile"
                    id="commercialRegistryFile"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  <label
                    style={{ marginBottom: '16px' }}
                    htmlFor="commercialRegistryFile"
                    className="upload-btn"
                  >
                    <img src={commercialRegistryIcon} alt="Commercial Registry Icon" />
                    <span
                      style={{
                        marginLeft: '16px',
                        cursor: 'pointer',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#121212',
                      }}
                    >
                      Upload Commercial Registry
                    </span>
                  </label>
                  {formik.values.commercialRegistryFile && <p className="file-name"></p>}
                  {formik.touched.commercialRegistryFile && formik.errors.commercialRegistryFile && (
                    <div className="text-danger">{formik.errors.commercialRegistryFile}</div>
                  )}

                  {isLoading.commercialRegistryFile && (
                    <FaSpinner
                      className="spinner"
                      style={{
                        position: 'absolute',
                        right: '20px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: '#888',
                        fontSize: '24px',
                        animation: 'spin 1s linear infinite',
                      }}
                    />
                  )}

                  {isSuccess.commercialRegistryFile && !isLoading.commercialRegistryFile && (
                    <FaCheckCircle
                      style={{
                        position: 'absolute',
                        right: '20px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: '#28a745',
                        fontSize: '24px',
                      }}
                    />
                  )}

                  {uploadError.commercialRegistryFile && (
                    <div
                      style={{
                        position: 'absolute',
                        right: '20px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: '#dc3545',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <FaExclamationCircle style={{ marginRight: '8px' }} />
                      {uploadError.commercialRegistryFile}
                    </div>
                  )}
                </div>
              </div>

              <div className="form-group mb-4 input-container">
                <div className="custom-file-input" style={{ position: 'relative' }}>
                  <input
                    type="file"
                    className="form-control-file"
                    name="taxIdFile"
                    id="taxIdFile"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  <label
                    style={{ marginBottom: '16px' }}
                    htmlFor="taxIdFile"
                    className="upload-btn"
                  >
                     {!formik.values.taxIdFile && !uploadError.taxIdFile && !isSuccess.taxIdFile && (
                    <img src={commercialRegistryIcon} alt="Tax ID Icon" />
                  )}
                    <span
                      style={{
                        marginLeft: '16px',
                        cursor: 'pointer',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#121212',
                      }}
                    >
                      Upload Tax ID
                    </span>
                  </label>
                  {formik.values.taxIdFile && <p className="file-name"></p>}
                  {formik.touched.taxIdFile && formik.errors.taxIdFile && (
                    <div className="text-danger">{formik.errors.taxIdFile}</div>
                  )}

                  {isLoading.taxIdFile && (
                    <FaSpinner
                      className="spinner"
                      style={{
                        position: 'absolute',
                        right: '20px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: '#888',
                        fontSize: '24px',
                        animation: 'spin 1s linear infinite',
                      }}
                    />
                  )}

                  {isSuccess.taxIdFile && !isLoading.taxIdFile && (
                    <FaCheckCircle
                      style={{
                        position: 'absolute',
                        right: '20px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: '#28a745',
                        fontSize: '24px',
                      }}
                    />
                  )}

                  {uploadError.taxIdFile && (
                    <div
                      style={{
                        position: 'absolute',
                        right: '20px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: '#dc3545',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <FaExclamationCircle style={{ marginRight: '8px' }} />
                      {uploadError.taxIdFile}
                    </div>
                  )}
                </div>
              </div>

              <button
                style={{
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '140%',
                  marginBottom: '16px',
                }}
                type="submit"
                className="btn btn-dark btn-custom-size-continue w-100"
                disabled={formik.isSubmitting || isLoading.commercialRegistryFile || isLoading.taxIdFile}
              >
                {(isLoading.commercialRegistryFile || isLoading.taxIdFile) ? (
                  <FaSpinner className="spinner" style={{ fontSize: '24px', animation: 'spin 1s linear infinite' }} />
                ) : (
                  'Save and Continue'
                )}
              </button>
            </form>

            {isSuccess.commercialRegistryFile && isSuccess.taxIdFile && (
              <div className="text-center">
                <FaCheckCircle style={{ fontSize: '24px', color: '#28a745', marginTop: '10px' }} />
                <p className="text-success">All files uploaded successfully!</p>
              </div>
            )}
            <p className="text-center mb-3">{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardDocumentsUploading;
