import React, { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import RightContainerFormHeader from '../../components/rightContainerFormHeader';
import { toast } from 'react-toastify';
import '../../../../styles/customToast.css';
import InputField from '../../../loans/components/inputfield';
import tick from '../../../../assests/img/tick.png';



function SignUpRightContainer() {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [countdown, setCountdown] = useState(60);
  const navigate = useNavigate();

  useEffect(() => {
    let timer;
    if (isButtonDisabled && countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, 1000);
    } else if (countdown === 0) {
      setIsButtonDisabled(false);
      setCountdown(60);
    }
    return () => clearInterval(timer);
  }, [isButtonDisabled, countdown]);

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .required('First Name is required')
      .min(2, 'First Name must be at least 2 characters')
      .max(15, 'First Name cannot be more than 15 characters')
      .matches(/^[A-Za-z\s]+$/, 'First Name should only contain letters'),
    lastName: Yup.string()
      .required('Last Name is required')
      .min(5, 'Last Name must be at least 5 characters')
      .max(15, 'Last Name cannot be more than 15 characters')
      .matches(/^[A-Za-z\s]+$/, 'Last Name should only contain letters'),
    phoneNumber: Yup.string()
      .required('Phone Number is required')
      .matches(/^\d{11}$/, 'Phone Number must be exactly 11 digits and contain only numbers'),
    otp: Yup.string().required('OTP is required'),
  });

  const handleSendOTP = async (phoneNumber, setFieldError) => {
    if (!phoneNumber) return;

    const formattedPhoneNumber = `+2${phoneNumber}`;
    setIsButtonDisabled(true);

    try {
      await axios.post(`${process.env.REACT_APP_SERVER_URL}/registration/sendOTP`, {
        phoneNumber: formattedPhoneNumber,
      });

      toast.success('Verification code sent to your phone number', {
        autoClose: 3000,
        position: 'bottom-center',
        className: 'custom-toast',
        bodyClassName: 'custom-body-toast',
        icon: <div className="custom-toast-icon"><img src={tick} alt="Success" style={{ width: '30px', height: '30px' }} /></div>,
        progressClassName: 'custom-toast-progress',
        style: { width: '425px' },
      });
    } catch (error) {
      setFieldError('phoneNumber', `Error sending OTP: ${error.message}`);
      setIsButtonDisabled(false);
    }
  };

  const onSubmit = async (values, { setSubmitting, setFieldError }) => {
    const formattedPhoneNumber = `+2${values.phoneNumber}`;
    setSubmitting(true);

    try {
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/registration/create`, {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: formattedPhoneNumber,
        email,
        otp: values.otp,
      });

      localStorage.setItem('token', response.data.token); 

      navigate(`/companyDetails`);
    } catch (error) {
      setFieldError('general', `Error creating account: ${error}`);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="col-md-6 d-flex flex-column justify-content-center align-items-center style">
      <div className="w-75">
        <RightContainerFormHeader
          title={"Let's Create Your Account"}
          subtitle={'Provide the info below to start your account.'}
        />
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            phoneNumber: '',
            otp: '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ errors, touched, isSubmitting, values, setFieldError }) => (
            <Form>
              <div style={{display:'flex',flexDirection:'column',gap:'16px'}}>
              <InputField
                name="firstName"
                type="text"
                label="First name"
                errors={errors.firstName}
                touched={touched.firstName}
                
              />

              <InputField
                name="lastName"
                type="text"
                label="Last name"
                errors={errors.lastName}
                touched={touched.lastName}
              />

              <InputField
                name="phoneNumber"
                type="text"
                label="Phone Number"
                errors={errors.phoneNumber}
                touched={touched.phoneNumber}
                resendButtonProps={{
                  isButtonDisabled: isButtonDisabled,
                  setIsButtonDisabled: setIsButtonDisabled,
                  countdown: countdown,
                  setCountdown: setCountdown,
                  handleSendOTP: () => handleSendOTP(values.phoneNumber, setFieldError),
                }}
              />

              <InputField
                name="otp"
                type="text"
                label="Enter verification code"
                errors={errors.otp}
                touched={touched.otp}
              />

              <button
                style={{
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontWeight: '600',
                }}
                type="submit"
                className="btn btn-dark btn-custom-size-continue w-100"
                disabled={isSubmitting}
              >
                Continue to set up your company
              </button>
              </div>
              {errors.general && (
                <p className="text-center text-danger mb-3">{errors.general}</p>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default SignUpRightContainer;