import React, { useState, useEffect } from 'react';
import LuggageIcon from '../../../assests/img/LuggageIcon.png';
import BankIcon from '../../../assests/img/BankIcon.png';
import FirstModal from './modals/FirstModal';
import { ToastProvider } from '../toastProvider';

function ManagePlan() {
  const [Calendar, setCalendar] = useState('');

  const [isModalOpen, setIsModalOpen] = useState({
    first: false,
  });

  const closeModal = () => {
    setIsModalOpen({
      first: false,
    });
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isModalOpen]);

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
          width:'100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            height: '219px',
            width: '100%',
            padding: '16px',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexShrink: '0',
            alignSelf: 'stretch',
            borderRadius: '12px',
            border: '1px solid var(--Border-1, #EAEDF2)',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '8px',
              alignSelf: 'stretch',
            }}
          >
            <h1
              style={{
                color: 'var(--Greyscale-900, #09090B)',
                fontFamily: 'Lato',
                fontSize: '20px',
                fontWeight: '600',
                lineHeight: '140%',
                margin: '0',
              }}
            >
              Upcoming Maturity
            </h1>
            <h1
              style={{
                color: '#353535',
                fontFamily: 'Lato',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '140%',
                margin: '0',
              }}
            >
              Some assets are going to liquidated soon
            </h1>
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '78px',
              padding: 'var(--2, 8px)',
              flexDirection: 'column',
              alignItems: 'flex-start',
              gap: '4px',
              alignSelf: 'stretch',
              borderRadius: '8px',
              background: '#F8FAFB',
            }}
            id="hoverText"
          >
            <span
              style={{
                alignSelf: 'stretch',
                color: 'var(--UI-Text-Secondary, #323741)',
                fontFamily: 'Lato',
                fontSize: '12px',
                fontWeight: '400',
                lineHeight: '140%',
              }}
              title="hover text"
            >
              Fixed income & Money market{' '}
            </span>
            <span
              style={{
                color: 'var(--UI-Text-Primary, #0F0F10)',
                fontFamily: 'Lato',
                fontSize: '20px',
                fontWeight: '500',
                lineHeight: '120%',
              }}
              title="hover text"
            >
              EGP60,493{' '}
            </span>
            <span
              style={{
                color: 'var(--UI-Text-Primary, #757575)',
                fontFamily: 'Inter',
                fontSize: '9px',
                fontWeight: '400',
                lineHeight: '140%',
              }}
              title="hover text"
            >
              Total Returns : EGP7,997.86{' '}
            </span>
          </div>
          <div></div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              gap: '4px',
            }}
          >
            <img
              style={{
                display: 'flex',
                width: '20px',
                height: '20px',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: '4px',
                borderRadius: '815.894px',
                background: 'var(--Secondary-0, #F5F3FF)',
              }}
              src={LuggageIcon}
              alt="Verification"
              className="img-fluid"
            />
            <span
              style={{
                color: 'var(--UI-Text-Primary, #0F0F10)',
                fontFamily: 'Lato',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '140%',
              }}
            >
              Next Tuesday 22 May
            </span>
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            height: '219px',
            width: '100%',
            padding: '16px',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flexShrink: '0',
            flexGrow: 1,
            alignSelf: 'stretch',
            borderRadius: '12px',
            border: '1px solid var(--Border-1, #EAEDF2)',
          }}
        >
          <h1
            style={{
              color: 'var(--Greyscale-900, #09090B)',
              fontFamily: 'Lato',
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: '140%',
              margin: '0',
            }}
          >
            Liquidity plan
          </h1>
          <h1
            style={{
              color: '#353535',
              fontFamily: 'Lato',
              fontSize: '14px',
              fontWeight: '400',
              lineHeight: '140%',
              margin: '0',
            }}
          >
            Understanding your liquidity needs allows us to offer you the
            optimal treasury investment plan.
          </h1>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '146px',
                height: '33px',
                padding: '3.264px 14px 3.264px 3.264px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: ' 6.527px',
                borderRadius: '80.773px',
                border: 'var(--Letter-spacing-Letter-spacing, 0.816px) #E4E4E7',
                background: '#FFF',
              }}
            >
              <img
                style={{
                  display: 'flex',
                  width: '20px',
                  height: '20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                src={LuggageIcon}
                alt="Submit Request"
                className="img-fluid"
              />
              <span
                style={{
                  width: '96px',
                  overflow: 'hidden',
                  color: '#09090B',
                  textAlign: 'center',
                  textOverflow: 'ellipsis',
                  fontFamily: 'Lato',
                  fontSize: '10.5px',
                  fontWeight: '400',
                  lineHeight: '155%',
                }}
              >
                From 25th to the 1st
              </span>
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '146px',
                height: '33px',
                padding: '3.264px 14px 3.264px 3.264px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: ' 6.527px',
                borderRadius: '80.773px',
                border: 'var(--Letter-spacing-Letter-spacing, 0.816px) #E4E4E7',
                background: '#FFF',
              }}
            >
              <img
                style={{
                  display: 'flex',
                  width: '20px',
                  height: '20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                src={BankIcon}
                alt="Submit Request"
                className="img-fluid"
              />
              <span
                style={{
                  width: '96px',
                  overflow: 'hidden',
                  color: '#09090B',
                  textAlign: 'center',
                  textOverflow: 'ellipsis',
                  fontFamily: 'Lato',
                  fontSize: '10.5px',
                  fontWeight: '400',
                  lineHeight: '155%',
                }}
              >
                EGP200,000
              </span>
            </div>
          </div>

          <div>
            <button
              style={{
                display: 'flex',
                width: '140px',
                height: '32px',
                padding: '8px 16px',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '8px',
                flex: '1 0 0',
                borderRadius: '12px',
                border: '1px solid var(--Color-Gray-200, #E8E8E8)',
                background: '#1E1E20',
                cursor: 'pointer',
                boxSizing: 'border-box',
                border: 'none', // Add this line
              }}
              onClick={() => setIsModalOpen({ ...isModalOpen, first: true })}
            >
              <span
                style={{
                  color: '#FFF',
                  textAlign: 'center',
                  fontFamily: 'Lato',
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '19.6px',
                  boxShadow: 'none',
                  border: 'none',
                  outline: 'none',
                }}
              >
                Manage plan
              </span>
            </button>
          </div>

          <div></div>
        </div>
      </div>
      
        <FirstModal closeModal={closeModal} isModalOpen={isModalOpen.first} />
     
    </>
  );
}

export default ManagePlan;
