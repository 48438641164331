import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation, Navigate } from 'react-router-dom';
import '../styles/customButton.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SubmitIcon from '../assests/img/SubmitIcon.png';
import NotificationsIcon from '../assests/img/NotificationsIcon.png';
import PlusIcon from '../assests/img/PlusIcon.png';
import LoadingIcon from '../assests/img/LoadingIcon.png';


const Dashboard = () => {
  const [companyDetails, setCompanyDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true); // Add a loading state
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const location = useLocation();
  const { companyId } = location.state || {}; 

  
  

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const infoResponse = await axios.get(`${process.env.REACT_APP_SERVER_URL}/companyDetails?companyId=${companyId}`);

        console.log('Company Details:', infoResponse.data);
        setCompanyDetails(infoResponse.data);
      } catch (error) {
        console.error('Error fetching company data:', error);
      } finally {
        setIsLoading(false); // Stop loading once data is fetched
      }
    };

    fetchCompanyData();
  }, []);

  const handleSetup = async () => {
    try {
      navigate('/dashboard/setupCompany', {
        state: { companyId },  
      });
      setMessage('Company details added successfully');
    } catch (error) {
      console.error(error);
      setMessage(`An error occurred while submitting company details. ${error}`);
    }
  };

  if (isLoading) {
    return <div>Loading...</div>; // Display a loading state while fetching data
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
      }}
      className="dashboard"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <div
          style={{
            width: '100%',
            height: '80px',
            display: 'flex',
            gap: '12px',
            padding: '18px',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderBottom: '1px solid #E8E8E8',
          }}
        >
          <button
            style={{
              display: 'flex',
              height: '44px',
              width: '171px',
              padding: '8px var(--Layout-x4, 16px)',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 'var(--Layout-x2, 8px)',
              borderRadius: '12px',
              background: '#F5F5F5',
              border: 'none',
              flexDirection: 'row',
              marginRight: '8px',
            }}
          >
            <img
              style={{
                display: 'flex',
                width: '20px',
                height: '20px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              src={SubmitIcon}
              alt="Submit Request"
              className="img-fluid"
            />
            <h1
              style={{
                color: '#121212',
                textAlign: 'center',
                fontFamily: 'Lato',
                fontSize: '16px',
                fontWeight: '400',
                margin: '0',
              }}
            >
              Submit Request
            </h1>
          </button>
          <button
            style={{
              display: 'flex',
              height: '44px',
              width: '44px',
              padding: '8px',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '8px',
              borderRadius: '8px',
              background: '#F5F5F5',
              border: 'none',
            }}
          >
            <img
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '4px',
                gap: '4px',
              }}
              src={NotificationsIcon}
              alt="Submit Request"
              className="img-fluid"
            />
          </button>
        </div>

        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '28px',
            alignItems: 'flex-start',
            alignContent: 'flex-start',
            gap: '24px',
            flexDirection: 'column',
            flexWrap: 'wrap',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '34px',
              padding: 'var(--spacing-2, 2px) 0px',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexShrink: '0',
              flexDirection: 'row',
            }}
          >
            <h1
              style={{
                width: '105px',
                height: '34px',
                fontFamily: 'Lato',
                fontSize: '24px',
                fontWeight: '700',
              }}
            >
              Overview
            </h1>
            <button
              style={{
                display: 'flex',
                height: '29px',
                width: '125px',
                padding: 'var(--4, 4px) var(--8, 8px)',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '4px',
                borderRadius: '8px',
                background: '#1E1E20',
                border: 'none',
                flexDirection: 'row',
              }}
            >
              <img
                style={{
                  display: 'flex',
                  width: '16px',
                  height: '16px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                src={PlusIcon}
                alt="Plus"
                className="img-fluid"
              />
              <h1
                style={{
                  color: '#FFFFFF',
                  width: '100px',
                  textAlign: 'center',
                  fontFamily: 'Inter',
                  fontSize: '13px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  margin: '0',
                }}
              >
                Export Report
              </h1>
            </button>
          </div>

          {/* Conditionally render the setup div only if setupComplete is false */}
          {companyDetails && !companyDetails.setupComplete && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '56px',
                padding: '12px 24px',
                alignItems: 'center',
                gap: '24px 20px',
                flexDirection: 'row',
                flexShrink: '0',
                borderRadius: '12px',
                border: '1px solid #572875',
                justifyContent: 'space-between',
              }}
            >
              <h1
                style={{
                  color: '#3F3F46',
                  textAlign: 'center',
                  fontFamily: 'Lato',
                  fontSize: '18px',
                  fontWeight: '400',
                  lineHeight: '20px',
                  margin: '0',
                }}
              >
                Set up your company account to start using Bokra benefits
              </h1>
              <div
                style={{
                  display: 'flex',
                  width: '291px',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  gap: '24px',
                  flexShrink: '0',
                }}
              >
                <button
                  style={{
                    display: 'flex',
                    width: '188px',
                    height: '32px',
                    padding: '8px var(--Layout-x4, 16px)',
                    justifyContent: 'center',
                    alignItems: 'center',
                    border: 'none',
                    borderRadius: '12px',
                    background: '#572875',
                    gap: '8px',
                    flexShrink: '0',
                  }}
                  onClick={handleSetup}
                >
                  <h1
                    style={{
                      color: '#FFF',
                      textAlign: 'center',
                      fontFamily: 'Lato',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    Set up
                  </h1>
                </button>
              </div>
            </div>
          )}

          {/* Dashboard Content */}
          <div
            style={{
              width: '100%',
              height: '342px',
              border: '1px solid #E8E8E8',
              borderRadius: '12px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                width: '292px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  padding: '16px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '24px',
                  width: '292px',
                  height: '114px',
                  borderBottom: '1px solid #E8E8E8',
                }}
              >
                <h1
                  style={{
                    color: '#1F2023',
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '140%',
                    margin: '0',
                  }}
                >
                  Pension Balance
                </h1>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '2px',
                    alignSelf: 'stretch',
                  }}
                >
                  <h1
                    style={{
                      color: '#121212',
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      fontWeight: '600',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    EGP0.00
                  </h1>
                  <h1
                    style={{
                      color: '#A5A5A5',
                      fontFamily: 'Lato',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    Returns 0.00
                  </h1>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '16px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '24px',
                  width: '292px',
                  height: '114px',
                  borderBottom: '1px solid #E8E8E8',
                  background: '#F8FAFB',
                }}
              >
                <h1
                  style={{
                    color: '#1F2023',
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '140%',
                    margin: '0',
                  }}
                >
                  Treasury Balance
                </h1>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '2px',
                    alignSelf: 'stretch',
                  }}
                >
                  <h1
                    style={{
                      color: '#121212',
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      fontWeight: '600',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    EGP0.00
                  </h1>
                  <h1
                    style={{
                      color: '#A5A5A5',
                      fontFamily: 'Lato',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    Returns 0.00
                  </h1>
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  padding: '16px',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  gap: '24px',
                  width: '292px',
                  height: '114px',
                  borderBottom: '1px solid #E8E8E8',
                }}
              >
                <h1
                  style={{
                    color: '#1F2023',
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    fontWeight: '600',
                    lineHeight: '140%',
                    margin: '0',
                  }}
                >
                  Upcoming Maturity
                </h1>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '2px',
                    alignSelf: 'stretch',
                  }}
                >
                  <h1
                    style={{
                      color: '#121212',
                      fontFamily: 'Lato',
                      fontSize: '20px',
                      fontWeight: '600',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    EGP0.00
                  </h1>
                  <h1
                    style={{
                      color: '#A5A5A5',
                      fontFamily: 'Lato',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '140%',
                      margin: '0',
                    }}
                  >
                    No upcoming maturity
                  </h1>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                width: '520px',
                padding: '14px',
                alignItems: 'flex-start',
                alignContent: 'flex-start',
                gap: '12px 147px',
                flexWrap: 'wrap',
                borderLeft: '1px solid #E8E8E8',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  flex: '1 0 0',
                }}
              >
                <h1
                  style={{
                    width: '232px',
                    color: '#121212',
                    fontFamily: 'Inter',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '24px',
                  }}
                >
                  Treasury Balance
                </h1>
                <select
                  style={{
                    display: 'flex',
                    padding: '4px 8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '4px',
                    height: '30px',
                    width: '82px',
                    borderRadius:'8px',
                    fontFamily:'Lato',
                    fontSize:'16px',
                    fontStyle:'normal',
                    fontWeight:'400',
                    lineHeight:'140%',
                  }}
                  type="lastName"
                  className="form-select"
                  id="exampleInputLastName1"
                  placeholder="Company Sector"
                >
                  <option value="" >Today</option>
                  <option value="Technology">Technology</option>
                  <option value="Finance">Finance</option>
                  <option value="Healthcare">Healthcare</option>
                </select>
              </div>

              <canvas
                id="myChart"
                style={{
                  width: '100%',
                  maxWidth: '480px',
                  height: '250px',
                }}
              ></canvas>
            </div>
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '428px',
              padding: '16px',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
              borderRadius: '12px',
              border: '1px solid #E8E8E8',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <h1
                style={{
                  flex: '1 0 0',
                  height: '24px',
                  textAlign: 'left',
                  color: '#171717',
                  fontFamily: 'Inter',
                  fontSize: '16px',
                  fontWeight: '500',
                  lineHeight: '24px',
                  margin: '0',
                }}
              >
                Recent Transactions
              </h1>
              <img
                style={{
                  display: 'flex',
                  width: '24px',
                  height: '24px',
                  alignSelf: 'stretch',
                  justifyContent: 'space-between',
                  alignItems: 'flex-left',
                }}
                src={LoadingIcon}
                alt="al"
                className="img-fluid"
              />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignSelf: 'flex-start',
                width: '100%',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  alignSelf: 'flex-start',
                  width: '100%',
                  height: '36px',
                  padding: '8px 12px',
                  gap: '8px',
                  borderRadius: '8px 0px 0px 0px',
                  background: '#F8FAFB',
                }}
              >
                <h1
                  style={{
                    flex: '1 0 0',
                    display: 'flex',
                    width: '100%',
                    height: '20px',
                    alignSelf: 'flex-start',
                    color: '#1F2023',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    margin: '0',
                  }}
                >
                  Transactions
                </h1>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  alignSelf: 'flex-start',
                  width: '100%',
                  height: '36px',
                  padding: '8px 12px',
                  gap: '8px',
                  borderRadius: '8px 0px 0px 0px',
                  background: '#F8FAFB',
                }}
              >
                <h1
                  style={{
                    flex: '1 0 0',
                    display: 'flex',
                    width: '100%',
                    height: '20px',
                    alignSelf: 'flex-start',
                    color: '#1F2023',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    margin: '0',
                  }}
                >
                  Date
                </h1>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  alignSelf: 'flex-start',
                  width: '100%',
                  height: '36px',
                  padding: '8px 12px',
                  gap: '8px',
                  borderRadius: '8px 0px 0px 0px',
                  background: '#F8FAFB',
                }}
              >
                <h1
                  style={{
                    flex: '1 0 0',
                    display: 'flex',
                    width: '100%',
                    height: '20px',
                    alignSelf: 'flex-start',
                    color: '#1F2023',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    margin: '0',
                  }}
                >
                  Type
                </h1>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  alignSelf: 'flex-start',
                  width: '280px',
                  height: '36px',
                  padding: '8px 12px',
                  gap: '8px',
                  borderRadius: '8px 0px 0px 0px',
                  background: '#F8FAFB',
                }}
              >
                <h1
                  style={{
                    flex: '1 0 0',
                    display: 'flex',
                    width: '280px',
                    height: '20px',
                    alignSelf: 'flex-start',
                    color: '#1F2023',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontWeight: '400',
                    lineHeight: '20px',
                    margin: '0',
                  }}
                >
                  Status
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
