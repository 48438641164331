import React from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import '../styles/customButton.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import landingImage from '../assests/img/landingImage.png';

const validationSchema = Yup.object({
  Government: Yup.string().required('This field is required'),
  City: Yup.string().required('This field is required'),
  Region: Yup.string().required('This field is required'),
  StreetCompound: Yup.string()
    .required('This field is required')
    .max(30, 'Street/Compount cannot exceed 30 characters')
    .matches(
      /^[a-zA-Z0-9\s,-]+$/,
      'Street/Compound should not contain special characters'
    ),
  Building: Yup.string()
    .required('This field is required')
    .max(20, 'Building cannot exceed 20 characters')
    .matches(/^[a-zA-Z0-9\s,-]+$/, 'Building should not contain special characters'),
});

const CompanyInformation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { companyId } = location.state || {}; 


  const handleSubmit = async (values, { setSubmitting, setStatus }) => {
    try {
      const companyData = {
        ...values,
        setupComplete: true,
      };
  
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/companyInformation`,
        companyData
      );
      
  
      setStatus({ success: 'Company Information added successfully, setup complete.' });
      navigate('/dashboard');
    } catch (error) {
      console.error(error);
      setStatus({
        error: 'An error occurred while submitting company information.',
      });
    } finally {
      setSubmitting(false);
    }
  };
  

  return (
    <div className="container">
      <div className="row vh-100">
        <div className="col-md-6 d-flex justify-content-center align-items-center bg-light">
          <img src={landingImage} alt="Verification" className="img-fluid" />
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-center align-items-center style">
          <div className="w-75">
            <h1
              style={{
                fontSize: '32px',
                fontFamily: 'Lato',
                fontWeight: 'bold',
                marginBottom: '12px',
              }}
            >
              Company Information
            </h1>
            <p
              style={{
                fontSize: '18px',
                fontFamily: 'Lato',
                marginBottom: '32px',
              }}
            >
              Provide the info below to start your account
            </p>

            <Formik
              initialValues={{
                Government: '',
                City: '',
                Region: '',
                StreetCompound: '',
                Building: '',
                Instructions: '',
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ isSubmitting, status }) => (
                <Form>
                  <div
                    className="d-flex align-items-center"
                    style={{ flexDirection: 'column', gap: '24px' }}
                  >
                    <div className="form-group mb input-container">
                      <div
                        className="d-flex align-items-center"
                        style={{ flexDirection: 'row', gap: '24px' }}
                      >
                        <div style={{ width: '100%' }}>
                          <Field
                            as="select"
                            name="Government"
                            className="form-select"
                            style={{
                              backgroundColor: '#f2f2f2',
                              fontFamily: 'Lato',
                              height: '56px',
                              width: '100%',
                            }}
                          >
                            <option value="" disabled>
                              Governorate
                            </option>
                            <option value="Cairo">Cairo</option>
                            <option value="Giza">Giza</option>
                            <option value="Alexandria">Alexandria</option>
                            <option value="Qalyubia">Qalyubia</option>
                            <option value="Port Said">Port Said</option>
                            <option value="Suez">Suez</option>
                            <option value="Gharbia">Gharbia</option>
                            <option value="Dakahlia">Dakahlia</option>
                            <option value="Sharqia">Sharqia</option>
                            <option value="Monufia">Monufia</option>
                            <option value="Ismailia">Ismailia</option>
                            <option value="Asyut">Asyut</option>
                            <option value="Faiyum">Faiyum</option>
                            <option value="Beheira">Beheira</option>
                            <option value="Beni Suef">Beni Suef</option>
                            <option value="Minya">Minya</option>
                            <option value="Damietta">Damietta</option>
                            <option value="Luxor">Luxor</option>
                            <option value="Qena">Qena</option>
                            <option value="Aswan">Aswan</option>
                            <option value="Red Sea">Red Sea</option>
                            <option value="New Valley">New Valley</option>
                            <option value="Matruh">Matruh</option>
                            <option value="North Sinai">North Sinai</option>
                            <option value="South Sinai">South Sinai</option>
                            <option value="Kafr El Sheikh">
                              Kafr El Sheikh
                            </option>
                            <option value="Sohag">Sohag</option>
                          </Field>
                          <ErrorMessage
                            name="Government"
                            component="div"
                            className="text-danger mt-2"
                          />
                        </div>

                        <div style={{ width: '100%' }}>
                          <Field
                            as="select"
                            name="City"
                            className="form-select"
                            style={{
                              backgroundColor: '#f2f2f2',
                              fontFamily: 'Lato',
                              height: '56px',
                              width: '100%',
                            }}
                          >
                            <option value="" disabled>
                              City
                            </option>
                            <option value="Cairo">Cairo</option>
                            <option value="Giza">Giza</option>
                            <option value="Alexandria">Alexandria</option>
                          </Field>
                          <ErrorMessage
                            name="City"
                            component="div"
                            className="text-danger mt-2"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb input-container">
                      <div
                        className="d-flex align-items-center"
                        style={{ flexDirection: 'row', gap: '24px' }}
                      >
                        <div style={{ width: '100%' }}>
                          <Field
                            as="select"
                            name="Region"
                            className="form-select"
                            style={{
                              backgroundColor: '#f2f2f2',
                              fontFamily: 'Lato',
                              height: '56px',
                              width: '100%',
                            }}
                          >
                            <option value="" disabled>
                              Region
                            </option>
                            <option value="District 1">District 1</option>
                            <option value="District 2">District 2</option>
                            <option value="District 3">District 3</option>
                          </Field>
                          <ErrorMessage
                            name="Region"
                            component="div"
                            className="text-danger mt-2"
                          />
                        </div>

                        <div style={{ width: '100%' }}>
                          <Field
                            name="StreetCompound"
                            className="form-control"
                            style={{
                              backgroundColor: '#f2f2f2',
                              fontFamily: 'Lato',
                              height: '56px',
                              width: '100%',
                            }}
                            placeholder="Street/Compound"
                          />
                          <ErrorMessage
                            name="StreetCompound"
                            component="div"
                            className="text-danger mt-2"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group mb input-container">
                      <div style={{ width: '100%' }}>
                        <Field
                          name="Building"
                          className="form-control"
                          style={{
                            backgroundColor: '#f2f2f2',
                            fontFamily: 'Lato',
                            height: '56px',
                            width: '100%',
                          }}
                          placeholder="Building"
                        />
                        <ErrorMessage
                          name="Building"
                          component="div"
                          className="text-danger mt-2"
                        />
                      </div>
                    </div>

                    <div className="form-group mb input-container">
                      <Field
                        name="Instructions"
                        className="form-control"
                        style={{
                          backgroundColor: '#f2f2f2',
                          fontFamily: 'Lato',
                          height: '56px',
                          width: '100%',
                        }}
                        placeholder="Special delivery instructions (optional)"
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn btn-dark btn-custom-size-continue w-100"
                      style={{
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontWeight: '600',
                        lineHeight: '140%',
                      }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? 'Submitting...' : 'Save and Start'}
                    </button>

                    {status && (
                      <p className="text-center mb-3 text-danger">
                        {status.success || status.error}
                      </p>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyInformation;
