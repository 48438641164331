import { TextField, InputAdornment } from '@mui/material';
import { Field } from 'formik';
import React from 'react';
import ResendButton from '../../auth/components/resendButton'; // Adjust the path as needed

function InputField(props) {
  const {
    label,
    name,
    type,
    errors,
    touched,
    disabled,
    select = false,
    variant,
    as,
    children,
    resendButtonProps, // Props for the ResendButton
  } = props;

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Field
        name={name}
        type={type}
        as={as || TextField}
        variant={variant || 'filled'}
        label={label}
        fullWidth
        error={Boolean(errors) && Boolean(touched)}
        helperText={Boolean(touched) && errors}
        disabled={disabled}
        select={select}
        sx={{
          borderRadius: '8px',
          '& .MuiInputBase-root': {
            backgroundColor: '#FAFAFA',
            borderRadius: '8px',
          },
          '& .MuiFilledInput-root': {
            '&:before, &:after': {
              borderBottom: 'none', // Remove the default underline
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: 'none', // Remove the bottom border on hover
            },
            '&.Mui-error': {
              '&:before, &:after': {
                borderBottom: 'none',
              },
            },
          },
        }}
        InputProps={{
          endAdornment: resendButtonProps ? (
            <InputAdornment position="end">
                            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <ResendButton {...resendButtonProps} />
              </div>
            </InputAdornment>
          ) : null,
          style: {
            borderRadius: '8px',
            backgroundColor: '#FAFAFA',
          },
        }}
        InputLabelProps={{
          style: {
            color: '#757575',
          },
        }}
        FormHelperTextProps={{
          style: {
            color: '#BB2124',
            fontFamily: 'lato',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
          },
        }}
      >
      {children}
      </Field>
    </div>
    
  );
}

export default InputField;
