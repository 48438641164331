import React, { useState, useRef } from 'react';

function CollateralUpload({ name, value = [], errors, setFieldValue }) {
  const [error, setError] = useState();
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const validFiles = files.filter((file) =>
      ['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)
    );
    const invalidFiles = files.filter(
      (file) =>
        !['application/pdf', 'image/jpeg', 'image/png'].includes(file.type)
    );

    if (invalidFiles.length > 0) {
      setError(
        'Unsupported file format. Please upload PDF, JPG, JPEG, or PNG files.'
      );
    }

    if (validFiles.length > 0) {
      setFieldValue(name, [...(value || []), ...validFiles]);
      setError('');
    }

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleRemoveFile = (fileToRemove) => {
    const updatedFiles = value.filter((file) => file !== fileToRemove);
    setFieldValue(name, updatedFiles);

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      {value &&
        value.map((file, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              alignItems: 'center',
              border: '1px dashed #757575',
              padding: '16px',
              borderRadius: '10px',
              backgroundColor: '#FAFAFA',
              cursor: 'pointer',
              height: '56px',
              justifyContent: 'space-between',
            }}
          >
            <span
              style={{
                flex: 1,
                color: '#8064A2',
                backgroundColor: '#FAFAFA',
                fontWeight: 'normal',
              }}
            >
              {file.name}
            </span>
            <button
              type="button"
              onClick={() => handleRemoveFile(file)}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                color: '#000000',
                cursor: 'pointer',
                fontSize: '16px',
                marginLeft: '10px',
                outline: 'none',
              }}
            >
              &times;
            </button>
          </div>
        ))}

      <div style={{ marginTop: '10px' }}>
        <label
          htmlFor={`${name}-input`}
          style={{
            cursor: 'pointer',
            color: '#000000',
            display: 'inline-block',
          }}
        >
          + Add another collateral
        </label>
        <input
          type="file"
          id={`${name}-input`}
          ref={fileInputRef}
          name={name}
          multiple
          accept=".pdf,.jpg,.jpeg,.png"
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </div>

      {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}
    </div>
  );
}

export default CollateralUpload;
