import React from 'react';
import QuickActionsContainer from './quickActions';
import Top from './top';
import TotalTreasuryBalance from './totalTreasuryBalance';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ManagePlan from './maturityAndPlan';
import TransactionsAndRequests from './transactionsAndRequests';

function TreasuryScreen() {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        scrollbarWidth: 'none',
        msOverflowStyle: 'none',
        width: '100%',
        height:'100%',
       // overflowX:'hidden',        
        padding: '24px',
      }}
    >
      <div
        style={{ display: 'flex', flexDirection: 'row', paddingBottom: '17px' }}
      >
        <h1
          style={{
            fontFamily: 'Lato',
            fontSize: '24px',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '140%',
          }}
        >
          Treasury
        </h1>
      </div>

      <QuickActionsContainer />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          flexGrow: 1,
          paddingTop: '17px',
        }}
      >
        <div style={{display:'flex',
          flexDirection:'row',
          width:'100%'
        
        }}>
            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between'}}>
        <div style={{ flex: 1, marginRight: '16px', width:'67%' }}>
          <TotalTreasuryBalance />
        </div>
        <div style={{  width: '30%' }}>
          <ManagePlan />
        </div>
      </div>
       
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          flex: 1,
        }}
      >
        <TransactionsAndRequests />
      </div>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default TreasuryScreen;
