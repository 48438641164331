import React, { useRef, useState } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import uploadicon from '../../../assests/img/uploadicon.png';

const FileUploader = ({
  name,
  value = null, // Accept only a single file
  errors,
  setFieldValue,
  touched,
  uploadText,
  acceptedFormats = [],
}) => {
  const [uploadStatus, setUploadStatus] = useState('idle');
  const [fileError, setFileError] = useState(null);
  const fileInputRef = useRef(null);

  const acceptPattern =
    acceptedFormats.length > 0 ? acceptedFormats.join(',') : '';

  const handleFileChange = (e) => {
    const file = e.target.files[0]; // Only allow one file

    if (file) {
      if (!acceptedFormats.includes(file.type)) {
        alert('Please upload a file in the supported formats.');
        setFileError('Unsupported file format.');
        return;
      }

      setUploadStatus('uploading');
      setFileError(null);

      // Simulate file upload
      setTimeout(() => {
        setFieldValue(name, file); // Store only a single file
        setUploadStatus('success');
      }, 2000);
    }

    // Reset the input field
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleRemoveFile = () => {
    setFieldValue(name, null); // Remove the file
    setUploadStatus('idle');
    setFileError('Please upload a file.');
  };

  return (
    <div style={{ marginBottom: '20px' }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          border: '1px dashed #757575',
          padding: '16px',
          borderRadius: '10px',
          backgroundColor: '#FAFAFA',
          cursor: 'pointer',
          height: '56px',
          justifyContent: 'space-between',
        }}
        onClick={() => fileInputRef.current.click()}
      >
        <span
          style={{
            flex: 1,
            color: uploadStatus === 'success' ? '#8064A2' : '#757575',
            fontWeight: 'normal',
          }}
        >
          {value ? value.name : uploadText}
        </span>

        {uploadStatus === 'idle' && !value && (
          <img
            src={uploadicon}
            alt="Placeholder"
            style={{
              width: '24px',
              height: '24px',
              color: '#121212',
              objectFit: 'contain',
              marginLeft: '10px',
            }}
          />
        )}

        {uploadStatus === 'uploading' && (
          <CircularProgress size={24} sx={{ color: '#8064A2' }} />
        )}

        {uploadStatus === 'success' && value && (
          <button
            type="button"
            onClick={(e) => {
              e.stopPropagation();
              handleRemoveFile();
            }}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              color: '#000000',
              cursor: 'pointer',
              fontSize: '16px',
              marginLeft: '10px',
              outline: 'none',
            }}
          >
            &times;
          </button>
        )}

        <input
          type="file"
          ref={fileInputRef}
          name={name}
          accept={acceptPattern}
          onChange={handleFileChange}
          style={{ display: 'none' }}
        />
      </Box>

      {fileError && (
        <Typography
          color="#BB2124"
          variant="body2"
          style={{ marginTop: '10px', fontFamily: 'Lato', fontSize: '16px' }}
        >
          {fileError}
        </Typography>
      )}

      {errors && touched && (
        <Typography
          color="#BB2124"
          variant="body2"
          style={{ marginTop: '10px', fontFamily: 'Lato', fontSize: '16px' }}
        >
          {errors}
        </Typography>
      )}
    </div>
  );
};

export default FileUploader;
