import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import CloseIcon from '../../../../assests/img/CloseIcon.png';
import CustomSlider from '../../../../components/slider/customSlider';
import ToggleSwitch from '../../../../components/buttons/toggleSwitch';
import { Formik, Field, Form } from 'formik';
import WarningIcon from '../../../../assests/img/WarningIcon.png';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  fullName: Yup.string()
    .required('Full name is required')
    .max(100, 'Full Name must not exceed 100 characters.'),
  distributionFreq: Yup.string().required('Distribution Frequency is required'),
  email: Yup.string()
    .required('Email is required')
    .email('Must be a valid email address'),
  id: Yup.string()
    .required('National ID is required')
    .length(14, 'National ID must be exactly 14 digits long')
    .matches(
      /^\d{14}$/,
      'National ID must be 14 digits and contain no letters or special characters'
    ),
  period: Yup.string().required('Please select a period'),
  vestingPeriod: Yup.string().required('Please select a period'),
  age: Yup.number()
    .required('Age is required')
    .min(16, 'Age cannot be less than 16')
    .max(60, 'Age cannot exceed 60'),
  employeeContribution: Yup.number()
    .typeError('Company contribution must be a number')
    .min(500, 'Minimum contribution must be greater than 499')
    .max(999999999, 'Company Contribution cannot exceed 9 digits'),
});

const VestingPlanModalTwo = ({
  isOpen,
  onClose,
  vestingPeriod,
  period,
  onBack,
}) => {
  const [isEqualDistribution, setIsEqualDistribution] = useState(false);
  const [sliders, setSliders] = useState([0, 0, 0, 0]);

  useEffect(() => {
    const numberOfSliders = Math.max(vestingPeriod - period, 0);
    setSliders(Array(numberOfSliders).fill(0));
  }, [vestingPeriod, period]);

  const handleBackClick = () => {
    onBack();
  };

  const initialValues = {
    fullName: '',
    distributionFreq: '',
    email: '',
    id: '',
    period: '',
    vestingPeriod: '',
    age: '',
    employeeContribution: '',
  };

  const numberOfSliders = vestingPeriod - period;

  const handleToggle = (isToggled) => {
    setIsEqualDistribution(isToggled);
    console.log('hey');

    if (isToggled) {
      const equalValue = parseFloat((100 / numberOfSliders).toFixed(2));

      let slidersCopy = Array(numberOfSliders).fill(equalValue);
      let total = slidersCopy.reduce((acc, value) => acc + value, 0);

      const difference = parseFloat((100 - total).toFixed(2));

      setSliders(slidersCopy);
    }
  };

  const handleSliderChange = (newValue, index) => {
    const updatedSliders = [...sliders];
    updatedSliders[index] = newValue;

    if (isEqualDistribution) {
      const equalValue = parseFloat((100 / sliders.length).toFixed(2));
      setSliders(sliders.map(() => equalValue));
    } else {
      setSliders(updatedSliders.map((value) => parseFloat(value.toFixed(2))));
    }
  };

  const getWordForNumber = (n) => {
    const words = [
      'First',
      'Second',
      'Third',
      'Fourth',
      'Fifth',
      'Sixth',
      'Seventh',
      'Eighth',
      'Ninth',
      'Tenth',
    ];
    return words[n - 1] || `${n}th`;
  };

  const onSubmit = (values) => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel="Add New Employee"
      style={{
        content: {
          position: 'fixed',
          top: '75px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          background: 'white',
          padding: '0px',
          borderRadius: '12px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '8px 16px',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderRadius: '12px 12px 0px 0px',
            borderBottom: '1px solid var(--UI-Strock-Soft, #EAEDF2)',
            background: 'var(--Background-Primary, #FFF)',
          }}
        >
          <button
            style={{
              height: '20px',
              width: '20px',
              border: 'none',
              backgroundColor: 'transparent',
              padding: 0,
              border:'none'
            }}
            onClick={onClose}
          >
            <img
              style={{
                display: 'flex',
                width: '20px',
                height: '20px',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              src={CloseIcon}
              alt="Close"
            />
          </button>
        </div>

        <div
          style={{
            display: 'flex',
            borderRadius: '16px 16px 0px 0px',
            background: 'var(--Background-Layer, #FAFAFA)',
            width: '100vw',
            height: '100vh',
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: '32px',
          }}
        >
          <div
            style={{
              display: 'flex',
              padding: '74px 121px 123px 121px',
              justifyContent: 'center',
              alignItems: 'flex-start',
              borderRadius: '12px',
              border: '1px solid var(--Border-1, #EAEDF2)',
              background: 'var(--Background-Primary, #FFF)',
              height: 'auto',
              width: '81vw',
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
              maxHeight: '90vh',
            }}
          >
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ touched, errors, isSubmitting }) => (
                <Form
                  style={{
                    width: '79vw',
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        paddingBottom: '74px',
                      }}
                    >
                      <span
                        style={{
                          color: '#09090B',
                          fontFamily: 'Lato',
                          fontSize: '22px',
                          fontStyle: 'normal',
                          fontWeight: '500',
                          lineHeight: '140%',
                        }}
                      >
                        Vesting Plan
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '50vw',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <span
                        style={{
                          alignSelf: 'stretch',
                          color: '#09090B',
                          fontSize: '16px',
                          font: 'Lato',
                          fontWeight: '500',
                          lineHeight: '160%',
                        }}
                      >
                        Vesting frequency
                      </span>
                      <span
                        style={{
                          alignSelf: 'stretch',
                          color: '#09090B',
                          fontSize: '14px',
                          font: 'Lato',
                          fontWeight: '400',
                          lineHeight: '155%',
                        }}
                      >
                        Decide how Frequent employees are vesting each year
                      </span>
                      <ToggleSwitch
                        label="Enable equal vesting distribution"
                        onToggle={handleToggle}
                      />
                    </div>
                    {sliders.length > 0 && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '16px',
                          alignItems: 'center',
                          maxHeight: '50vh',
                          overflowY: 'auto',
                          width: '54vw',
                          paddingTop: '0',
                          overflowY: 'scroll',
                          scrollbarWidth: 'none',
                          msOverflowStyle: 'none',
                        }}
                      >
                        {sliders.map((value, index) => (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              gap: '8px',
                              paddingBottom: '16px',
                              alignItems: 'center',
                            }}
                          >
                            <span
                              style={{
                                alignSelf: 'stretch',
                                color: '#09090B',
                                fontSize: '16px',
                                font: 'Lato',
                                fontWeight: '500',
                                lineHeight: '160%',
                              }}
                            >
                              {getWordForNumber(index + 1)} year
                            </span>
                            <CustomSlider
                              value={value}
                              onChange={(newValue) =>
                                handleSliderChange(newValue, index)
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '12px',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: '20px',
                        borderRadius: '16px',
                        background: 'var(--Background-Layer, #FAFAFA)',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        width: '30vw'

                      }}
                    >
                      <img src={WarningIcon} />
                      <span
                        style={{
                          alignSelf: 'stretch',
                          color: '#121212',
                          fontSize: '12px',
                          fontFamily: 'Lato',
                          fontWeight: '500',
                          lineHeight: 'normal%',
                        }}
                      >
                        The cliff year is not included in the vesting frequency
                      </span>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '54vw',
                        gap: '8px',
                      }}
                    >
                      <button
                        type="submit"
                        style={{
                          display: 'flex',
                          width: '48vw',
                          padding: '8px 16px',
                          justifySelf: 'center',
                          alignSelf: 'center',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '12px',
                          background: 'var(--Buttons-Primary, #FFF)',
                        }}
                        disabled={isSubmitting}
                        onClick={handleBackClick}
                      >
                        <span
                          style={{
                            color: 'var(--Text-Invert, #1E1E20)',
                            textAlign: 'center',
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '140%',
                          }}
                        >
                          Back
                        </span>
                      </button>
                      <button
                        type="submit"
                        style={{
                          display: 'flex',
                          width: '48vw',
                          padding: '8px 16px',
                          justifySelf: 'center',
                          alignSelf: 'center',
                          justifyContent: 'center',
                          alignItems: 'center',
                          borderRadius: '12px',
                          background: 'var(--Buttons-Primary, #1E1E20)',
                        }}
                        disabled={isSubmitting}
                        onClick={onClose}
                      >
                        <span
                          style={{
                            color: 'var(--Text-Invert, #FFF)',
                            textAlign: 'center',
                            fontFamily: 'Lato',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '140%',
                          }}
                        >
                          Continue
                        </span>
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default VestingPlanModalTwo;
