import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Modal,
  Box,
  Typography,
  Button,
  Divider,
  IconButton,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '../../../assests/img/CloseIcon.png';
import { ApplicationContext } from './ApplicationContext';

const ApplicationReceivedModal = ({
  isOpen,
  onClose,
  applicationDate,
  email,
  handleDownload,
  createApplication,
}) => {
  const navigate = useNavigate();
  const { addApplication, applications } = useContext(ApplicationContext);
  const formattedDate = new Date(applicationDate).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });

  const handleSubmit = () => {
    navigate('/dashboard/loans/applications');
    onClose();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="application-received-title"
      aria-describedby="application-received-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '500px',
          height: '330px',
          bgcolor: '#FFF',
          borderRadius: '12px',
          boxShadow: 24,
          padding: '24px',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography
            id="application-received-title"
            variant="h1"
            sx={{
              fontFamily: 'Lato',
              fontSize: '20px',
              fontWeight: '600',
              lineHeight: '140%',
            }}
          >
            Application Received
          </Typography>
          <IconButton
            onClick={handleSubmit}
            sx={{
              marginLeft: 'auto',
              color: '#121212',
           
            }}
          >
               <img
                  style={{
                    display: 'flex',
                    width: '20px',
                    height: 'auto',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  src={CloseIcon}
                  alt="Submit Request"
                  className="img-fluid"
                />
                  </IconButton>
        </Box>
        <Divider sx={{ width: '100%', my: '24px' }} />
        <Typography id="application-received-description" sx={{}}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <span
              style={{
                fontFamily: 'Lato',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '160%',
                fontStyle: 'normal',
                color: '#121212',
                marginBottom: '6px',
              }}
            >
              Application Number
            </span>
            <span
              style={{
                fontFamily: 'Lato',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '160%',
                fontStyle: 'normal',
                color: '#121212',
              }}
            >
              #{applications.length}
            </span>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <span
              style={{
                fontFamily: 'Lato',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '160%',
                fontStyle: 'normal',
                color: '#121212',
              }}
            >
              Application Date
            </span>
            <span
              style={{
                fontFamily: 'Lato',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '160%',
                fontStyle: 'normal',
                color: '#121212',
              }}
            >
              {formattedDate}
            </span>
          </Box>
          <br />
          <span>
            A confirmation email has been sent to{' '}
            <a href={`mailto:${email}`}>{email}</a>.
          </span>
          <br />
          Please check your inbox for more details.
        </Typography>
        <Box
          sx={{
            mt: 4,
            display: 'flex',
            justifyContent: 'space-between',
            gap: '16px',
          }}
        >
          <div style={{ flex: 1 }}>
            <Button
              variant="outlined"
              onClick={() => navigate('/dashboard')}
              sx={{
                outlineColor: 'black',
                backgroundColor: 'white',
                color: 'black',
                padding: '8px',
                borderRadius: '12px',
                display: 'flex',
                fontFamily: 'Lato',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '140%',
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'none',
                width: '100%',
                height: '38px',
                borderColor: 'black',
              }}
            >
              Return to Dashboard
            </Button>
          </div>
          <div style={{ flex: 1, marginBottom: '24px' }}>
            <Button
              variant="contained"
              onClick={handleDownload}
              sx={{
                backgroundColor: 'black',
                color: 'white',
                '&:hover': {
                  backgroundColor: '#333',
                },
                padding: '8px',
                borderRadius: '12px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'none',
                fontFamily: 'Lato',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '140%',
                width: '100%',
                height: '38px',
              }}
            >
              Download Application
            </Button>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default ApplicationReceivedModal;

