import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Select, { components } from 'react-select';
import Avatar from 'react-avatar';
import axios from 'axios';
import Top from '../../treasury/top';
import EmployeeModal from './modal/employeeModal';
import VestingPlanModal from './modal/vestingPlanModal';
import CustomAlertModal from '../../../components/modal/customAlertModal';
import AlienIcon from '../../../assests/img/EmptyState.png';
import EmployeeDetailModal from './modal/employeeDetailModal';
import PlusIcon from '../../../assests/img/PlusIcon2.png';
import GreenDot from '../../../assests/img/GreenDot.png';
import XIcon from '../../../assests/img/x.png';
import Name from '../../../assests/img/Name.png';
import { toast } from 'react-toastify';
import tick from '../../../assests/img/tick.png';
import { ToastContainer } from 'react-toastify';
import axiosInstance from '../../../utils/axiosInstance';

function Employees() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAgeRanges, setSelectedAgeRanges] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [isVestingModalOpen, setIsVestingModalOpen] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [isCheckedAll, setIsCheckedAll] = useState(false);
  const location = useLocation();
  const { segment } = location.state || {};

  const navigate = useNavigate();

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => setIsModalOpen(false);
  const departmentOptions = [
    { value: 'Human Resources (HR)', label: 'Human Resources (HR)' },
    { value: 'Finance', label: 'Finance' },
    { value: 'Marketing', label: 'Marketing' },
    { value: 'Sales', label: 'Sales' },
    { value: 'Operations', label: 'Operations' },
    {
      value: 'IT (Information Technology)',
      label: 'IT (Information Technology)',
    },
    { value: 'Customer Service', label: 'Customer Service' },
    { value: 'Product', label: 'Product' },
    { value: 'Legal', label: 'Legal' },
    { value: 'Administration', label: 'Administration' },
    { value: 'Research and Development', label: 'Research and Development' },
    { value: 'Public Relations', label: 'Public Relations' },
    { value: 'Strategy and Planning', label: 'Strategy and Planning' },
    {
      value: 'Logistics and Supply Chain',
      label: 'Logistics and Supply Chain',
    },
    { value: 'Quality Assurance', label: 'Quality Assurance' },
  ];

  const ageOptions = [
    { value: '20-30', label: '20 - 30' },
    { value: '30-40', label: '30 - 40' },
    { value: '40-50', label: '40 - 50' },
    { value: '50-60', label: '50 - 60' },
  ];

 useEffect(() => {
  console.log('Segment:', segment); // Debugging segment object
  console.log(segment._id)
  if (segment && segment._id) {
    axiosInstance
      .get(`${process.env.REACT_APP_SERVER_URL}/employees`, {
        params: { segmentId: segment._id },
      })
      .then((response) => {
        setEmployees(response.data);
      })
      .catch((error) => {
        console.error('There was an error fetching employees!', error);
      });
  } else {
    console.error('Segment ID is missing or undefined.');
  }
}, [segment]);


  const handleCreateEmployee = async (newEmployee) => {
    try {
      // Fetch existing employees for the segment
      const response = await axiosInstance.get(`/employees`, {
        params: { segmentId: segment._id },
      });
  
      // Check if the employee with the same national ID already exists
      const employeeExists = response.data.some(
        (employee) => employee.id === newEmployee.id
      );
  
      if (employeeExists) {
        alert('An employee with the same national ID already exists in this segment.');
      } else {
        console.log('hi im here');
        // Prepare employee data
        const employeeData = {
          ...newEmployee,
          segmentId: segment._id,
          companyContribution: segment.companyContribution,
        };
  
        const createResponse = await axiosInstance.post(`/employees`, employeeData);
  
        setEmployees((prevEmployees) => [...prevEmployees, createResponse.data]);
  
        const updatedSegment = {
          ...segment,
          employeeCount: segment.employeeCount + 1,
        };
  
        location.state.segment = updatedSegment;
        closeModal();
  
        toast.success('Employee added successfully ', {
          autoClose: 3000,
          position: 'bottom-center',
          className: 'custom-toast',
          bodyClassName: 'custom-body-toast',
          icon: (
            <div className="custom-toast-icon">
              <img
                src={tick}
                alt="Success"
                style={{ width: '30px', height: '30px' }}
              />
            </div>
          ),
          progressClassName: 'custom-toast-progress',
          style: {
            width: '300px',
          },
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log('No existing employees found, creating new employee...');
        try {
          const employeeData = {
            ...newEmployee,
            segmentId: segment._id,
            companyContribution: segment.companyContribution,
          };
  
          const createResponse = await axiosInstance.post(`/employees`, employeeData);
  
          setEmployees((prevEmployees) => [...prevEmployees, createResponse.data]);
  
          const updatedSegment = {
            ...segment,
            employeeCount: segment.employeeCount + 1,
          };
  
          location.state.segment = updatedSegment;
          closeModal();
  
          toast.success('Employee added successfully ', {
            autoClose: 3000,
            position: 'bottom-center',
            className: 'custom-toast',
            bodyClassName: 'custom-body-toast',
            icon: (
              <div className="custom-toast-icon">
                <img
                  src={tick}
                  alt="Success"
                  style={{ width: '30px', height: '30px' }}
                />
              </div>
            ),
            progressClassName: 'custom-toast-progress',
            style: {
              width: '300px',
            },
          });
        } catch (error) {
          console.error('Error creating new employee:', error);
          toast.error('Error occurred while creating the employee. Please try again.');
        }
      } else {
        // Handle other errors
        console.error('There was an error handling the employee creation!', error);
        toast.error('Error occurred while creating the employee. Please try again.');
      }
    }
  };
  
  

  const handleSelectAllChange = () => {
    setIsCheckedAll(!isCheckedAll);

    if (!isCheckedAll) {
      setSelectedEmployees(employees);
    } else {
      setSelectedEmployees([]);
    }
  };

  const isEmployeeSelected = (employee) => selectedEmployees.includes(employee);

  const handleEmployeeUpdate = (employeeId, updatedEmployeeData) => {
    axios
      .put(
        `${process.env.REACT_APP_SERVER_URL}/employees/info/${employeeId}`,
        updatedEmployeeData
      )
      .then((response) => {
        console.log(updatedEmployeeData);
        console.log('Employee information updated successfully');
        setEmployees((prevEmployees) =>
          prevEmployees.map((emp) =>
            emp._id === employeeId ? { ...emp, ...updatedEmployeeData } : emp
          )
        );
      })
      .catch((error) => {
        console.error('Error updating employee info', error);
      });
  };

  const onUpdateContributions = (
    employeeId,
    newCompanyContribution,
    newEmployeeContribution
  ) => {
    setEmployees((prevEmployees) =>
      prevEmployees.map((emp) =>
        emp._id === employeeId
          ? {
              ...emp,
              companyContribution: newCompanyContribution,
              employeeContribution: newEmployeeContribution,
            }
          : emp
      )
    );

    axios
      .put(`${process.env.REACT_APP_SERVER_URL}/employees/${employeeId}`, {
        companyContribution: newCompanyContribution,
        employeeContribution: newEmployeeContribution,
      })
      .then((response) => {
        console.log('Contributions updated successfully');
      })
      .catch((error) => {
        console.error('Error updating contributions', error);
      });
  };

  const openVestingModal = () => {
    if (selectedEmployees.length === 0) {
      setIsAlertOpen(true);
    } else {
      setIsVestingModalOpen(true);
    }
  };

  const handleEmployeeClick = (employee) => {
    console.log(employee);
    setSelectedEmployee(employee);
  };

  const handleSaveVestingPlan = (vestingDetails) => {
    const updatedEmployees = employees.map((employee) => {
      if (selectedEmployees.includes(employee)) {
        return { ...employee, vestingDetails };
      }
      return employee;
    });
    setEmployees(updatedEmployees);
    setSelectedEmployees([]);
  };

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckboxChange = (employee) => {
    setSelectedEmployees((prevSelected) => {
      if (prevSelected.includes(employee)) {
        const updatedSelected = prevSelected.filter((e) => e !== employee);
        setIsCheckedAll(false);
        return updatedSelected;
      } else {
        const updatedSelected = [...prevSelected, employee];
        if (updatedSelected.length === employees.length) {
          setIsCheckedAll(true);
        }
        return updatedSelected;
      }
    });
  };

  const handleAgeFilterChange = (e) => {
    const options = e.target.options;
    const selectedValues = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    setSelectedAgeRanges(selectedValues);
  };

  const handleDepartmentChange = (selected) => {
    setSelectedDepartments(selected || []);
  };
  const handleAgeChange = (selected) => {
    setSelectedAgeRanges(selected || []);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#FAFAFA',
      borderColor: '#D9D9D9',
      borderRadius: '12px',
      padding: '4px 8px',
      boxShadow: 'none',
      '&:hover': {
        borderColor: '#D9D9D9',
      },
      width: '200px', // Adjust the width if necessary
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '12px',
      marginTop: 0,
      width: '200px',
    }),
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      alignItems: 'center',
      padding: '10px 8px', // Customize padding for better spacing
      backgroundColor: state.isSelected ? '#E0E0E0' : '#FFF',
      borderBottom: '1px solid #EAEDF2', // Border between options
      '&:hover': {
        backgroundColor: '#F8F8F8',
      },
    }),
    checkbox: () => ({
      marginRight: '8px',
    }),
    multiValueContainer: () => ({
      backgroundColor: '#FAFAFA',
    }),
  };

  const CustomDepartmentValueContainer = ({ children, ...props }) => {
    const { getValue } = props;
    const selected = getValue();

    return (
      <components.ValueContainer {...props}>
        {selected.length > 0 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '8px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                borderRadius: '12px',
                //  marginLeft:'2px',
                alignSelf: 'center',
                justifyContent: 'center',
                // marginRight:'2px',
                background: '#EBEBEB',
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                width: '50px',
                //background:'red',
                height: 'auto',
                gap: '6px',
              }}
            >
              <div
                style={{
                  fontFamily: 'Lato',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '140%',
                }}
              >
                {selected.length}
              </div>

              <img
                src={XIcon}
                alt="Clear Icon"
                onClick={() => props.clearValue()}
                style={{ Width: '14px', height: '14px' }}
              />
            </div>

            <span
              style={{
                marginLeft: '10px',
                fontSize: '16px',
                fontWeight: '500',
              }}
            >
              Department
            </span>
          </div>
        )}

        {children}
      </components.ValueContainer>
    );
  };
  const CustomAgeValueContainer = ({ children, ...props }) => {
    const { getValue } = props;
    const selected = getValue();

    return (
      <components.ValueContainer {...props}>
        {selected.length > 0 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '8px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                borderRadius: '12px',
                //  marginLeft:'2px',
                alignSelf: 'center',
                justifyContent: 'center',
                // marginRight:'2px',
                background: '#EBEBEB',
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                width: '50px',
                //background:'red',
                height: 'auto',
                gap: '6px',
              }}
            >
              <div
                style={{
                  fontFamily: 'Lato',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: '140%',
                }}
              >
                {selected.length}
              </div>
              <img
                src={XIcon}
                alt="Clear Icon"
                onClick={() => props.clearValue()}
                style={{ Width: '14px', height: '14px' }}
              />
            </div>

            <span
              style={{
                marginLeft: '10px',
                fontSize: '16px',
                fontWeight: '500',
              }}
            >
              Age
            </span>
          </div>
        )}
        {children}
      </components.ValueContainer>
    );
  };

  const ClearIndicator = () => null;
  const MultiValueRemove = () => null;

  const handleDepartmentFilterChange = (e) => {
    const options = e.target.options;
    const selectedValues = [];
    for (let i = 0; i < options.length; i++) {
      if (options[i].selected) {
        selectedValues.push(options[i].value);
      }
    }
    setSelectedDepartments(selectedValues);
  };

  const filteredEmployees = employees.filter((employee) => {
    let isWithinAgeRange = true;
    let isInDepartment = true;

    if (selectedAgeRanges.length > 0) {
      const employeeAge = parseInt(employee.age, 10);
      isWithinAgeRange = selectedAgeRanges.some((range) => {
        const [minAge, maxAge] = range.value.split('-').map(Number);
        return employeeAge >= minAge && employeeAge <= maxAge;
      });
    }

    if (selectedDepartments.length > 0) {
      isInDepartment = selectedDepartments.some(
        (department) => department.value === employee.department
      );
    }

    return isWithinAgeRange && isInDepartment;
  });

  const formatDate = (date) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-US', options)
      .format(date)
      .replace(',', '');
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          boxSizing: 'border-box',
        }}
      >
        <Top />

        <div
          style={{
            display: 'flex',
            width: '100%',
            flexDirection: 'column',
            paddingTop: '24px',
            paddingLeft: '32px',
            paddingRight: '24px',
            paddingBottom: '24px',
            gap: '16px',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            <span
              style={{
                color: '#0F0F10',
                fontFamily: 'Lato',
                fontSize: '24px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '140%',
                whiteSpace: 'nowrap',
              }}
            >
              {segment.segmentName}
            </span>
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                alignItems: 'center',
                alignContent: 'center',
                gap: '8px',
                whiteSpace: 'nowrap',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '4px 8px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '4px',
                  cursor: 'pointer',
                  border: 'none',
                }}
                onClick={openModal}
              >
                <img src={PlusIcon} />
                <span
                  style={{
                    color: '#121212',
                    textAlign: 'center',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                  }}
                >
                  Add Employee
                </span>
              </div>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '4px 8px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '4px',
                  cursor: 'pointer',
                }}
              >
                <img src={PlusIcon} />
                <span
                  style={{
                    color: '#121212',
                    textAlign: 'center',
                    fontFamily: 'Inter',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '20px',
                  }}
                >
                  Import from CSV
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              height: '1px',
              background: '#EAEDF2',
              marginBottom: '8px',
            }}
          ></div>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginBottom: '8px',
            }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              <Select
                isMulti
                options={departmentOptions}
                value={selectedDepartments}
                onChange={handleDepartmentChange}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                placeholder="Department"
                components={{
                  ValueContainer: CustomDepartmentValueContainer,
                  MultiValue: () => null,
                  ClearIndicator,
                }}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: '#FAFAFA',
                    color: 'black',
                    border: 'none',
                    borderRadius: '12px',
                    padding: '4px 8px',
                    boxShadow: 'none',
                    '&:hover': {
                      borderColor: '#D9D9D9',
                    },
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'black',
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '140%',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '12px',
                    marginTop: 0,
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#333',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid #EAEDF2', // Border between options
                    '&:hover': {
                      backgroundColor: state.isSelected ? '#EBEBEB' : '#FAFAFA',
                    },
                    padding: '10px 8px', // Customize padding for better spacing
                    backgroundColor: state.isSelected ? '#EBEBEB' : '#FFF',
                    color: 'black',
                  }),
                  checkbox: () => ({
                    marginRight: '8px',
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: 'red', // Set the background color of selected items to red
                    borderRadius: '8px',
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: 'white', // Change the text color of the selected items to white
                    fontWeight: 'bold',
                  }),
                  multiValueRemove: (provided) => ({
                    ...provided,
                    color: 'black !important', // Change color of the remove button
                    backgroundColor: 'darkred !important', // Background color of the remove button
                    borderRadius: '50%', // Make it circular
                    ':hover': {
                      backgroundColor: 'red !important', // Change background color on hover
                      color: 'black',
                    },
                    padding: '2px', // Add some padding
                  }),
                }}
                classNamePrefix="react-select"
              />
              <Select
                isMulti
                options={ageOptions}
                value={selectedAgeRanges}
                onChange={handleAgeChange}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                placeholder="Age"
                classNamePrefix="react-select"
                components={{
                  ValueContainer: CustomAgeValueContainer,
                  MultiValue: () => null,
                  ClearIndicator,
                }}
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: '#FAFAFA',
                    color: 'black',
                    border: 'none',
                    borderRadius: '12px',
                    padding: '4px 8px',
                    boxShadow: 'none',
                    '&:hover': {
                      borderColor: '#D9D9D9',
                    },
                  }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: 'black',
                    fontFamily: 'Lato',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '140%',
                  }),
                  menu: (provided) => ({
                    ...provided,
                    borderRadius: '12px',
                    marginTop: 0,
                  }),
                  dropdownIndicator: (provided) => ({
                    ...provided,
                    color: '#333',
                  }),
                  indicatorSeparator: () => ({
                    display: 'none',
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    display: 'flex',
                    alignItems: 'center',
                    borderBottom: '1px solid #EAEDF2', // Border between options
                    '&:hover': {
                      backgroundColor: state.isSelected ? '#EBEBEB' : '#FAFAFA',
                    },
                    padding: '10px 8px', // Customize padding for better spacing
                    backgroundColor: state.isSelected ? '#EBEBEB' : '#FFF',
                    color: 'black',
                  }),
                  checkbox: () => ({
                    marginRight: '8px',
                  }),
                  multiValue: (provided) => ({
                    ...provided,
                    backgroundColor: 'red', // Set the background color of selected items to red
                    borderRadius: '8px',
                  }),
                  multiValueLabel: (provided) => ({
                    ...provided,
                    color: 'white', // Change the text color of the selected items to white
                    fontWeight: 'bold',
                  }),
                  multiValueRemove: (provided) => ({
                    ...provided,
                    color: 'black !important', // Change color of the remove button
                    backgroundColor: 'darkred !important', // Background color of the remove button
                    borderRadius: '50%', // Make it circular
                    ':hover': {
                      backgroundColor: 'red !important', // Change background color on hover
                      color: 'black',
                    },
                    padding: '2px', // Add some padding
                  }),
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                width: '36vw',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              <button
                style={{
                  display: 'flex',
                  padding: '8px 16px',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: '8px',
                  borderRadius: 'var(--Radius-Large, 12px)',
                  background: 'var(--Buttons-Primary, #1E1E20)',
                  color: '#FFF',
                  textAlign: 'center',
                  fontFamily: 'Lato',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '400',
                  lineHeight: '140%',
                  border: 'none',
                }}
                onClick={openVestingModal}
              >
                Add vesting plans
              </button>
            </div>
            <VestingPlanModal
              isOpen={isVestingModalOpen}
              onClose={() => setIsVestingModalOpen(false)}
              onSave={handleSaveVestingPlan}
            />
          </div>
          {employees.length === 0 ? (
            <>
              <table
                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                  marginBottom: '152px',
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        width: '5%',
                        padding: '8px 12px',
                        borderRadius: '8px 0px 0px 0px',
                        background: '#F8FAFB',
                        textAlign: 'center',
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleSelectAllChange}
                        style={{
                          cursor: 'pointer',
                        }}
                      />
                    </th>
                    <th
                      style={{
                        width: '25%',
                        padding: '8px 12px',
                        color: '#1F2023',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        background: '#F8FAFB',
                      }}
                    >
                      Employee
                    </th>
                    <th
                      style={{
                        width: '20%',
                        padding: '8px 12px',
                        color: '#1F2023',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        background: '#F8FAFB',
                        textAlign: 'center',
                      }}
                    >
                      Total Contribution
                    </th>
                    <th
                      style={{
                        width: '15%',
                        padding: '8px 12px',
                        color: '#1F2023',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        background: '#F8FAFB',
                        textAlign: 'center',
                      }}
                    >
                      Age
                    </th>
                    <th
                      style={{
                        width: '20%',
                        padding: '8px 12px',
                        color: '#1F2023',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        background: '#F8FAFB',
                        textAlign: 'center',
                      }}
                    >
                      Department
                    </th>
                    <th
                      style={{
                        width: '15%',
                        padding: '8px 12px',
                        color: '#1F2023',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        background: '#F8FAFB',
                        textAlign: 'right',
                      }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
              </table>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  alignSelf: 'center',
                  gap: '44px',
                  width: '50vw',
                }}
              >
                <img src={AlienIcon} alt="Alien" />
                <button
                  style={{
                    display: 'flex',
                    padding: '8px 16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '8px',
                    alignSelf: 'stretch',
                    borderRadius: '12px',
                    background: '#1E1E20',
                    height: '4vh',
                    border: 'none',
                  }}
                  onClick={openModal}
                >
                  <span
                    style={{
                      color: '#FFF',
                      textAlign: 'center',
                      fontFamily: 'Lato',
                      fontSize: '16px',
                      fontStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '140%',
                    }}
                  >
                    Add Employee
                  </span>
                </button>
              </div>
            </>
          ) : (
            <div>
              <table
                style={{
                  width: '100%',
                  borderCollapse: 'collapse',
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        width: '5%',
                        padding: '8px 12px',
                        borderRadius: '8px 0px 0px 0px',
                        background: '#F8FAFB',
                        textAlign: 'center',
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        style={{
                          cursor: 'pointer',
                        }}
                      />
                    </th>
                    <th
                      style={{
                        width: '25%',
                        padding: '8px 12px',
                        color: '#1F2023',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        background: '#F8FAFB',
                        textAlign: 'left',
                      }}
                    >
                      Employee
                    </th>
                    <th
                      style={{
                        width: '20%',
                        padding: '8px 12px',
                        color: '#1F2023',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        background: '#F8FAFB',
                        textAlign: 'left',
                      }}
                    >
                      Total Contribution
                    </th>
                    <th
                      style={{
                        width: '15%',
                        padding: '8px 12px',
                        color: '#1F2023',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        background: '#F8FAFB',
                        textAlign: 'left',
                      }}
                    >
                      Age
                    </th>
                    <th
                      style={{
                        width: '20%',
                        padding: '8px 12px',
                        color: '#1F2023',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        background: '#F8FAFB',
                        textAlign: 'left',
                      }}
                    >
                      Department
                    </th>
                    <th
                      style={{
                        width: '15%',
                        padding: '8px 12px',
                        color: '#1F2023',
                        fontFamily: 'Inter',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '20px',
                        background: '#F8FAFB',
                        textAlign: 'right',
                      }}
                    >
                      Status
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredEmployees.map((employee, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          width: '5%',
                          padding: '8px 12px',
                          textAlign: 'center',
                          borderBottom: '1px solid #EAEDF2',
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={isEmployeeSelected(employee)}
                          onChange={() => handleCheckboxChange(employee)}
                          style={{
                            cursor: 'pointer',
                          }}
                        />
                      </td>
                      <td
                        style={{
                          width: '25%',
                          padding: '8px 12px',
                          textAlign: 'center',
                          borderBottom: '1px solid #EAEDF2',
                        }}
                        onClick={() => handleEmployeeClick(employee)}
                      >
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'left',
                            justifyContent: 'left',
                            gap: '8px',
                          }}
                        >
                          <Avatar
                            name={employee.fullName}
                            size="40"
                            round={true}
                            style={{
                              height: '100%',
                              borderRadius: '50%',
                            }}
                          />
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'left',
                              justifyContent: 'left',
                              textAlign: 'left',
                            }}
                          >
                            <div style={{ display: 'flex', width: '100%' }}>
                              <span
                                style={{
                                  color: '#0C0C0C',
                                  fontFamily: 'Lato',
                                  fontSize: '14px',
                                  fontWeight: '600',
                                }}
                              >
                                {employee.fullName}
                              </span>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                padding: '0px',
                                justifyContent: 'left',
                                alignItems: 'left',
                              }}
                            >
                              <span
                                style={{
                                  color: '#797979',
                                  fontFamily: 'Lato',
                                  fontSize: '10px',
                                  fontWeight: '400',
                                  textAlign: 'left',
                                }}
                              >
                                {employee.email}
                              </span>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td
                        style={{
                          width: '20%',
                          padding: '8px 12px',
                          textAlign: 'left',
                          borderBottom: '1px solid #EAEDF2',
                        }}
                        onClick={() => handleEmployeeClick(employee)}
                      >
                        <span
                          style={{
                            color: '#121212',
                            fontFamily: 'Lato',
                            fontSize: '14px',
                            fontWeight: '400',
                          }}
                        >
                          {`EGP${(Number(employee.employeeContribution) + Number(employee.companyContribution)).toLocaleString()}`}
                        </span>
                      </td>
                      <td
                        style={{
                          width: '15%',
                          padding: '8px 12px',
                          textAlign: 'left',
                          borderBottom: '1px solid #EAEDF2',
                        }}
                        onClick={() => handleEmployeeClick(employee)}
                      >
                        <span
                          style={{
                            color: '#121212',
                            fontFamily: 'Lato',
                            fontSize: '14px',
                            fontWeight: '400',
                          }}
                        >
                          {employee.age}
                        </span>
                      </td>
                      <td
                        style={{
                          width: '20%',
                          padding: '8px 12px',
                          textAlign: 'left',
                          borderBottom: '1px solid #EAEDF2',
                        }}
                        onClick={() => handleEmployeeClick(employee)}
                      >
                        <span
                          style={{
                            color: '#121212',
                            fontFamily: 'Lato',
                            fontSize: '14px',
                            fontWeight: '400',
                          }}
                        >
                          {employee.department}
                        </span>
                      </td>
                      <td
                        style={{
                          width: '15%',
                          padding: '8px 12px',
                          textAlign: 'right',
                          borderBottom: '1px solid #EAEDF2',
                        }}
                        onClick={() => handleEmployeeClick(employee)}
                      >
                        <span
                          style={{
                            color: 'var(--Icons-positive, #198754)',
                            fontFamily: 'Inter',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '140%',
                          }}
                        >
                          {employee.status}
                        </span>
                        <img src={GreenDot} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>

        <EmployeeDetailModal
          isOpen={!!selectedEmployee}
          onClose={() => setSelectedEmployee(null)}
          employee={selectedEmployee}
          segment={segment}
          onUpdateContributions={onUpdateContributions}
          onUpdateEmployee={handleEmployeeUpdate}
        />
        <EmployeeModal
          isOpen={isModalOpen}
          onClose={closeModal}
          onCreateEmployee={handleCreateEmployee}
        />
      </div>
      <CustomAlertModal
        isOpen={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
      />
    </>
  );
}

export default Employees;
