import React, { useState } from 'react';
import CalendarIcon from '../../../../../assests/img/calendar.png';
import { useToast } from '../../../toastProvider';
import DatePicker from 'react-datepicker';
import { addDays, format } from 'date-fns';
import '../../../../../styles/customToast.css';
import { toast } from 'react-toastify';
import CloseIcon from '../../../../../assests/img/CloseIcon.png';
import Modal from 'react-modal';
import Title from '../../../modals/components/title';
import tick from '../../../../../assests/img/tick.png';

function FirstModal({ closeModal, isModalOpen }) {
  const [activeRequest, setActiveRequest] = useState('liquidity');
  const [inputValue, setInputValue] = useState('');
  const [inputValuePurchase, setInputValuePurchase] = useState('');
  const [selectedDate, setSelectedDate] = useState(null);
  const [errors, setErrors] = useState({});
  const { showToast } = useToast();

  const resetForm = () => {
    setActiveRequest('liquidity');
    setInputValue('');
    setInputValuePurchase('');
    setSelectedDate(null);
    setErrors({});
  };

  const submitFirstModal = () => {
    let validationErrors = {};

    if (!inputValue || !/^\d+$/.test(inputValue)) {
      validationErrors.inputValue =
        'Purchase amount is required and must be a valid number with no decimals or special characters.';
    } else if (inputValue.length > 12) {
      validationErrors.inputValue =
        'The purchase amount cannot exceed 12 digits.';
    }

    if (activeRequest === 'purchase' && !inputValuePurchase) {
      validationErrors.inputValuePurchase = 'Please select an asset.';
    }

    if (!selectedDate) {
      validationErrors.selectedDate = 'Please select a date.';
    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      setErrors({});
      resetForm();
      closeModal();
      toast.success(
        `${activeRequest === 'liquidity' ? 'Liquidity' : 'Purchase'} request has been submitted successfully!`,
        {
          autoClose: 3000,
          position: 'bottom-center',
          className: 'custom-toast',
          bodyClassName: 'custom-body-toast',
          icon: <div className="custom-toast-icon"><img src={tick} alt="Success" style={{ width: '30px', height: '30px'}} /></div>,
          progressClassName: 'custom-toast-progress',
          style: {
            width: '470px',
           
          },
        }
      );
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handlePurchaseInputChange = (e) => {
    setInputValuePurchase(e.target.value);
  };

  const isFridayOrSaturday = (date) => {
    const day = date.getDay();
    return day === 5 || day === 6;
  };

  const filterFridaysAndSaturdays = (date) => {
    return !isFridayOrSaturday(date);
  };

  const handleToggleRequest = (requestType) => {
    setActiveRequest(requestType);
    setInputValue('');
    setInputValuePurchase('');
    setSelectedDate(null);
    setErrors({});
  };

  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel="Request to Buy or Liquidate"
      style={{
        content: {
          position: 'fixed',
          top: '75px',
          left: '0px',
          right: '0px',
          bottom: '0px',
          background: 'white',
          padding: '0px',
          borderRadius: '12px',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
          overflow: 'hidden',
        },
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
        }}
      >
        <div
          style={{
            display: 'flex',
            width: '100%',
            padding: '8px 16px',
            justifyContent: 'flex-end',
            alignItems: 'center',
            borderRadius: '12px 12px 0px 0px',
            borderBottom: '1px solid var(--UI-Strock-Soft, #EAEDF2)',
            background: 'var(--Background-Primary, #FFF)',
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '8px 0px',
              justifyContent: 'flex-end',
              alignItems: 'center',
              gap: '10px',
              flexShrink: '0',
            }}
          >
            <button
              style={{
                height: '20px',
                width: '20px',
                border: 'none',
                backgroundColor: 'transparent',
                padding: 0,
              }}
              onClick={() => {
                resetForm();
                closeModal();
              }}
            >
              <img
                style={{
                  display: 'flex',
                  width: '20px',
                  height: '20px',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                src={CloseIcon}
                alt="Close"
                className="img-fluid"
              />
            </button>
          </div>
        </div>

        <div
          style={{ height: '24px', width: '100%', background: '#FAFAFA' }}
        ></div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: '#FAFAFA',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '55px',
              width: '80vw',
              height: 'calc(100vh - 300px)',
              borderRadius: '12px',
              margin: 'auto',
              border: '1px solid #EAEDF2',
              background: '#FFF',
              overflowY: 'scroll',
              scrollbarWidth: 'none',
              msOverflowStyle: 'none',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '70%',
                height: '450px',
              }}
            >
              <Title
                title={'Request to buy or liquidate assets'}
                subtitle={
                  'If you require liquidity or want to purchase specific assets, simply submit a request, and our asset management team will handle it.'
                }
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  padding: '2px',
                  alignItems: 'flex-start',
                  alignSelf: 'stretch',
                  borderRadius: '12px',
                  background: '#FAFAFA',
                  marginBottom: activeRequest === 'liquidity' ? '28px' : '16px',
                }}
              >
                <button
                  style={{
                    display: 'flex',
                    width: '100%',
                    padding: '11px 8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    flex: '1 0 0',
                    borderRadius: '8px',
                    border: '1px solid #E8E8E8',
                    background:
                      activeRequest === 'liquidity' ? '#FFF' : '#FAFAFA',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleToggleRequest('liquidity')}
                >
                  <span
                    style={{
                      color:
                        activeRequest === 'liquidity' ? '#121212' : '#757575',
                      textAlign: 'center',
                      fontFamily: 'Lato',
                      fontSize: '12px',
                      fontWeight: '600',
                      lineHeight: '16.8px',
                    }}
                  >
                    Liquidity Request
                  </span>
                </button>

                <button
                  style={{
                    display: 'flex',
                    width: '100%',
                    height: '38.5px',
                    padding: '11px 8px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '10px',
                    flex: '1 0 0',
                    borderRadius: '8px',
                    border: '1px solid #E8E8E8',
                    background:
                      activeRequest === 'purchase' ? '#FFF' : '#FAFAFA',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleToggleRequest('purchase')}
                >
                  <span
                    style={{
                      color:
                        activeRequest === 'purchase' ? '#121212' : '#757575',
                      textAlign: 'center',
                      fontFamily: 'Lato',
                      fontSize: '12px',
                      fontWeight: '600',
                      lineHeight: '16.8px',
                    }}
                  >
                    Purchase Request
                  </span>
                </button>
              </div>

              {activeRequest === 'liquidity' && (
                <>
                  <input
                    style={{
                      display: 'flex',
                      height: '56px',
                      width: '100%',
                      padding: '18px 16px',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      borderRadius: '10px',
                      border: 'none',
                      background: '#FAFAFA',
                      fontFamily: 'Lato',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '140%',
                      color: '#121212',
                      marginBottom: '16px',
                    }}
                    placeholder="Liquidity amount"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  {errors.inputValue && (
                    <span className="text-danger" style={{ color: 'red' , marginBottom: '20px'}}>
                      {errors.inputValue}
                    </span>
                  )}

                  <div
                    style={{
                      display: 'flex',
                      width: '301.733px',
                      padding: '13.767px',
                      flexDirection: 'column',
                      gap: '13.767px',
                      margin: 'auto',
                    }}
                  >
                    <DatePicker
                      style={{ marginBottom: '24px' }}
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      inline
                      minDate={addDays(new Date(), 1)}
                      filterDate={filterFridaysAndSaturdays}
                    />
                    {errors.selectedDate && (
                      <span
                        className="text-danger"
                        style={{ color: 'red', textAlign: 'center' , marginBottom: '30px'}}
                      >
                        {errors.selectedDate}
                      </span>
                    )}
                  </div>
                </>
              )}

              {activeRequest === 'purchase' && (
                <>
                  <select
                    style={{
                      display: 'flex',
                      backgroundColor: '#FAFAFA',
                      justifyContent: 'space-between',
                      flex: '1 0 0',
                      padding: '18px 16px',
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      fontFamily: 'Lato',
                      fontSize: '16px',
                      fontWeight: '400',
                      fontStyle: 'normal',
                      lineHeight: '22.4px',
                      height: '60px',
                      width: '100%',
                      marginBottom: '16px',
                    }}
                    value={inputValuePurchase}
                    onChange={handlePurchaseInputChange}
                  >
                    <option value="" disabled>
                      Choose the asset you want to buy
                    </option>
                    <option value="Treasury Bills">Treasury Bills</option>
                    <option value="Dahab">Dahab</option>
                    <option value="Aqar">Aqar</option>
                  </select>
                  {errors.inputValuePurchase && (
                    <span className="text-danger" style={{ color: 'red', marginBottom: '20px' }}>
                      {errors.inputValuePurchase}
                    </span>
                  )}

                  <input
                    style={{
                      display: 'flex',
                      height: '56px',
                      width: '100%',
                      padding: '18px 16px',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      borderRadius: '10px',
                      border: 'none',
                      background: '#FAFAFA',
                      fontFamily: 'Lato',
                      fontSize: '16px',
                      fontWeight: '400',
                      lineHeight: '140%',
                      color: '#121212',
                      marginBottom: '16px',
                    }}
                    placeholder="Purchase amount"
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  {errors.inputValue && (
                    <span className="text-danger" style={{ color: 'red',
                      marginBottom: '20px'
                     }}>
                      {errors.inputValue}
                    </span>
                  )}

                  <div style={{ alignSelf: 'center', textAlign: 'center' }}>
                    <DatePicker
                      selected={selectedDate}
                      onChange={(date) => setSelectedDate(date)}
                      inline
                      minDate={addDays(new Date(), 1)}
                    />
                    {errors.selectedDate && (
                      <span
                        className="text-danger"
                        style={{
                          color: 'red',
                          display: 'block',
                          marginTop: '10px',
                          marginBottom: '30px'
                        }}
                      >
                        {errors.selectedDate}
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div
            style={{
              width: '80%',
              height: '143px',
              display: 'flex',
              padding: '28px 133px',
              justifyContent: 'space-between',
              alignItems: 'center',
              alignSelf: 'stretch',
              border: '1px #EAEDF2',
              background: '#FFF',
              boxShadow: '0px -5px 47px 0px rgba(0, 0, 0, 0.03)',
            }}
          >
            {activeRequest === 'liquidity' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '8px',
                  }}
                >
                  <span
                    style={{
                      color: '#757575',
                      height: '20px',
                      fontFamily: 'Lato',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '20px',
                    }}
                  >
                    Liquidity request for
                  </span>

                  <span
                    style={{
                      color: '#09090B',
                      height: '31px',
                      fontFamily: 'Lato',
                      fontSize: '22px',
                      fontWeight: '500',
                      lineHeight: '140%',
                    }}
                  >
                    EGP{Number(inputValue).toLocaleString() || 0}
                  </span>

                  <span
                    style={{
                      color: '#757575',
                      height: '20px',
                      fontFamily: 'Lato',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '20px',
                    }}
                  >
                    On{' '}
                    {selectedDate
                      ? format(selectedDate, 'dd MMMM yyyy')
                      : 'Select a date'}
                  </span>
                </div>
              </>
            )}

            {activeRequest === 'purchase' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '8px',
                  }}
                >
                  <span
                    style={{
                      color: '#757575',
                      height: '20px',
                      fontFamily: 'Lato',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '20px',
                    }}
                  >
                    Purchase request for
                  </span>

                  <span
                    style={{
                      color: '#09090B',
                      height: '31px',
                      fontFamily: 'Lato',
                      fontSize: '22px',
                      fontWeight: '500',
                      lineHeight: '140%',
                    }}
                  >
                    EGP{Number(inputValue).toLocaleString() || 0} -{' '}
                    {inputValuePurchase}
                  </span>

                  <span
                    style={{
                      color: '#757575',
                      height: '20px',
                      fontFamily: 'Lato',
                      fontSize: '14px',
                      fontWeight: '400',
                      lineHeight: '20px',
                    }}
                  >
                    On{' '}
                    {selectedDate
                      ? format(selectedDate, 'dd MMMM yyyy')
                      : 'Select a date'}
                  </span>
                </div>
              </>
            )}

            <button
              style={{
                display: 'flex',
                width: '340px',
                height: '38px',
                padding: '8px var(--Layout-x4, 16px)',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 'var(--Layout-x2, 8px)',
                borderRadius: '12px',
                background: '#1E1E20',
                border:'none'
              }}
              onClick={submitFirstModal}
            >
              <span
                style={{
                  color: '#FFF',
                  textAlign: 'center',
                  height: '22px',
                  fontFamily: 'Lato',
                  fontSize: '16px',
                  fontWeight: '600',
                  lineHeight: '140%',
                }}
              >
                Submit Request
              </span>
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default FirstModal;
