import React from 'react'
import Modal  from 'react-modal'
import CloseIcon from '../../assests/img/CloseIcon.png';

function CustomModal({children,isModalOpen,closeModal}) {
  return (
      <Modal
        isOpen={isModalOpen}
        contentLabel="Request to Buy or Liquidate"
        style={{
          content: {
            
            position: 'fixed',
            top: '75px',
            left: '0px',
            right: '0px',
            bottom: '0px',
            background: 'white',
            padding: '0px',
            borderRadius: '12px',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            overflow: 'hidden',
          },
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              width: '100%',
              padding: '8px 16px',
              justifyContent: 'flex-end',
              
              alignItems: 'center',
              borderRadius: '12px 12px 0px 0px',
              borderBottom: '1px solid var(--UI-Strock-Soft, #EAEDF2)',
              background: 'var(--Background-Primary, #FFF)',
            }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                padding: '8px 0px',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: '10px',
                flexShrink: '0',
                
              }}
            >
              <button
                style={{
                  height: '20px',
                  width: '20px',
                  border: 'none',
                  backgroundColor: 'transparent',
                  padding: 0,
                  
                  
                }}
                onClick={closeModal}
              >
                <img
                  style={{
                    display: 'flex',
                    width: '20px',
                    height: '20px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    
                  }}
                  src={CloseIcon}
                  alt="Submit Request"
                  className="img-fluid"
                />
              </button>
            </div>
          </div>
          
          {children}

        </div>
      </Modal>
  )
}

export default CustomModal