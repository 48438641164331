import React from 'react';
import { useRequests } from '../../requestsPage/components/requestContext';
import '../../../../styles/RequestItem.css';
import PurchaseIcon from '../../../../assests/img/ArrowwIcon.png';
import LiquidationIcon from '../../../../assests/img/LiquidationIcon.png';

const RequestItem = ({ requestName, date, type, amount }) => {
  const typeClass =
    type === 'Purchase' ? 'request-type-purchase' : 'request-type-liquidate';
  const icon = type === 'Purchase' ? PurchaseIcon : LiquidationIcon;

  return (
    <tr>
      <td style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}>
        <img
          style={{ marginRight: '8px' }}
          src={icon}
          alt={`${type} Arrow`}
          width="16"
        />
        <div>
          <div className="request-name">{requestName}</div>
          <div
            className="request-date"
            style={{ fontSize: '12px', color: '#757575' }}
          >
            {date}
          </div>
        </div>
      </td>

      <td style={{ textAlign: 'center', padding: '10px 0' }}>
        <span className={typeClass}>{type}</span>
      </td>

      <td style={{ textAlign: 'right', padding: '10px 0' }}>
        <span className="request-amount">{amount}</span>
      </td>
    </tr>
  );
};

const RequestList = () => {
  const { requests, addRequest } = useRequests();

  const handleAddRequest = (type) => {
    const currentDate = new Date().toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
    });
    const newRequest = {
      requestName: 'B 72 Yawmy MM Fund @49.11',
      date: currentDate,
      type: type,
      amount: 'EGP 50,000',
    };

    addRequest(newRequest);
  };

  return (
    <div>
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr style={{ backgroundColor: '#FAFAFA' }}>
            <th
              style={{
                textAlign: 'left',
                padding: '12px',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '20px',
              }}
            >
              Requests
            </th>
            <th
              style={{
                textAlign: 'center',
                padding: '12px',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '20px',
              }}
            >
              Type
            </th>
            <th
              style={{
                textAlign: 'right',
                padding: '12px',
                fontFamily: 'Inter',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: '400',
                lineHeight: '20px',
              }}
            >
              Amount
            </th>
          </tr>
        </thead>
        <tbody>
          {requests.map((request, index) => (
            <RequestItem
              key={index}
              requestName={request.requestName}
              date={request.date}
              type={request.type}
              amount={request.amount}
            />
          ))}
        </tbody>
      </table>
      <button onClick={() => handleAddRequest('Liquidate')}>Add Request</button>
    </div>
  );
};

export default RequestList;
