import React, { useState } from 'react';
import LoanScreen from '..';
import LoanApplication from '../loanapplication';

function LoanManager() {
  const [applications, setApplications] = useState([]);

  const handleCreateApplication = (newApplication) => {
    setApplications((prevApplications) => [...prevApplications, newApplication]);
  };

  return (
    <div>
      <LoanScreen onAddApplication={handleCreateApplication} />
      <LoanApplication applications={applications} />
    </div>
  );
}

export default LoanManager;
