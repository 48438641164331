import React, { useState, useEffect } from 'react';
import Header from './components/header';
import ActionItem from './components/actionItem';
import FirstModal from './modals/FirstModal';
import SecondModal from './modals/SecondModal';
import { ToastProvider } from '../toastProvider';
import ThirdModal from './modals/ThirdModal';
import FourthModal from '../modals/FourthModal';
import { padding } from '@mui/system';


function QuickActionsContainer() {
  const [isModalOpen, setIsModalOpen] = useState({
    first: false,
    second: false,
    third: false,
    fourth: false,
  });

  const closeModal=()=>{
    setIsModalOpen({
        first: false,
        second: false,
        third: false,
        fourth: false,
    })
  }

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isModalOpen]);
  return (
    <>
    
    <div
      style={{
        display: 'flex',
        width: '100%',
        padding: '16px',
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '16px',
        flexShrink: '0',
        borderRadius: '12px',
        border: '1px solid var(--Border-1, #EAEDF2)',
      }}
    >
      <Header />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: '16px',
         width:'100%'

        }}
      >
        <ActionItem
          title={'Request to purchase or liquidate'}
          slug={
            'Submit a request for liquidity or asset purchases, and our asset management team will handle it.'
          }
          buttonText={'Request'}
          background1={'#F3F2FB'}
          buttonAction={() => setIsModalOpen({ ...isModalOpen, first: true })}
         // width={'455px'}
        />
        <ActionItem
          title={'Confirm deposits'}
          slug={
            'Attach a screenshot of your account deposit to expedite your treasury investments.'
            
          }
          marginBottom={'0.5rem'}
          buttonText={'Confirm Deposits'}
          background1={'#F3F3F3'}
          buttonAction={() => setIsModalOpen({ ...isModalOpen, second: true })}
          //width={'365px'}
        />
        <ActionItem
          title={'Set custom portfolio'}
          slug={
            'Our team currently allocates your portfolio, feel free to adjust to custom plan if you want to'
          }
          buttonText={'Set Custom Portfolio'}
          background1={'#E2EDF0'}
          buttonAction={() => setIsModalOpen({ ...isModalOpen, third: true })}
          //width={'365px'}
        />
      </div>
    </div>
    <FirstModal closeModal={closeModal} isModalOpen={isModalOpen.first}/>
    <SecondModal closeModal={closeModal} isModalOpen={isModalOpen.second}/>
    <ThirdModal closeModal={closeModal} isModalOpen={isModalOpen.third}/>
    <FourthModal closeModal={closeModal} isModalOpen={isModalOpen.fourth}/>




    </>
  );
}

export default QuickActionsContainer;
