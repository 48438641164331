import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { FaCheckCircle, FaExclamationCircle, FaSpinner } from 'react-icons/fa';
import '../styles/customButton.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import commercialRegistryIcon from '../assests/img/icon.svg';
import landingImage from '../assests/img/landingImage.png';

const UploadDocuments = () => {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState({
    commercialRegistryFile: false,
    taxIdFile: false,
  });
  const [isSuccess, setIsSuccess] = useState({
    commercialRegistryFile: false,
    taxIdFile: false,
  });
  const [uploadError, setUploadError] = useState({
    commercialRegistryFile: '',
    taxIdFile: '',
  });
  const [uploadedFiles, setUploadedFiles] = useState({
    commercialRegistryFile: null,
    taxIdFile: null,
  });
  const navigate = useNavigate();
  const location = useLocation();
  const { companyId } = location.state || {}; 


  const SUPPORTED_FORMATS = [
    'image/png',
    'image/jpg',
    'image/jpeg',
    'application/pdf',
  ];

  const validationSchema = Yup.object({
    commercialRegistryFile: Yup.mixed()
      .required('Commercial Registry is required')
      .test(
        'fileFormat',
        'Unsupported file format. Only PNG, PDF, JPG are allowed.',
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
    taxIdFile: Yup.mixed()
      .required('Tax ID is required')
      .test(
        'fileFormat',
        'Unsupported file format. Only PNG, PDF, JPG are allowed.',
        (value) => value && SUPPORTED_FORMATS.includes(value.type)
      ),
  });

  const formik = useFormik({
    initialValues: {
      commercialRegistryFile: null,
      taxIdFile: null,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setIsLoading({ commercialRegistryFile: true, taxIdFile: true });
      const formData = new FormData();
      formData.append('commercialRegistry', values.commercialRegistryFile);
      formData.append('taxId', values.taxIdFile);

      try {
        await axios.post(
          `${process.env.REACT_APP_SERVER_URL}/uploadDocuments`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          }
        );
        setMessage('Files uploaded successfully');
        navigate('/companyInformation' , {
          state: { companyId },  
        });
      } catch (error) {
        console.log(error);
        setUploadError({
          commercialRegistryFile: 'Error uploading files',
          taxIdFile: 'Error uploading files',
        });
        setIsSuccess({ commercialRegistryFile: false, taxIdFile: false });
      } finally {
        setIsLoading({ commercialRegistryFile: false, taxIdFile: false });
        setSubmitting(false);
      }
    },
  });

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    if (file) {
      if (!SUPPORTED_FORMATS.includes(file.type)) {
        formik.setFieldError(
          name,
          'Unsupported file format. Only PNG, PDF, JPG are allowed.'
        );
        return;
      }

      setIsLoading((prev) => ({ ...prev, [name]: true }));
      setIsSuccess((prev) => ({ ...prev, [name]: false }));
      setUploadError((prev) => ({ ...prev, [name]: '' }));

      formik.setFieldValue(name, file);
      uploadFile(name, file);
    }
  };

  const uploadFile = async (name, file) => {
    try {
      await new Promise((resolve, reject) => {
        setTimeout(() => {
          const success = Math.random() > 0;
          if (success) resolve();
          else reject(new Error(`Failed to upload ${name}`));
        }, 2000);
      });

      setIsLoading((prev) => ({ ...prev, [name]: false }));
      setIsSuccess((prev) => ({ ...prev, [name]: true }));
      setUploadedFiles((prevFiles) => ({ ...prevFiles, [name]: file.name }));
    } catch (error) {
      setIsLoading((prev) => ({ ...prev, [name]: false }));
      setUploadError((prev) => ({ ...prev, [name]: error.message }));
    }
  };

  return (
    <div className="container">
      <div className="row vh-100">
        <div className="col-md-6 d-flex justify-content-center align-items-center bg-light">
          <img src={landingImage} alt="Verification" className="img-fluid" />
        </div>
        <div className="col-md-6 d-flex flex-column justify-content-center align-items-center style">
          <div className="w-75">
            <h1
              style={{
                fontSize: '32px',
                fontFamily: 'Lato',
                fontWeight: 'bold',
                marginBottom: '12px',
              }}
            >
              Verify Your Company Identity
            </h1>
            <p
              style={{
                fontSize: '18px',
                fontFamily: 'Lato',
                marginBottom: '32px',
              }}
            >
              Provide the info below to start your account
            </p>
            <form onSubmit={formik.handleSubmit}>
              <div className="form-group mb-4 input-container">
                <div
                  className="custom-file-input"
                  style={{ position: 'relative' }}
                >
                  <input
                    type="file"
                    className="form-control-file"
                    name="commercialRegistryFile"
                    id="commercialRegistryFile"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  <label
                    style={{ marginBottom: '16px' }}
                    htmlFor="commercialRegistryFile"
                    className="upload-btn"
                  >
                    {!formik.values.commercialRegistryFile &&
                      !uploadError.commercialRegistryFile &&
                      !isSuccess.commercialRegistryFile && (
                        <img
                          src={commercialRegistryIcon}
                          alt="Commercial Registry Icon"
                        />
                      )}
                    <span
                      style={{
                        marginLeft: '16px',
                        cursor: 'pointer',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#121212',
                      }}
                    >
                      Upload Commercial Registry
                    </span>
                  </label>

                  {/* Error for unsupported format */}
                  {formik.touched.commercialRegistryFile &&
                    formik.errors.commercialRegistryFile && (
                      <div className="text-danger">
                        {formik.errors.commercialRegistryFile}
                      </div>
                    )}

                  {/* Error message from file validation */}
                  {uploadError.commercialRegistryFile && (
                    <div
                      style={{
                        position: 'absolute',
                        right: '20px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: '#dc3545',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <FaExclamationCircle style={{ marginRight: '8px' }} />
                      {uploadError.commercialRegistryFile}
                    </div>
                  )}

                  {/* Spinner while loading */}
                  {isLoading.commercialRegistryFile && (
                    <FaSpinner
                      className="spinner"
                      style={{
                        position: 'absolute',
                        right: '20px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: '#888',
                        fontSize: '24px',
                        animation: 'spin 1s linear infinite',
                      }}
                    />
                  )}

                  {/* Success checkmark */}
                  {isSuccess.commercialRegistryFile &&
                    !isLoading.commercialRegistryFile && (
                      <FaCheckCircle
                        style={{
                          position: 'absolute',
                          right: '20px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                          color: '#28a745',
                          fontSize: '24px',
                        }}
                      />
                    )}
                </div>
              </div>

              {/* Tax ID Upload */}
              <div className="form-group mb-4 input-container">
                <div
                  className="custom-file-input"
                  style={{ position: 'relative' }}
                >
                  <input
                    type="file"
                    className="form-control-file"
                    name="taxIdFile"
                    id="taxIdFile"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />
                  <label
                    style={{ marginBottom: '16px' }}
                    htmlFor="taxIdFile"
                    className="upload-btn"
                  >
                    {!formik.values.taxIdFile &&
                      !uploadError.taxIdFile &&
                      !isSuccess.taxIdFile && (
                        <img src={commercialRegistryIcon} alt="Tax ID Icon" />
                      )}
                    <span
                      style={{
                        marginLeft: '16px',
                        cursor: 'pointer',
                        fontFamily: 'Lato',
                        fontSize: '16px',
                        fontWeight: '400',
                        color: '#121212',
                      }}
                    >
                      Upload Tax ID
                    </span>
                  </label>

                  {/* Error for unsupported format */}
                  {formik.touched.taxIdFile && formik.errors.taxIdFile && (
                    <div className="text-danger">{formik.errors.taxIdFile}</div>
                  )}

                  {/* Error message from file validation */}
                  {uploadError.taxIdFile && (
                    <div
                      style={{
                        position: 'absolute',
                        right: '20px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: '#dc3545',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <FaExclamationCircle style={{ marginRight: '8px' }} />
                      {uploadError.taxIdFile}
                    </div>
                  )}

                  {isLoading.taxIdFile && (
                    <FaSpinner
                      className="spinner"
                      style={{
                        position: 'absolute',
                        right: '20px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: '#888',
                        fontSize: '24px',
                        animation: 'spin 1s linear infinite',
                      }}
                    />
                  )}

                  {/* Success checkmark */}
                  {isSuccess.taxIdFile && !isLoading.taxIdFile && (
                    <FaCheckCircle
                      style={{
                        position: 'absolute',
                        right: '20px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                        color: '#28a745',
                        fontSize: '24px',
                      }}
                    />
                  )}
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-dark w-100"
                disabled={
                  formik.isSubmitting ||
                  isLoading.commercialRegistryFile ||
                  isLoading.taxIdFile
                }
              >
                {isLoading.commercialRegistryFile || isLoading.taxIdFile ? (
                  <FaSpinner
                    className="spinner"
                    style={{
                      fontSize: '24px',
                      animation: 'spin 1s linear infinite',
                    }}
                  />
                ) : (
                  'Save and Continue'
                )}
              </button>
            </form>

            {isSuccess.commercialRegistryFile && isSuccess.taxIdFile && (
              <div className="text-center">
                <FaCheckCircle
                  style={{
                    fontSize: '24px',
                    color: '#28a745',
                    marginTop: '10px',
                  }}
                />
                <p className="text-success">All files uploaded successfully!</p>
              </div>
            )}
            <p className="text-center mb-3">{message}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadDocuments;
