import React, { useState } from 'react';
import './toggleSwitch.css'; 

const ToggleSwitch = ({ label, onToggle }) => {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
    onToggle(!isToggled); 
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', paddingBottom:'24px', paddingTop: '24px' }}>
      <label className="switch">
        <input type="checkbox" checked={isToggled} onChange={handleToggle} />
        <span className="slider"></span>
      </label>
      <span style={{ color: '#09090B', fontSize: '16px', fontWeight: '500' }}>
        {label}
      </span>
    </div>
  );
};

export default ToggleSwitch;
