import React from 'react'
import landingImage from "../../../assests/img/landingImage.png";

function SignUpLeftContainer() {
  return (
     <div className="col-md-6 d-flex justify-content-center align-items-center">
     
          <img src={landingImage} alt="Verification" className="img-fluid" />
        </div>
  )
}

export default SignUpLeftContainer